import React, { useEffect, useState } from 'react'
import { Container } from '@mui/material';
import api from '../../service/api';
import Divider from '@mui/material/Divider';
import { AdherenteListado } from '../afiliado/AdherenteListado';
import { ReportesListadosAdherenteCompletoPdf } from '../ui/ReportesListadosAdherenteCompletoPdf';

export const InformeAfiliadoDetalleModal = ({ afiliado_aux }) => {
    const [afiliado, setAfiliado] = useState({
        id_afiliado: afiliado_aux,
        nombre_afiliado: '',
        dni_afiliado: '',
        obra_social_afiliado: '',
        empresa_afiliado: '',
        apellido: '',
        aporte_caja_jub: '',
        aporte_sist_nac: '',
        celular: '',
        cuil: '',
        domicilio: '',
        email: '',
        empresa: '',
        fecha_afiliacion: '',
        fecha_baja_afiliacion: '',
        fecha_ingreso: null,
        fecha_jubilacion: '',
        fecha_nacimiento: '',
        fondo_compensador: null,
        funcion: null,
        id_empresa: null,
        id_estado_civil: '',
        id_grupo_sanguineo: '',
        id_localidad: '',
        id_localidad_nac: '',
        id_obra_social: '',
        id_persona: '',
        id_plan: null,
        id_provincia: '',
        id_provincia_nac: '',
        id_sistema_nacional: null,
        id_sistema_provicional: null,
        ingresos: null,
        nacionalidad: '',
        nombre: '',
        obra_social: null,
        res2689: null,
        sindicato: null,
        telefono: '',
        genero: '',
        vigente: 0
    });

    const [adherente, setAdherente] = useState({ data: null, loading: true, error: null });

    useEffect(() => {
        api.getAfiliadoCompletoVW({ 'id': afiliado_aux }).then(respuesta => {
            setAfiliado({
                apellido: respuesta.data.apellido,
                aporte_caja_jub: respuesta.data.aporte_caja_jub,
                aporte_sist_nac: respuesta.data.aporte_sist_nac,
                celular: respuesta.data.celular ? respuesta.data.celular : '',
                celular1: respuesta.data.celular1 ? respuesta.data.celular1 : '',
                caracteristica_c: respuesta.data.caracteristica_c ? respuesta.data.caracteristica_c : '',
                caracteristica_c1: respuesta.data.caracteristica_c1 ? respuesta.data.caracteristica_c1 : '',
                caracteristica_t: respuesta.data.caracteristica_t ? respuesta.data.caracteristica_t : '',
                cuil: respuesta.data.cuil,
                prov: respuesta.data.prov ? respuesta.data.prov : '',
                pnac: respuesta.data.pnac ? respuesta.data.pnac : '',
                dni: respuesta.data.dni,
                domicilio: respuesta.data.domicilio ? respuesta.data.domicilio : '',
                edad: respuesta.data.edad ?? '',
                email: respuesta.data.email ? respuesta.data.email : '',
                empresa: respuesta.data.empresa ? respuesta.data.empresa : '',
                estado_civil: respuesta.data.estado_civil,
                fecha_afiliacion: respuesta.data.fecha_afiliacion,
                fecha_baja_afiliacion: respuesta.data.fecha_baja_afiliacion,
                fecha_ingreso: respuesta.data.fecha_ingreso,
                fecha_jubilacion: respuesta.data.fecha_jubilacion,
                fecha_nacimiento: respuesta.data.fecha_nacimiento ?? '',
                fondo_compensador: respuesta.data.fondo_compensador,
                funcion: respuesta.data.funcion,
                grupo_sanguineo: respuesta.data.grupo_sanguineo ? respuesta.data.grupo_sanguineo : '',
                id_afiliado: respuesta.data.id_afiliado,
                id_persona: respuesta.data.id_persona,
                ingresos: respuesta.data.ingresos,
                localidad: respuesta.data.localidad ? respuesta.data.localidad : '',
                localidad_nac: respuesta.data.localidad_nac ? respuesta.data.localidad_nac : '',
                nacionalidad: respuesta.data.nacionalidad ? respuesta.data.nacionalidad : '',
                nombre: respuesta.data.nombre,
                obra_social: respuesta.data.obra_social,
                sindicato: respuesta.data.sindicato,
                sistema_nacional: respuesta.data.sistema_nacional,
                sistema_provicional: respuesta.data.sistema_provicional,
                telefono: respuesta.data.telefono,
                genero: respuesta.data.genero,
                vigente: respuesta.data.vigente
            });
            api.getAfiliadosAdherenteVW(afiliado_aux)
                .then(resp_ad => {
                    setAdherente({
                        loading: false,
                        error: null,
                        data: resp_ad.data
                    });
                })
                .catch((error) => {
                    setAdherente({
                        loading: false,
                        error: true,
                        data: null
                    });
                });
        }).catch(error => {
            console.log('error => ', error);
            setAfiliado({ error: true });
        });
    }, [afiliado_aux]);

    const { apellido,
        aporte_sist_nac,
        celular,
        cuil,
        domicilio,
        edad,
        email,
        empresa,
        estado_civil,
        fecha_afiliacion,
        fecha_ingreso,
        fecha_nacimiento,
        fondo_compensador,
        funcion,
        grupo_sanguineo,
        localidad,
        localidad_nac,
        nacionalidad,
        nombre,
        obra_social,
        sistema_nacional,
        telefono,
        prov,
        genero,
        caracteristica_c,
        caracteristica_t,
        caracteristica_c1,
        celular1,
        pnac,
        plan_especial,
        faf,
        res2689 } = afiliado;

    const [adherentes_, setAdherentes_] = useState([]);

    useEffect(() => {
        if (adherente.data) {
            setAdherentes_(adherente.data.map(e => {
                return {
                    Documento: (e.dni && e.dni !== null) ? e.dni.toString() : '',
                    Apellido: e.apellido ? e.apellido : ' ',
                    Nombre: e.nombre ? e.nombre : ' ',
                    'Fecha Nacimiento': e.fecha_nacimiento ? e.fecha_nacimiento : ' ',
                    Parentezco: e.relacion ? e.relacion : ' ',
                    id_adherente: e.id_adherente
                };
            }));
        } else {
            setAdherentes_([]);
        }
    }, [adherente]);

    const headers = [
        'Documento',
        'Apellido',
        'Nombre',
        'Fecha Nacimiento',
        'Parentezco'
    ];

    return (
        <>
            <ReportesListadosAdherenteCompletoPdf
                nombre="afiliado"
                headerTabla={headers}
                datos={adherentes_}
                afiliado={afiliado}
            >
            </ReportesListadosAdherenteCompletoPdf>

            <Container>
                <p><b style={{ fontSize: 25 }}>{nombre}, {apellido}</b>{grupo_sanguineo ? <>&nbsp;({grupo_sanguineo}) </> : ''} &nbsp;&nbsp;&nbsp;&nbsp;<b>DNI/CUIL:</b> {cuil}</p>
                <p>{fecha_nacimiento !== '' ? <><b>Fecha naciemiento:</b> {fecha_nacimiento} - {edad} &nbsp;&nbsp;&nbsp;&nbsp;</> : ''} {genero ? <>&nbsp;&nbsp;&nbsp;&nbsp;<b>Genero:</b> {genero} </> : ''}</p>

                <p><b>Nacionalidad:</b> {nacionalidad} &nbsp;&nbsp;&nbsp;&nbsp;<b>Lugar de naciemiento:</b> {pnac}, {localidad_nac}</p>
                <p><b>Dirección:</b> {domicilio}, {localidad}, {prov}</p>

                <p>
                    <b>Teléfono:</b>{telefono !== '' ? <> ({caracteristica_t}) {telefono}</> : ' S/D'}
                    &nbsp;&nbsp;&nbsp;&nbsp;<b>Celular:</b> {celular !== '' ? <> ({caracteristica_c}) {celular}</> : ' S/D'}

                    {celular1 !== '' ? <>&nbsp;&nbsp;&nbsp;&nbsp;<b>Celular:</b> ({caracteristica_c1}) {celular1}</> : ''}
                </p>
                <p><b>E-mail:</b> {email} &nbsp;&nbsp;&nbsp;&nbsp;<b>Estado civil:</b> {estado_civil}</p>
            </Container>

            <Divider />

            <Container>
                <p><b>Trabajo:</b> {empresa} &nbsp;&nbsp;&nbsp;&nbsp;<b>Función:</b> {funcion} &nbsp;&nbsp;&nbsp;&nbsp;<b>Ingreso:</b> {fecha_ingreso}</p>
                <p><b>Obra Social:</b> {obra_social} &nbsp;&nbsp;&nbsp;&nbsp;</p>
                <p><b>Sistema Jubilación:</b> {aporte_sist_nac !== 'No' ? 'Sistema Nacional' : 'Sistema Provicional'} &nbsp;&nbsp;&nbsp;&nbsp;<b>Cuál:</b> {sistema_nacional}</p>
                <p><b> Fecha afiliación:</b> {fecha_afiliacion}</p>
                <p><b>Planes:</b>
                    {parseInt(plan_especial) === 1 ? 'Aporte Solidario OSFATLYF (Plan Especial), ' : ' '}
                    {parseInt(faf) === 1 ? 'Fondo de Auxilio Financiero (FAF), ' : ' '}
                    {parseInt(res2689) === 1 ? 'Res. 268/9' : ' '}
                </p>

                <p><b>Fondo Compensador:</b> {fondo_compensador !== null ? fondo_compensador : ''}</p>

            </Container>

            <AdherenteListado adherentes={adherente} />
        </>
    )
}