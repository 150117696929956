import * as React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from "@mui/icons-material/Clear";

const Modal = (props) => {
  const { handleClose, open } = props;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth={props.size}
    >
      {props.title === null && props.responsiveTitle === null ? (
        ""
      ) : (
        <DialogTitle
          sx={{
            backgroundColor: "#617F43",
            color: "#fff",
            height: 60,
          }}
        >
          <Grid container>
            <Grid item xs={9}>
              <Typography
                variant="h6"
                sx={{ display: { xs: "none", md: "inline" } }}
              >
                {props.title}
              </Typography>

              <Typography
                variant="h6"
                sx={{ display: { xs: "inline", md: "none" } }}
              >
                {props.responsiveTitle ? props.responsiveTitle : props.title}
              </Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  color: "#FFFFFF",
                  paddingTop: 0,
                }}
                color="primary"
                fontSize="small"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
      )}
      <DialogContent dividers>{props.children}</DialogContent>
      {props.closeButton ? (
        <DialogActions>
          <Button
            variant="plata"
            onClick={handleClose}
            endIcon={<ClearIcon />}
            sx={{ m: 1 }}
          >
            Cerrar
          </Button>
        </DialogActions>
      ) : (
        ""
      )}
    </Dialog>
  );
};

Modal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  responsiveTitle: PropTypes.string,
  closeButton: PropTypes.bool,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

Modal.defaultProps = {
  closeButton: false,
  title: null,
  responsiveTitle: null,
  size: "md",
};

export default Modal;