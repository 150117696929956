import React, { useEffect, useReducer } from 'react'
import { AuthContext } from './auth/AuthContext'
import { authReducer } from './auth/authReducer'
import { AppRouter } from './routers/AppRouter'
import DataTables from "datatables.net-dt";

const init = () => {
    return JSON.parse(localStorage.getItem('user')) || { logged: false }
}
export const LuzYFuerzaApp = () => {
    const dataTablesInfo = (s, start, end, max, total, result) => {
        var msg = "";
        if (total === 0) {
            msg = "No hay registros para mostrar";
        } else {
            var api = new DataTables.Api(s);
            if (api.page.info().pages > 1) {
                msg = `Mostrando del ${start} al ${end} de un total de ${total} registros`;
            } else {
                msg = `Mostrando ${total} registros`;
            }
        }
        if (total !== max) {
            msg += ` (filtrados de ${max} registros)`;
        }
        return msg;
    };

    Object.assign(DataTables.defaults, {
        layout: {
            topStart: {
                info: {
                    callback: dataTablesInfo
                }
            },
            topEnd: 'search',
            bottomStart:  {
                info: {
                    callback: dataTablesInfo
                }
            },
            bottomEnd: 'paging'
        },
        searching: false,
        paging: false,
        language: {
            processing: "Procesando...",
            lengthMenu: "Mostrar _MENU_ registros",
            zeroRecords: "No se encontraron resultados",
            emptyTable: "Ningún dato disponible en esta tabla",
            info: "Mostrando del _START_ al _END_ de un total de _TOTAL_ registros",
            infoEmpty: "No hay registros para mostrar",
            infoFiltered: "(filtrados de un total de _MAX_ registros)",
            infoPostFix: "",
            search: "Buscar en resultados:",
            url: "",
            infoThousands: ".",
            loadingRecords: "Cargando...",
            paginate: {
                first: "Primero",
                last: "Ultimo",
                next: "Siguiente",
                previous: "Anterior"
            },
            aria: {
                sortAscending: ": Activar para ordenar la columna de manera ascendente",
                sortDescending: ": Activar para ordenar la columna de manera descendente"
            }
        }
    });

    const [user, dispatch] = useReducer(authReducer, {}, init)

    useEffect(() => {
        localStorage.setItem('user', JSON.stringify(user))
    }, [user])

    return (
        <AuthContext.Provider value={{ user, dispatch }}>
            <AppRouter />
        </AuthContext.Provider>
    )
}
