import React from 'react'
import {
    Alert,
    Container,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { AuthContext } from '../../auth/AuthContext'
import { useContext } from 'react';

export const AdherenteListado = ({
    adherentes,
    edicion,
    cerrar,
    adherenteSeleccionado,
    recargar,
    onAdherenteBajaDefinitiva
}) => {
    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
        root: {
            flexGrow: 1,
        },
        cabeceraTabla: {
            backgroundColor: 'darkgrey',
            color: 'white'
        },
        enEdicion: {
            backgroundColor: 'rgb(229, 246, 253) !important',
            '&:hover': {
                backgroundColor: 'rgb(214, 229, 235) !important',
            },
        },
        deshabilitado: {
            backgroundColor: '#EFAFC0 !important',
            '&:hover': {
                backgroundColor: '#DBA1B0 !important',
            },
        }
    });
    const { user: { isSuperAdmin } } = useContext(AuthContext);
    const classes = useStyles();
    const editar = (persona) => {
        cerrar(persona)
    };

    const eliminarDefinitivo = (adherente) => {
        onAdherenteBajaDefinitiva(adherente);
    };

    const getClassName = (adherente) => {
        if (adherenteSeleccionado && adherente.id_persona === adherenteSeleccionado.id_persona)
            return classes.enEdicion;
        if (adherente.vigente === 0)
            return classes.deshabilitado;
        return '';
    }

    return (
        <Container>
            <h5>Adherentes
                {recargar &&
                    <IconButton
                        color="primary"
                        size="small"
                        type="submit"
                        variant="outlined"
                        onClick={recargar}
                    >
                        <RefreshIcon />
                    </IconButton>
                }
            </h5>
            {adherentes.loading &&
                <Alert severity="info">
                    Cargando adherentes...
                </Alert>
            }
            {!adherentes.loading && adherentes.error &&
                <Alert severity="error">
                    Error al cargar listado de adherentes.
                </Alert>
            }
            {!adherentes.loading && adherentes.data && adherentes.data.length > 0 &&
                <TableContainer>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead className={classes.cabeceraTabla}>
                            <TableRow>
                                <TableCell align="left">Relación</TableCell>
                                <TableCell align="center">Documento/CUIL</TableCell>
                                <TableCell align="left">Apellido</TableCell>
                                <TableCell align="left">Nombre</TableCell>
                                <TableCell align="center">Fecha Nacimiento</TableCell>
                                <TableCell align="center">Genero</TableCell>
                                <TableCell align="center">Nro. Obra Social</TableCell>
                                <TableCell align="center">OSFATLYF</TableCell>
                                <TableCell align="center">Discapacidad</TableCell>
                                {edicion && <TableCell align="center"></TableCell>}
                                {isSuperAdmin && <TableCell align="center" width="1%">N° Interno</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>{adherentes.data && adherentes.data.map((row) => (
                            <TableRow hover key={row.id_persona} className={getClassName(row)}>
                                <TableCell align="left">{row.relacion}</TableCell>
                                <TableCell align="center">{row.cuil}</TableCell>
                                <TableCell component="th" scope="row">{row.apellido}</TableCell>
                                <TableCell component="th" scope="row">{row.nombre}</TableCell>
                                <TableCell align="center">{row.fecha_nacimiento}</TableCell>
                                <TableCell align="center">{row.genero}</TableCell>
                                {row.pre_nro ? <TableCell align="center">{row.pre_nro}-{row.numero}-{row.post_nro}/{row.extra_nro}</TableCell> : <TableCell></TableCell>}
                                <TableCell align="center">{parseInt(row.osfatlyf) === 1 ? 'Sí' : 'No'}</TableCell>
                                <TableCell align="center">{parseInt(row.discapacidad) === 1 ? 'Sí' : 'No'}</TableCell>
                                {edicion &&
                                    <TableCell align='center'>
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            type="submit"
                                            variant="outlined"
                                            onClick={() => editar(row)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        {row.vigente === 0 && isSuperAdmin &&
                                            <IconButton
                                                color="error"
                                                size="small"
                                                type="submit"
                                                variant="outlined"
                                                onClick={() => eliminarDefinitivo(row)}
                                            >
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        }
                                    </TableCell>
                                }
                                {isSuperAdmin &&
                                    <TableCell align="right">{row.id_adherente}</TableCell>}
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            {!adherentes.loading && !adherentes.error && (!adherentes.data || adherentes.data.length === 0) &&
                <Alert severity="info">
                    El afiliado no posee adherentes.
                </Alert>
            }
        </Container>
    )
}
