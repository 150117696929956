import React from 'react'
import { useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import api from '../../service/api';

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import { Button, FormControl, Grid, Stack, TextField } from '@mui/material';
import Modal from '../ui/Modal';

export const BajaDefinitiva = ({ ver, afiliado, cerrar }) => {
    const {
        apellido,
        id_afiliado,
        id_persona,
        nombre
    } = afiliado

    const handleCloseModalXs = () => {
        formik.resetForm()
    };

    const [submitting, setSubmitting] = useState(false);

    var validationSchema = Yup.object().shape({});
    const formik = useFormik({
        initialValues: {
            id_afiliado: id_afiliado,
            usuario: '',
            password: ''
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitting(true);
            api.bajaDefinitivaAfiliado(values)
                .then((res) => {
                    formik.resetForm()
                    cerrar({ mensaje: 'Baja definitiva de afiliado exitosa.', error: 'success' })
                    setSubmitting(false);
                })
                .catch(function (error) {
                    formik.resetForm()
                    cerrar({ mensaje: error, error: 'error' })
                    setSubmitting(false);
                })
        },
        onReset: () => { },
    });

    const cerrar01 = (resultado) => {
        formik.resetForm()
        cerrar({ mensaje: 'Acción cancelada por el usuario.', error: 'warning' })
    }

    return (
        <Modal open={ver} handleClose={handleCloseModalXs} size="sm">
            <form
                id="formAltaObra"
                name="formAltaObra"
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}
                autoComplete="off"
            >
                <Typography variant="titulo" paragraph>
                    <b>Baja definitiva de afiliado</b>
                </Typography>
                <Grid container>
                    <p>El afiliado '<b>{apellido}, {nombre}</b>' (N° Interno {id_persona}/{id_afiliado}) se eliminará definitivamente.</p>
                    <p>¿Está seguro de proseguir? Ingrese las credenciales para continuar.</p>
                    <br />
                    <FormControl fullWidth>
                        <TextField
                            autoComplete="off"
                            label="Usuario"
                            name="usuario"
                            id="usuario"
                            type="text"
                            focused
                            variant="outlined"
                            value={formik.values.usuario}
                            onChange={e => { formik.handleChange(e) }}
                            error={formik.touched.usuario && Boolean(formik.errors.usuario)}
                            helperText={formik.touched.usuario && formik.errors.usuario}
                        />
                    </FormControl>
                </Grid>
                <br />
                <Grid container>
                    <FormControl fullWidth>
                        <TextField
                            autoComplete="off"
                            label="Clave"
                            name="password"
                            id="password"
                            type="password"
                            variant="outlined"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                    </FormControl>
                </Grid>
                <br />
                <Grid container>
                    <FormControl fullWidth>
                        <TextField
                            autoComplete="off"
                            label="Comentario"
                            name="comentario"
                            id="comentario"
                            type="textarea"
                            variant="outlined"
                            value={formik.values.comentario}
                            onChange={formik.handleChange}
                            error={formik.touched.comentario && Boolean(formik.errors.comentario)}
                            helperText={formik.touched.comentario && formik.errors.comentario}
                            multiline
                        />
                    </FormControl>
                </Grid>

                <br></br>
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="contained"
                        size="medium"
                        color="error"
                        disabled={submitting}
                        onClick={() => formik.submitForm()}
                        endIcon={<SaveIcon />}
                    >
                        {submitting ? "Eliminando..." : "Eliminar"}
                    </Button>

                    <Button
                        variant="secondary"
                        size="medium"
                        color="secondary"
                        onClick={() => cerrar01(false)}
                        disabled={submitting}
                        endIcon={<CloseIcon />}
                    >Cerrar
                    </Button>
                </Stack>

            </form>
        </Modal>
    )
}