import React from 'react';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import PolicyIcon from '@mui/icons-material/Policy';
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

import logo_1 from '../assets/logo.png';

export const InformesAuditPdf = (props) => {

    var today = new Date(),
        dateNow = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

    const handleClick = async () => {
        const documento = new jsPDF('p', 'pt', 'a4')

        const datamostrar = await props.datos.map(e => Object.values(e));

        //margen x- margen y - datos - cabecera - settings
        documento.setFontSize(2);
        autoTable(documento, {
            columns: props.headerTabla,
            body: datamostrar,
            margin: { top: 150 },
            styles: { fontSize: 6 }
        });

        //   pdfDoc.setFontSize(12);
        const pageCount = documento.internal.getNumberOfPages();

        for (let i = 1; i <= pageCount; i++) {
            documento.setPage(i);
            const pageSize = documento.internal.pageSize;
            const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            documento.setFontSize(8);
            const header = documento.addImage(logo_1, 'PNG', (pageWidth / 2) - 40, 20, 80, 80) + '' +
                documento.text('Fecha de impresión ' + dateNow, 440, 120);
            // documento.text(props.nombre, 260, 120, { align: 'center'}) + '' + 

            const footer = `Página ${i} de ${pageCount}`;

            // Header
            documento.text(header, -1, -3);
            documento.text(props.nombre, (pageWidth / 2) - (props.nombre.length * 2), 120);

            // Footer
            documento.text(footer, pageWidth / 2 - (documento.getTextWidth(footer) / 2), pageHeight - 15, { baseline: 'bottom' });
        }

        documento.save(`informe_auditorias_${props.subfixNamePdf}_${new Date().toISOString()}.pdf`);
    }

    const btnDisabled = props.datos === null || props.datos.length === 0;
    if (btnDisabled) {
        return (<></>);
    } else {
        return (
            <Tooltip title={`Imprimir ${props.nombre}`}>
                <IconButton
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={handleClick}
                >
                    {props.showNombre ?
                        <>
                            {props.nombre + " "}
                            < PolicyIcon />
                        </> :
                        <PrintIcon />
                    }
                </IconButton>
            </Tooltip>
        )
    }
}
