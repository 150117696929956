import React, { useState, useEffect } from 'react';
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Page } from '../ui/Page'
import { Alert } from '@mui/material';
import api from "../../service/api";

const Bold = styled("span")({
    fontWeight: 600,
});

export const Perfil = (props) => {

    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Perfil", ruta: "" },
    ];

    const [usuario, setUsuario] = useState({
        loading: true,
        data: {
            dni: "",
            nombre: "",
            perfil: "",
            codperfil: "",
        }
    });

    const buscarPerfil = async () => {
        api
            .usuarioConectado()
            .then(function (resp) {
                setUsuario({
                    loading: false,
                    data: {
                        dni: resp.data.usuario[0].dni,
                        nombre: resp.data.usuario[0].apellido + ", " + resp.data.usuario[0].nombre,
                        perfil: resp.data.usuario[0].perfil,
                        codperfil: resp.data.usuario[0].codigo,
                    }
                });
            })
            .catch(function (error) {
                console.log('El error es =>', error);
                setUsuario({
                    loading: false,
                    error: true,
                });
            });
    };

    useEffect(() => {
        buscarPerfil();
    }, []);

    if (usuario.loading) {
        return (
            <Page title="Perfil de Usuario" breadcrumbs={breadcrumbs}>
                <Alert severity="info">
                    Cargando...
                </Alert>
            </Page>
        );
    } else if (!usuario.loading && usuario.error) {
        return (
            <Page title="Perfil de Usuario" breadcrumbs={breadcrumbs}>
                <Alert severity="error">
                    Error al cargar los datos
                </Alert>
            </Page>
        );
    } else {
        return (
            <Page title="Perfil de Usuario" breadcrumbs={breadcrumbs}>
                <Typography paragraph variant="cuerpo">
                    <Bold>Documento</Bold>: {usuario.data.dni}{" "}
                </Typography>
                <Typography paragraph variant="cuerpo">
                    <Bold>Apellido y Nombre</Bold>: {usuario.data.nombre}{" "}
                </Typography>
                <Typography paragraph variant="cuerpo">
                    <Bold>Perfil</Bold>: {usuario.data.perfil + " (cod:" + usuario.data.codperfil + ")"}
                </Typography>
            </Page>
        );
    }
}
