import React, { useState, useEffect, useContext } from 'react';
import {
    Tooltip,
    Table, TableBody,
    TableCell, TableContainer, TableHead,
    TableRow, Button, Grid, FormControl, TextField, Stack, Snackbar
} from '@mui/material';
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import { DocumentoListadoPdf } from '../ui/ReportesListadosPdf';

import Modal from '../ui/Modal';
import { Page } from '../ui/Page';
import api from "../../service/api";
import { AuthContext } from '../../auth/AuthContext';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    root: {
        flexGrow: 1,
    },
    cabeceraTabla: {
        backgroundColor: 'darkgrey',
        color: 'white'
    },
    deshabilitado: {
        backgroundColor: '#FFCF8F !important',
        '&:hover': {
            backgroundColor: '#F1C589 !important',
        },
    }
});

export const ObraSocial = (props) => {
    const { user: { canABMObrasSociales } } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [openModalXsNew, setOpenModalXsNew] = useState(false);
    const [openModalXsEdit, setOpenModalXsEdit] = useState(false);
    const [openModalXsDelete, setOpenModalXsDelete] = useState(false);
    const [submittingNew, setSubmittingNew] = useState(false);
    const [submittingEdit, setSubmittingEdit] = useState(false);
    const [submittingDelete, setSubmittingDelete] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState("");
    const [tipoMensaje, setTipoMensaje] = useState("");
    const [id, setId] = useState("");
    const [nombreMostrar, setNombreMostrar] = useState("");
    const [refrescar, setRefrescar] = useState(0);
    const [obras, setObra] = useState({ loading: true, data: [], error: false });
    const classes = useStyles();
    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Obras Sociales", ruta: "/obrasocial" },
    ];
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const getClassName = (row) => {
        if (row.activo === 'N')
            return classes.deshabilitado;
        return '';
    }

    var validationSchemaNew = Yup.object().shape({
        codObra: Yup.number()
            .optional()
            .integer("Debe ser un número"),
        abreviaturaObra: Yup.string()
            .required("Requerido")
            .min(2, "Mínimo 2 caracteres")
            .max(100, "Máximo 20 caracteres"),
        nombreObra: Yup.string()
            .required("Requerido")
            .min(2, "Mínimo 2 caracteres")
            .max(100, "Máximo 100 caracteres")
    });
    const formikNew = useFormik({
        initialValues: {
            nombreObra: "",
            codObra: "",
            abreviaturaObra: "",
        },
        enableReinitialize: true,
        validationSchema: validationSchemaNew,
        onSubmit: (values) => {
            setSubmittingNew(true);
            api.obrasSociales
                .altaObra({
                    nombre: values.nombreObra,
                    codigo: values.codObra,
                    abreviatura: values.abreviaturaObra,
                })
                .then((res) => {
                    setOpen(true);
                    setMensajeAlert('Se gurdó con éxito');
                    setTipoMensaje('success');
                    setSubmittingNew(false);
                    setOpenModalXsNew(false);
                    formikNew.handleReset();
                    setRefrescar(refrescar + 1);
                })
                .catch(function (error) {
                    setSubmittingNew(false);
                    setOpen(true);
                    setMensajeAlert('Error al guardar. Verifique los datos ingresados');
                    setTipoMensaje('error');
                    setSubmittingNew(false);
                    console.log('El error es =>', error)
                })
        },
        onReset: () => { },
    });

    var validationSchemaEdit = Yup.object().shape({
        codObraMod: Yup.number()
            .optional()
            .integer("Debe ser un número"),
        abreviaturaObraMod: Yup.string()
            .required("Requerido")
            .min(2, "Mínimo 2 caracteres")
            .max(100, "Máximo 20 caracteres"),
        nombreObraMod: Yup.string()
            .required("Requerido")
            .min(2, "Mínimo 2 caracteres")
            .max(100, "Máximo 100 caracteres")
    });
    const formikEdit = useFormik({
        initialValues: {
            id: "",
            nombreObraMod: "",
            codObraMod: "",
            abreviaturaObraMod: "",
        },
        enableReinitialize: true,
        validationSchema: validationSchemaEdit,
        onSubmit: (values) => {
            setSubmittingEdit(true);
            api.obrasSociales
                .modificarObra({
                    id: values.id,
                    nombre: values.nombreObraMod,
                    codigo: values.codObraMod,
                    abreviatura: values.abreviaturaObraMod
                })
                .then((res) => {
                    setOpen(true);
                    setMensajeAlert('Se modificó con éxito');
                    setTipoMensaje('success');
                    setSubmittingEdit(false);
                    setOpenModalXsEdit(false);
                    formikEdit.handleReset();
                    setRefrescar(refrescar + 1);
                })
                .catch(function (error) {
                    setSubmittingEdit(false);
                    setOpen(true);
                    setMensajeAlert('Error al modificar. Verifique los datos ingresados');
                    setTipoMensaje('error');
                    setSubmittingEdit(false);
                    console.log('El error es =>', error)
                })
        },
        onReset: () => { },
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleCloseModalXsNew = () => {
        setOpenModalXsNew(false);
    };

    const handleCloseModalXsEdit = () => {
        setOpenModalXsEdit(false);
    };

    const handleCloseModalXsDelete = () => {
        setOpenModalXsDelete(false);
    };

    const handleClickNew = () => {
        formikNew.handleReset();
        setOpenModalXsNew(true);
    };

    const handleClickEdit = (e, data, dataabre, datacod) => {
        formikEdit.setFieldValue("id", e);
        formikEdit.setFieldValue("nombreObraMod", data);
        formikEdit.setFieldValue("abreviaturaObraMod", dataabre);
        formikEdit.setFieldValue("codObraMod", datacod);
        setOpenModalXsEdit(true);
    };

    const handleClickDelete = (e, desc, cod) => {
        setId(e);
        setNombreMostrar('(' + cod + ') ' + desc);
        setOpenModalXsDelete(true);
    };

    const confirmarDelete = (id) => {
        setSubmittingDelete(true);
        api.obrasSociales
            .eliminarObras({
                'id': id,
            })
            .then(function (resp) {
                setOpenModalXsDelete(false);
                setOpen(true);
                setMensajeAlert('Se eliminó con éxito');
                setTipoMensaje('success');
                setSubmittingDelete(false);
                setRefrescar(refrescar + 1);
            })
            .catch(function (error) {
                setSubmittingDelete(false);
                setOpen(true);
                console.log('El error es =>', error)
                setMensajeAlert('Error al eliminar');
                setTipoMensaje('error');
            })
    }

    const confirmarNew = () => {
        formikNew.submitForm();
    }

    const confirmarEdit = () => {
        formikEdit.submitForm();
    }

    const headers = [
        'Id',
        'Codigo',
        'Abreviacion',
        'Nombre completo',
    ]

    useEffect(() => {
        let procesar = true;
        api.obrasSociales
            .buscarObras()
            .then(function (resp) {
                if (procesar)
                    setObra({ loading: false, data: resp.data });
            })
            .catch(function (error) {
                if (procesar)
                    setObra({ error: true, data: [] });
                console.log('El error es =>', error)
            });
        return () => procesar = false;
    }, [refrescar]);

    const obrasPdf = (obras.data &&
        obras.data.map((e) => {
            return {
                id: e.id,
                codigo: e.codigo,
                abreviatura: e.abreviatura,
                descripcion: e.descripcion,
            };
        })) || [];

    const pageTitle = "Obras Sociales";
    if (obras.loading) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Grid item xs={12}>
                    <Alert severity="info">
                        Cargando...
                    </Alert>
                </Grid>
            </Page>
        )
    } else if (obras.error) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Grid item xs={12}>
                    <Alert severity="error">
                        No se pudo cargar listado de obras sociales.
                    </Alert>
                </Grid>
            </Page>
        )
    } else {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}>
                    <Alert onClose={handleClose} severity={tipoMensaje} sx={{
                        width: '100%'
                    }}>
                        {mensajeAlert}
                    </Alert>
                </Snackbar>
                {canABMObrasSociales &&
                    <Grid container>
                        <Grid item xs={6} align="left">
                            <DocumentoListadoPdf
                                nombre="Obras Sociales"
                                headerTabla={headers}
                                datos={obrasPdf}
                            >
                            </DocumentoListadoPdf>
                        </Grid>
                        <Grid item xs={6} align="right">
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={handleClickNew}
                                endIcon={<AddIcon />}
                            >Nuevo</Button>
                        </Grid>
                    </Grid>
                }
                <br></br>
                <Grid container>
                    <Grid item>Obras sociales encontradas: {obras.data.length}</Grid>
                    <TableContainer>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead className={classes.cabeceraTabla}>
                                <TableRow>
                                    <TableCell align="center">Codigo</TableCell>
                                    <TableCell align="center">Abreviación</TableCell>
                                    <TableCell align="center">Nombre completo</TableCell>
                                    {canABMObrasSociales &&
                                        <TableCell align="center"></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {obras.data.map((row) => (
                                    <TableRow key={row.id} className={getClassName(row)}>
                                        <TableCell align="center" component="th" scope="row">{row.codigo}</TableCell>
                                        <TableCell align="center">{row.abreviatura}</TableCell>
                                        <TableCell align="left">{row.descripcion}</TableCell>
                                        {canABMObrasSociales &&
                                            <TableCell align="right">
                                                <Tooltip title="Modificar obra social">
                                                    <IconButton
                                                        color="primary"
                                                        size="small"
                                                        variant="outlined"
                                                        onClick={() => handleClickEdit(row.id, row.descripcion, row.abreviatura, row.codigo)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Eliminar obra social">
                                                    <IconButton
                                                        color="primary"
                                                        size="small"
                                                        variant="outlined"
                                                        onClick={() => handleClickDelete(row.id, row.abreviatura, row.codigo)}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {canABMObrasSociales &&
                    <>
                        <Modal open={openModalXsNew} handleClose={handleCloseModalXsNew} size="sm">
                            <form
                                id="formAltaObra"
                                name="formAltaObra"
                                onSubmit={formikNew.handleSubmit}
                                onReset={formikNew.handleReset}
                                autoComplete="off"
                            >
                                <Typography variant="titulo" paragraph>
                                    Nueva obra social
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={4} >
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete="off"
                                                placeholder="Código"
                                                label="Código"
                                                name="codObra"
                                                id="codObra"
                                                type="text"
                                                variant="outlined"
                                                value={formikNew.values.codObra}
                                                onChange={formikNew.handleChange}
                                                error={formikNew.touched.codObra && Boolean(formikNew.errors.codObra)}
                                                helperText={formikNew.touched.codObra && formikNew.errors.codObra}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8} >
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete="off"
                                                placeholder="Abreviatura"
                                                label="Abreviatura"
                                                name="abreviaturaObra"
                                                id="abreviaturaObra"
                                                type="text"
                                                variant="outlined"
                                                value={formikNew.values.abreviaturaObra}
                                                onChange={formikNew.handleChange}
                                                error={formikNew.touched.abreviaturaObra && Boolean(formikNew.errors.abreviaturaObra)}
                                                helperText={formikNew.touched.abreviaturaObra && formikNew.errors.abreviaturaObra}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <br></br>
                                <Grid container>
                                    <Grid item xs={12} >
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete="off"
                                                placeholder="Nombre de la obra social"
                                                label="Nombre de la obra social"
                                                name="nombreObra"
                                                id="nombreObra"
                                                type="text"
                                                variant="outlined"
                                                value={formikNew.values.nombreObra}
                                                onChange={formikNew.handleChange}
                                                error={formikNew.touched.nombreObra && Boolean(formikNew.errors.nombreObra)}
                                                helperText={formikNew.touched.nombreObra && formikNew.errors.nombreObra}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <br></br>
                                <br></br>
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="primary"
                                        disabled={submittingNew}
                                        onClick={confirmarNew}
                                        endIcon={<SaveIcon />}
                                    >
                                        {submittingNew ? "Guardando..." : "Guardar"}
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        size="medium"
                                        color="secondary"
                                        onClick={handleCloseModalXsNew}
                                        endIcon={<CloseIcon />}
                                    >Cerrar
                                    </Button>
                                </Stack>
                            </form>
                        </Modal>
                        <Modal open={openModalXsEdit} handleClose={handleCloseModalXsEdit} size="sm">
                            <form
                                id="formModObra"
                                name="formModObra"
                                onSubmit={formikEdit.handleSubmit}
                                onReset={formikEdit.handleReset}
                                autoComplete="off"
                            >
                                <Typography variant="titulo" paragraph>
                                    Modificar obra social
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={4} >
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete="off"
                                                placeholder="Código"
                                                label="Código"
                                                name="codObraMod"
                                                id="codObraMod"
                                                type="text"
                                                variant="outlined"
                                                value={formikEdit.values.codObraMod}
                                                onChange={formikEdit.handleChange}
                                                error={formikEdit.touched.codObraMod && Boolean(formikEdit.errors.codObraMod)}
                                                helperText={formikEdit.touched.codObraMod && formikEdit.errors.codObraMod}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8} >
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete="off"
                                                placeholder="Abreviatura"
                                                label="Abreviatura"
                                                name="abreviaturaObraMod"
                                                id="abreviaturaObraMod"
                                                type="text"
                                                variant="outlined"
                                                value={formikEdit.values.abreviaturaObraMod}
                                                onChange={formikEdit.handleChange}
                                                error={formikEdit.touched.abreviaturaObraMod && Boolean(formikEdit.errors.abreviaturaObraMod)}
                                                helperText={formikEdit.touched.abreviaturaObraMod && formikEdit.errors.abreviaturaObraMod}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <br></br>
                                <Grid container>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete="off"
                                            placeholder="Nombre de la obra social"
                                            label="Nombre de la obra social"
                                            name="nombreObraMod"
                                            id="nombreObraMod"
                                            type="text"
                                            variant="outlined"
                                            value={formikEdit.values.nombreObraMod}
                                            onChange={formikEdit.handleChange}
                                            error={formikEdit.touched.nombreObraMod && Boolean(formikEdit.errors.nombreObraMod)}
                                            helperText={formikEdit.touched.nombreObraMod && formikEdit.errors.nombreObraMod}
                                        />
                                    </FormControl>
                                </Grid>
                                <br></br>
                                <br></br>
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="primary"
                                        onClick={confirmarEdit}
                                        endIcon={<EditIcon />}
                                    >
                                        {submittingEdit ? "Modificando..." : "Modificar"}
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        size="medium"
                                        color="secondary"
                                        onClick={handleCloseModalXsEdit}
                                        endIcon={<CloseIcon />}
                                    >Cerrar
                                    </Button>
                                </Stack>
                            </form>
                        </Modal>
                        <Modal open={openModalXsDelete} handleClose={handleCloseModalXsDelete} size="sm">
                            <Typography variant="titulo" paragraph>
                                Eliminar obra social
                            </Typography>
                            <Typography variant='cuerpo'><b>{nombreMostrar}</b></Typography>
                            <br></br>
                            <br></br>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    onClick={() => confirmarDelete(id)}
                                >
                                    {submittingDelete ? "Eliminando..." : "Eliminar"}
                                </Button>
                                <Button
                                    variant="secondary"
                                    size="medium"
                                    color="secondary"
                                    onClick={handleCloseModalXsDelete}
                                    endIcon={<CloseIcon />}
                                >Cerrar
                                </Button>
                            </Stack>
                        </Modal>
                    </>
                }
            </Page>
        )
    }
}