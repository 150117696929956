import React, { useContext, useState, useRef } from 'react'
import { AuthContext } from '../../auth/AuthContext'
import { types } from '../../types/types'
import { useForm } from '../../hooks/useForm'
import logo from '../../assets/logo.png'
import ReCAPTCHA from "react-google-recaptcha";
import Typography from "@mui/material/Typography";
import MuiPaper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { styled } from "@mui/material/styles";
import { Box } from '@mui/system'
import api from "../../service/api";
import { Button, Grid } from '@mui/material';
import { Redirect } from 'react-router-dom';

const Error = styled(Box)(({ theme }) => ({
  backgroundColor: "#fadddd",
  color: "#772b35",
  borderColor: "#f8cfcf",
  borderRadius: ".25rem",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  textAlign: "center",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(5, 5, 5, 5),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  border: "1px solid #e0e0e0",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(5, 1, 5, 1),
  },
}));

const FormBox = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export const LoginScreen = () => {

  const captcha = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, handleInputChange] = useForm({
    name: '',
    password: '',
  });
  const { name, password } = formValues;
  const [loginOk, setLoginOk] = useState(false);

  const { dispatch } = useContext(AuthContext)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = () => {
    setErrorMessage("");
    setSubmitting(true);
    let datos = {
      usuario: name,
      contrasenia: password,
      captcha: captcha.current.getValue(),
    };
    api
      .login(datos)
      .then(function (resp) {
        setSubmitting(false);
        captcha.current.reset();
        if (resp.data.usuario) {
          const usr = resp.data.usuario[0];
          localStorage.setItem("user_id", usr.id_user);
          localStorage.setItem("perfil_id", usr.codigo);
          localStorage.setItem("api_token", resp.data.usuario.jwt);
          setLoginOk(true);
          dispatch({
            type: types.login,
            payload: usr,
          });
        } else {
          setErrorMessage("ERROR - Usuario no encontrado");
        }
      })
      .catch((e) => {
        setSubmitting(false);
        console.log("Error ", e);
        captcha.current.reset();
        setErrorMessage("Error - Usuario o Contraseña incorrecto");
      })
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (name.length === 0 || password.length === 0) {
      setErrorMessage("Debe ingresar el usuario y la contraseña");
    } else if (!/^-?\d+$/.test(name)) {
      setErrorMessage("Usuario: Solo números");
    } else if (password.length < 6) {
      setErrorMessage("Contraseña: Mínimo 6 caracteres.");
    } else if (process.env.REACT_APP_RECAPTCHA_KEY !== 'disabled' && captcha.current.getValue() === "") {
      setErrorMessage("Debe validar el reCAPTCHA.");
    } else {
      handleLogin();
    }
  };

  if (loginOk) {
    const lastPath = localStorage.getItem('lastPath') || '/';
    return <Redirect to={lastPath} />
  } else
    return (
      <div>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          height="95vh"
        >
          <Grid container spacing={1} justifyContent="center">
            <Grid item lg={7} sm={12} xs={12}>
              <Paper elevation={0}>
                <Box mb={1}>
                  <img src={logo} mb={1} alt="Logo" width="80px" />
                </Box>
                <Box alignSelf="flex-start" sx={{ p: 1 }}>
                  <Typography component="h1" variant="h5">
                    {String(
                      process.env.REACT_APP_NOMBRE_SISTEMA
                    ).toUpperCase()}
                  </Typography>
                  <Typography sx={{ color: "#768192" }}>
                    {String(process.env.REACT_APP_SUBNOMBRE_SISTEMA)}
                  </Typography>
                </Box>
                <FormBox sx={{ width: "100%" }}>

                  <TextField
                    variant="outlined"
                    size="small"
                    margin="normal"
                    fullWidth
                    id="name"
                    label="DNI"
                    name="name"
                    autoFocus
                    onChange={handleInputChange}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    margin="normal"
                    fullWidth
                    name="password"
                    label="CONTRASEÑA"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    onChange={handleInputChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box mt={2} mb={2}>
                    <div align="center">
                      <ReCAPTCHA
                        ref={captcha}
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                      />
                    </div>
                  </Box>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={submitting}
                  >
                    {submitting ? "INGRESANDO..." : "INGRESAR"}
                  </Button>
                  <Box
                    component="div"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    m={1}
                  >
                  </Box>
                  {errorMessage !== "" ? (
                    <Error>
                      <Typography
                        component="p"
                        variant="cuerpo"
                        sx={{ color: "#b71c1c" }}
                      >
                        {errorMessage}
                      </Typography>
                    </Error>
                  ) : ("")}
                </FormBox>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </div>
    )
}
