import React, { useEffect } from 'react'
import { useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import api from '../../service/api';

import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import { Button, FormControl, Grid, Stack, TextField, Container } from '@mui/material';
import Modal from '../ui/Modal';
import { ComentarioListado } from './ComentarioListado';

export const RevertirBajaAfiliacion = ({ ver, afiliado, cerrar }) => {
    const {
        apellido,
        fecha_baja_afiliacion,
        id_afiliado,
        nombre
    } = afiliado
    const [comentariosListado, setComentariosListado] = useState(false);

    useEffect(() => {
        if (ver) {
            api.getComentariosAfiliados({ id_afiliado: id_afiliado, accion: 'B' })
                .then(respuesta => setComentariosListado(respuesta.data))
        } else {
            setComentariosListado([]);
        }
    }, [id_afiliado, fecha_baja_afiliacion, ver])

    const [submitting, setSubmitting] = useState(false);

    var validationSchema = Yup.object().shape({});
    const formik = useFormik({
        initialValues: {
            id_afiliado: id_afiliado,
            vigente: 1,
            comentario: ''
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitting(true);
            api.revertirBajaAfiliado(values)
                .then((res) => {
                    formik.resetForm()
                    cerrar({ mensaje: 'La deshizo la baja del afiliado.', error: 'success' })
                    setSubmitting(false);
                })
                .catch(function (error) {
                    if (!error)
                        error = "No se pudo revertir la baja del afiliado.";
                    setSubmitting(false);
                    cerrar({ mensaje: error, error: 'error' })
                    setSubmitting(false);
                })
        },
        onReset: () => { },
    });

    const cerrar01 = (resultado) => {
        formik.resetForm()
        cerrar({ mensaje: 'Acción cancelada por el usuario.', error: 'warning' })
    }

    return (
        <Modal open={ver} size="sm" handleClose={() => {}}>
            <form
                id="formAltaObra"
                name="formAltaObra"
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}
                autoComplete="off"
            >
                <Typography variant="titulo" paragraph>
                    Revertir Baja de afiliación
                </Typography>
                <Container>
                    <p>Afiliado: <b>{apellido}, {nombre}</b></p>
                    <p>Fecha de baja: <b>{fecha_baja_afiliacion}</b></p>
                </Container>
                <br />
                <Grid container>
                    <FormControl fullWidth>
                        <TextField
                            autoComplete="off"
                            label="Comentario"
                            name="comentario"
                            id="comentario"
                            type="textarea"
                            variant="outlined"
                            value={formik.values.comentario}
                            onChange={formik.handleChange}
                            error={formik.touched.comentario && Boolean(formik.errors.comentario)}
                            helperText={formik.touched.comentario && formik.errors.comentario}
                            multiline
                        />
                    </FormControl>
                </Grid>
                <br></br>

                <ComentarioListado comentariosListado={comentariosListado} />
                <br></br>
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="contained"
                        size="medium"
                        color="primary"
                        disabled={submitting || !(fecha_baja_afiliacion !== null && fecha_baja_afiliacion !== '00/00/0000')}
                        onClick={() => formik.submitForm()}
                        endIcon={<SaveIcon />}
                    >
                        {submitting ? "Revirtiendo baja..." : "Revertir baja"}
                    </Button>
                    <Button
                        variant="secondary"
                        size="medium"
                        color="secondary"
                        onClick={() => cerrar01(false)}
                        disabled={submitting}
                        endIcon={<CloseIcon />}
                    >Cerrar
                    </Button>
                </Stack>
            </form>
        </Modal>
    )
}