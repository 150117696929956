import React, { useContext } from 'react'
import { Switch, Route, Redirect, HashRouter } from 'react-router-dom'
import { AuthContext } from '../auth/AuthContext'
import { SearchScreen } from '../search/SearchScreen'
import { AfiliadoScreen } from '../components/afiliado/AfiliadoScreen'
import { AfiliadoInformacionScreen } from '../components/afiliado/AfiliadoInformacionScreen'
import { Perfil } from '../components/login/Perfil'
import { Inicio } from '../components/inicio/Inicio'
import { EmpleoScreen } from '../components/afiliado/EmpleoScreen'
import { Empresa } from '../components/empresa/Empresa'
import { ObraSocial } from '../components/obrasocial/ObraSocial'
import { Padron } from '../components/padron/Padron'
import { Usuario } from '../components/usuario/Usuario'
import { AfiliadoEdicion } from '../components/afiliado/AfiliadoEdicion'
import { InformeAfiliadosAll } from '../components/informes/InformeAfiliadosAll'
import { AdherenteEdicion } from '../components/afiliado/AdherenteEdicion'
import { EmpleoEdicion } from '../components/afiliado/EmpleoEdicion'
import { EmpleoEdicionCompleto } from '../components/afiliado/EmpleoEdicionCompleto'
import { SearchJubilados } from '../search/SearchJubilados'
import { JubNuevos } from '../components/jubilados/JubNuevos'
import { SearchUnitaria } from '../search/SearchUnitaria'
import { AuditarIngresos } from '../components/auditorias/AuditarIngresos'
import { AuditarAfiliados } from '../components/auditorias/AuditarAfiliados'
import { AuditarEmpresas } from '../components/auditorias/AuditarEmpresas'
import { AuditarObrasSociales } from '../components/auditorias/AuditarObrasSociales'
import { AuditarUsuarios } from '../components/auditorias/AuditarUsuarios'

export const HasAccessRoute = ({
    hasAccess,
    component: Component,
    ...rest
}) => {
    return (
        <Route {...rest}
            component={(props) => (
                (hasAccess)
                    ? (<Component {...props} />)
                    : (<Redirect to="/inicio" />)
            )}
        />
    )
}

export const DashboardRoutes = () => {
    const { user: {
        afiliadosBusqueda,
        nuevoAfiliado,
        jubiladosBusqueda,
        nuevoJubilado,
        canABMEmpresas,
        canVerEmpresas,
        canABMObrasSociales,
        canABMUsuarios,
        canAuditorias,
        canPadron,
        canEditarAdherentes,
        canEditarEmpleoCompleto,
        canEditarEmpleoParcial,
        canEditarAfiliado,
        canInformeAfiliados,
        canBusquedaUnitaria,
    } } = useContext(AuthContext);
    return (
        <div className="container mt-2">
            <HashRouter>
                <Switch>
                    <Route exact path="/perfil" component={Perfil} />
                    <Route exact path="/inicio" component={Inicio} />
                    <Route exact path="/informacion/:id" component={AfiliadoInformacionScreen} />
                    <Route exact path="/informacion/:id/:filtro" component={AfiliadoInformacionScreen} />
                    {canEditarEmpleoCompleto &&
                        <Route exact path="/empleo/:id" component={EmpleoScreen} />}
                    {canEditarEmpleoCompleto &&
                        <Route exact path="/empleoEdicionCompleto/:id" component={EmpleoEdicionCompleto} />}
                    {(canEditarEmpleoCompleto || canEditarEmpleoParcial) &&
                        <Route exact path="/empleoEdicion/:id" component={EmpleoEdicion} />}
                    {canEditarAdherentes &&
                        <Route exact path="/adherenteEdicion/:id/:filtro" component={AdherenteEdicion} />}
                    {canEditarAdherentes &&
                        <Route exact path="/adherenteEdicion/:id/:filtro?/:filtro1?/:filtro2?" component={AdherenteEdicion} />}
                    {nuevoAfiliado &&
                        <Route exact path="/afiliado" component={AfiliadoScreen} />}
                    {canEditarAfiliado &&
                        <Route exact path="/afiliadoEdicion/:id" component={AfiliadoEdicion} />}
                    {afiliadosBusqueda &&
                        <Route exact path="/search" component={SearchScreen} />}
                    {(canVerEmpresas || canABMEmpresas) &&
                        <Route exact path="/empresa" component={Empresa} />}
                    {canABMObrasSociales &&
                        <Route exact path="/obrasocial" component={ObraSocial} />}
                    {canPadron &&
                        <Route exact path="/padron" component={Padron} />}
                    {canABMUsuarios &&
                        <Route exact path="/usuario" component={Usuario} />}
                    {canInformeAfiliados &&
                        <Route exact path="/informeAfiliados" component={InformeAfiliadosAll} />}
                    {canAuditorias &&
                        <Route exact path="/auditorias/ingresos" component={AuditarIngresos} />}
                    {canAuditorias &&
                        <Route exact path="/auditorias/auditAfiliados" component={AuditarAfiliados} />}
                    {canAuditorias &&
                        <Route exact path="/auditorias/auditEmpresas" component={AuditarEmpresas} />}
                    {canAuditorias &&
                        <Route exact path="/auditorias/auditObrasSociales" component={AuditarObrasSociales} />}
                    {canAuditorias &&
                        <Route exact path="/auditorias/auditUsuarios" component={AuditarUsuarios} />}
                    {jubiladosBusqueda &&
                        <Route exact path="/searchJubilados" component={SearchJubilados} />}
                    {nuevoJubilado &&
                        <Route exact path="/jubilados" component={JubNuevos} />}
                    {canBusquedaUnitaria &&
                        <Route exact path="/busqueda" component={SearchUnitaria} />}
                    <Redirect to="/inicio" />
                </Switch>
            </HashRouter>
        </div>
    )
}
