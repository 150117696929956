import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import api from '../../service/api';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import { Button, FormControl, Grid, Stack, TextField, Alert } from '@mui/material';
import Modal from '../ui/Modal';
import { ComentarioListado } from './ComentarioListado';
import { AuthContext } from '../../auth/AuthContext';

export const RevertirJubilacion = ({ ver, afiliado, cerrar }) => {
    const {
        apellido,
        fecha_jubilacion,
        id_afiliado,
        nombre,
        jubilacion_definitiva
    } = afiliado;

    const { user: {
        isAdmin,
    } } = useContext(AuthContext);

    const [comentariosListado, setComentariosListado] = useState(false);
    useEffect(() => {
        if (ver) {
            api.getComentariosAfiliados({ id_afiliado: id_afiliado, accion: 'J' })
                .then(respuesta => setComentariosListado(respuesta.data));
        } else {
            setComentariosListado([]);
        }
    }, [id_afiliado, fecha_jubilacion, ver]);

    const handleCloseModalXs = () => {
        formik.resetForm();
    };
    const [submitting, setSubmitting] = useState(false);
    var validationSchema = Yup.object().shape({
    });
    const formik = useFormik({
        initialValues: {
            id_afiliado: id_afiliado,
            comentario: '',
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitting(true);
            if (jubilacion_definitiva) {
                if (puedeRevertirJubilacion) {
                    api.revertirJubilarAfiliado(values)
                        .then((res) => {
                            formik.resetForm();
                            cerrar({ mensaje: 'Se revirtio la jubilación.', error: 'success' });
                            setSubmitting(false);
                        })
                        .catch(function (error) {
                            formik.resetForm();
                            cerrar({ mensaje: error, error: 'error' });
                            setSubmitting(false);
                        });
                } else {
                    cerrar({ mensaje: 'No puede revertir una jubilación definitiva.', error: 'warning' });
                    setSubmitting(false);
                }
            } else {
                api.revertirPrejubilarAfiliado(values)
                    .then((res) => {
                        formik.resetForm();
                        cerrar({ mensaje: 'Se revirtió la prejubilación.', error: 'success' });
                        setSubmitting(false);
                    })
                    .catch(function (error) {
                        formik.resetForm();
                        cerrar({ mensaje: error, error: 'error' });
                        setSubmitting(false);
                    });
            }
        },
        onReset: () => { },
    });

    const cerrar01 = (resultado) => {
        formik.resetForm();
        cerrar({ mensaje: 'Acción cancelada por el usuario.', error: 'warning' });
    };

    // Cambiar aca para indicar bajo que condiciones se puede revertir una jubilación.
    const puedeRevertirJubilacion = false;

    return (
        <Modal open={ver} handleClose={handleCloseModalXs} size="sm">
            <form
                id="formAltaObra"
                name="formAltaObra"
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}
                autoComplete="off"
            >
                <Typography variant="titulo" paragraph>
                    {jubilacion_definitiva ? "Revertir jubilación de afiliado" : "Revertir prejubilación de afiliado"}
                </Typography>
                <Grid container>
                    <p>Afiliado: <b>{apellido}, {nombre}</b></p>&nbsp;
                    <p>Fecha jubilación: <b>{fecha_jubilacion}</b></p>
                    <p>Jubilación definitiva: <b>{jubilacion_definitiva === 1 ? 'Si' : 'No'}</b></p>
                </Grid>
                <br />
                <br />
                {(puedeRevertirJubilacion || jubilacion_definitiva === 0)?
                    <Grid container>
                        <FormControl fullWidth>
                            <TextField
                                autoComplete="off"
                                label="Comentario"
                                name="comentario"
                                id="comentario"
                                type="textarea"
                                variant="outlined"
                                value={formik.values.comentario}
                                onChange={formik.handleChange}
                                error={formik.touched.comentario && Boolean(formik.errors.comentario)}
                                helperText={formik.touched.comentario && formik.errors.comentario}
                                multiline
                            />
                        </FormControl>
                    </Grid>
                    :
                    <Alert severity='error'>No se puede revertir una jubilación definitiva.</Alert>
                }

                <ComentarioListado comentariosListado={comentariosListado} />
                <br></br>
                {jubilacion_definitiva === 1 && puedeRevertirJubilacion &&
                    <>
                        <Alert severity='warning'>Esta por revertir una jubilación definitiva.</Alert>
                        <br></br>
                    </>
                }
                <Stack direction="row" spacing={2}>
                    {
                        (puedeRevertirJubilacion || jubilacion_definitiva === 0) &&
                        <Button
                            variant="contained"
                            size="medium"
                            color={jubilacion_definitiva === 1 ? "warning" : "primary"}
                            disabled={submitting}
                            onClick={() => formik.submitForm()}
                            endIcon={<SaveIcon />}
                        >
                            {submitting ? "Guardando..." :
                                jubilacion_definitiva ? "Revertir jubilación" : "Revertir prejubilación"}
                        </Button>
                    }
                    <Button
                        variant="secondary"
                        size="medium"
                        color="secondary"
                        onClick={() => cerrar01(false)}
                        disabled={submitting}
                        endIcon={<CloseIcon />}
                    >Cerrar
                    </Button>
                </Stack>

            </form>
        </Modal>
    )
}