import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { AuthContext } from '../auth/AuthContext'
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { useForm } from '../hooks/useForm';
import DataTables from "datatables.net-dt";
import 'datatables.net-dt/css/dataTables.dataTables.css';
import {
    Table, TableBody,
    TableCell, TableContainer, TableHead,
    TableRow, Button, Grid, Tooltip, TextField,
    Snackbar
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Alert } from '@mui/material'
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';
import { Page } from '../components/ui/Page';
import { useAfiliados } from '../hooks/useAfiliados';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import { BajaAfiliacion } from '../components/afiliado/BajaAfiliacion';
import { RevertirBajaAfiliacion } from '../components/afiliado/RevertirBajaAfiliacion';
import { Jubilacion } from '../components/afiliado/Jubilacion';
import { RevertirJubilacion } from '../components/afiliado/RevertirJubilacion';
import { BajaDefinitiva } from '../components/afiliado/BajaDefinitiva';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        width: '100% !important',
    },
    root: {
        flexGrow: 1,
    },
    cabeceraTabla: {
        backgroundColor: 'darkgrey',
        color: 'white'
    },
    jubilado: {
        backgroundColor: '#EFAFC0 !important',
        '&:hover': {
            backgroundColor: '#DBA1B0 !important',
        },
    },
    deshabilitado: {
        backgroundColor: '#FFCF8F !important',
        '&:hover': {
            backgroundColor: '#F1C589 !important',
        },
    }
});

const TablaAfiliados = React.memo(({
    afiliadosFiltered,
    q,
    a,
    n,
    openModalJubilacion,
    openModalRevertirJubilacion,
    openModalAfiliacion,
    openModalBajaDefinitiva,
    openModalRevertirBajaAfiliacion,
    refrescar
}) => {
    const { user: {
        isSuperAdmin,
        canBajaAfiliado,
        canBajaDefinitiva,
        canEditarAdherentes,
        canEditarAfiliado,
        canEditarEmpleoCompleto,
        canEditarEmpleoParcial,
        canPreJubilar
    } } = useContext(AuthContext);

    const classes = useStyles();

    const getClassName = (afiliado) => {
        if (afiliado.fecha_jubilacion !== '00/00/0000' && afiliado.fecha_jubilacion !== null)
            return classes.jubilado;
        if (afiliado.fecha_baja_afiliacion !== '00/00/0000' && afiliado.fecha_baja_afiliacion !== null)
            return classes.deshabilitado;
        return '';
    }

    const hayFiltros = q !== '' || a !== '' || n !== '';

    const tableRef = useRef();

    useEffect(() => {
        const dt = new DataTables(tableRef.current, {
            order: [[1, 'asc']],
            columnDefs: [{
                targets: [0, 2, 3, 4, 5],
                className: 'dt-body-center dt-head-center'
            }, {
                targets: '_all',
                className: 'dt-head-center'
            }]
        });
        return () => {
            dt.destroy();
        };
    }, [afiliadosFiltered]);

    if (hayFiltros && afiliadosFiltered.loading) {
        return (
            <Grid className="classes.root">
                <Alert severity="info">
                    Buscando...
                </Alert>
            </Grid>
        );
    } else if (hayFiltros && afiliadosFiltered.error) {
        return (
            <Grid className="classes.root">
                <Alert severity="error">
                    No se ha encontrado ningún afiliado con los datos especificados.
                </Alert>
            </Grid>
        );
    } else if (hayFiltros && afiliadosFiltered.data) {
        return (
            <Grid className="classes.root">
                <Grid container>
                    <TableContainer>
                        <Table ref={tableRef} className={`${classes.table} stripe compact`} size="small" aria-label="a dense table">
                            <TableHead className={classes.cabeceraTabla}>
                                <TableRow>
                                    <TableCell align="center">Documento</TableCell>
                                    <TableCell align="center">Nombre</TableCell>
                                    <TableCell align="center">Obra Social</TableCell>
                                    <TableCell align="center">Afiliación</TableCell>
                                    <TableCell align="center">Baja Afiliación</TableCell>
                                    <TableCell align="center">Tarea</TableCell>
                                    {isSuperAdmin && <TableCell align="center" width='1%'>N° Interno</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody key={refrescar}>
                                {afiliadosFiltered.data.map((row) => (
                                    <TableRow hover key={row.id_afiliado} className={getClassName(row)}>
                                        <TableCell align="center">{row.dni}</TableCell>
                                        <TableCell component="th" scope="row">{row.apellido}, {row.nombre}</TableCell>
                                        <TableCell align="center">{row.obra_social}</TableCell>
                                        <TableCell align="center" data-order={row.fecha_afiliacion_iso}>{row.fecha_afiliacion}</TableCell>
                                        <TableCell align="center" data-order={row.fecha_baja_afiliacion_iso}>{row.fecha_baja_afiliacion}</TableCell>
                                        <TableCell align="center">
                                            <Link to={`./informacion/${row.id_afiliado}?q=${q}&a=${a}&n=${n}`}>
                                                <Tooltip title="Información">
                                                    <IconButton
                                                        color="primary"
                                                        size="small"
                                                        type="submit"
                                                        variant="outlined"
                                                    >
                                                        <InfoIcon />
                                                    </IconButton >
                                                </Tooltip>
                                            </Link>
                                            {canEditarAdherentes &&
                                                <Link to={`./adherenteEdicion/${row.id_afiliado}?q=${q}&a=${a}&n=${n}`}>
                                                    <Tooltip title="Ver/Editar Adherentes">
                                                        <IconButton
                                                            color="primary"
                                                            size="small"
                                                            type="submit"
                                                            variant="outlined"
                                                        >
                                                            <GroupAddIcon />
                                                        </IconButton >
                                                    </Tooltip>
                                                </Link>
                                            }
                                            {canEditarEmpleoCompleto &&
                                                <Link to={`./empleoEdicionCompleto/${row.id_afiliado}?q=${q}&a=${a}&n=${n}`}>
                                                    <Tooltip title="Edición de datos de empleo">
                                                        <IconButton
                                                            color="primary"
                                                            size="small"
                                                            type="submit"
                                                            variant="outlined"
                                                        >
                                                            <AssuredWorkloadIcon />
                                                        </IconButton >
                                                    </Tooltip>
                                                </Link>
                                            }
                                            {canEditarEmpleoParcial &&
                                                <Link to={`./empleoEdicion/${row.id_afiliado}?q=${q}&a=${a}&n=${n}`}>
                                                    <Tooltip title="Edición de datos de empleo">
                                                        <IconButton
                                                            color="primary"
                                                            size="small"
                                                            type="submit"
                                                            variant="outlined"
                                                        >
                                                            <AssuredWorkloadIcon />
                                                        </IconButton >
                                                    </Tooltip>
                                                </Link>
                                            }
                                            {canEditarAfiliado &&
                                                <Link to={`./afiliadoEdicion/${row.id_afiliado}?q=${q}&a=${a}&n=${n}`}>
                                                    <Tooltip title="Edición de afiliado">
                                                        <IconButton
                                                            color="primary"
                                                            size="small"
                                                            type="submit"
                                                            variant="outlined"
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Link>
                                            }
                                            {canPreJubilar &&
                                                <>
                                                    {(row.fecha_jubilacion !== null && row.fecha_jubilacion !== '00/00/0000') ?
                                                        <Tooltip title="Revertir Jubilación">
                                                            <IconButton
                                                                color="warning"
                                                                size="small"
                                                                type="submit"
                                                                variant="outlined"
                                                                onClick={e => openModalRevertirJubilacion(row)}
                                                            >
                                                                <WorkOffIcon />
                                                            </IconButton >
                                                        </Tooltip>
                                                        :
                                                        <Tooltip title="Jubilación">
                                                            <IconButton
                                                                color="warning"
                                                                size="small"
                                                                type="submit"
                                                                variant="outlined"
                                                                onClick={e => openModalJubilacion(row)}
                                                            >
                                                                <WorkOffIcon />
                                                            </IconButton >
                                                        </Tooltip>
                                                    }
                                                </>
                                            }
                                            {canBajaAfiliado &&
                                                <>
                                                    {(row.fecha_baja_afiliacion !== null && row.fecha_baja_afiliacion !== '00/00/0000') ?
                                                        <Tooltip title="Revertir desafiliación">
                                                            <IconButton
                                                                color="warning"
                                                                size="small"
                                                                type="submit"
                                                                variant="outlined"
                                                                onClick={e => openModalRevertirBajaAfiliacion(row)}
                                                            >
                                                                <PersonAddDisabledIcon />
                                                            </IconButton >
                                                        </Tooltip>
                                                        :
                                                        <Tooltip title="Desafiliar">
                                                            <IconButton
                                                                color="warning"
                                                                size="small"
                                                                type="submit"
                                                                variant="outlined"
                                                                onClick={e => openModalAfiliacion(row)}
                                                            >
                                                                <PersonAddDisabledIcon />
                                                            </IconButton >
                                                        </Tooltip>
                                                    }
                                                </>
                                            }
                                            {canBajaDefinitiva &&
                                                <Tooltip title="Eliminar">
                                                    <IconButton
                                                        color="error"
                                                        size="small"
                                                        type="submit"
                                                        variant="outlined"
                                                        onClick={e => openModalBajaDefinitiva(row)}
                                                    >
                                                        <PersonAddDisabledIcon />
                                                    </IconButton >
                                                </Tooltip>
                                            }
                                        </TableCell>
                                        {isSuperAdmin && <TableCell align="right">{row.id_persona}/{row.id_afiliado}</TableCell>}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid className="classes.root">
                <Alert severity="info">
                    Para buscar un Afiliado debe de introducir un DNI, nombre y/o apellido.
                </Alert>
            </Grid>
        );
    }
});

export const SearchScreen = ({ history }) => {
    const { search } = useLocation();
    const { q = '', a = '', n = '' } = queryString.parse(search);

    const [formValues, handleInputChange] = useForm({
        searchText: q,
        searchTextA: a,
        searchTextN: n
    });

    const { searchText, searchTextA, searchTextN } = formValues;
    const [refrescar, setRefrescar] = useState(0);
    var afiliadosFiltered = useAfiliados(q, a, n, refrescar);
    const handleSearch = (e) => {
        e.preventDefault();
        setRefrescar(refrescar + 1);
        history.push(`?q=${searchText}&a=${searchTextA}&n=${searchTextN}`)
    };

    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Afiliado Listado", ruta: "/search" },
    ];

    const [abrirModalJubilacion, setAbrirModalJubilacion] = useState(false);
    const [abrirModalRevertirJubilacion, setAbrirModalRevertirJubilacion] = useState(false);
    const [abrirModalAfiliacion, setAbrirModalAfiliacion] = useState(false);
    const [abrirModalRevertirBajaAfiliacion, setAbrirModalRevertirBajaAfiliacion] = useState(false);
    const [abrirModalBajaDefinitiva, setAbrirModalBajaDefinitiva] = useState(false);
    const [afiliado, setAfiliado] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState('Dato almacenado exitosamente');
    const [tipoMensaje, setTipoMensaje] = useState('success');
    const [open, setOpen] = useState(false);

    const openModalJubilacion = useCallback((selectedAfiliado) => {
        setAfiliado(selectedAfiliado);
        setAbrirModalJubilacion(true);
    }, []);
    const closeModalCerrarJubilacion = (resultado) => {
        setMensajeAlert(resultado.mensaje);
        setTipoMensaje(resultado.error);
        setAbrirModalJubilacion(false);
        setOpen(true);
        if (resultado.error !== 'warning')
            setRefrescar(refrescar + 1);
    };

    const openModalRevertirJubilacion = useCallback((selectedAfiliado) => {
        setAfiliado(selectedAfiliado);
        setAbrirModalRevertirJubilacion(true);
    }, []);
    const closeModalCerrarRevertirJubilacion = (resultado) => {
        setMensajeAlert(resultado.mensaje);
        setTipoMensaje(resultado.error);
        setAbrirModalRevertirJubilacion(false);
        setOpen(true);
        if (resultado.error !== 'warning')
            setRefrescar(refrescar + 1);
    };

    const openModalAfiliacion = useCallback((selectedAfiliado) => {
        setAfiliado(selectedAfiliado);
        setAbrirModalAfiliacion(true);
    }, []);
    const closeModalCerrarAfiliacion = (resultado) => {
        setMensajeAlert(resultado.mensaje);
        setTipoMensaje(resultado.error);
        setAbrirModalAfiliacion(false);
        setOpen(true);
        if (resultado.error !== 'warning')
            setRefrescar(refrescar + 1);
    };

    const openModalRevertirBajaAfiliacion = useCallback((selectedAfiliado) => {
        setAfiliado(selectedAfiliado);
        setAbrirModalRevertirBajaAfiliacion(true);
    }, []);
    const closeModalCerrarRevertirBajaAfiliacion = (resultado) => {
        setMensajeAlert(resultado.mensaje);
        setTipoMensaje(resultado.error);
        setAbrirModalRevertirBajaAfiliacion(false);
        setOpen(true);
        if (resultado.error !== 'warning')
            setRefrescar(refrescar + 1);
    };

    const openModalBajaDefinitiva = useCallback((selectedAfiliado) => {
        setAfiliado(selectedAfiliado);
        setAbrirModalBajaDefinitiva(true);
    }, []);
    const closeModalCerrarBajaDefinitiva = (resultado) => {
        setMensajeAlert(resultado.mensaje);
        setTipoMensaje(resultado.error);
        setAbrirModalBajaDefinitiva(false);
        setOpen(true);
        if (resultado.error !== 'warning')
            setRefrescar(refrescar + 1);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Page title="Listado de Afiliados" breadcrumbs={breadcrumbs}>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}>
                <Alert onClose={handleClose} severity={tipoMensaje} sx={{
                    width: '100%'
                }}>
                    {mensajeAlert}
                </Alert>
            </Snackbar>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <form onSubmit={handleSearch}>
                        <TextField
                            autoComplete="off"
                            fullWidth
                            name="searchText"
                            label="Documento"
                            placeholder="Ingresar texto a buscar"
                            size="small"
                            type="text"
                            value={searchText}
                            onChange={handleInputChange}
                        />
                    </form>
                </Grid>
            </Grid>
            <br />

            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <form onSubmit={handleSearch}>
                        <TextField
                            autoComplete="off"
                            fullWidth
                            name="searchTextA"
                            label="Apellido"
                            placeholder="Ingresar texto a buscar"
                            size="small"
                            type="text"
                            inputProps={{ style: { textTransform: "uppercase" } }}
                            value={searchTextA}
                            onChange={handleInputChange}
                        />
                    </form>
                </Grid>

                <Grid item xs={6}>
                    <form onSubmit={handleSearch}>
                        <TextField
                            autoComplete="off"
                            fullWidth
                            name="searchTextN"
                            label="Nombre"
                            placeholder="Ingresar texto a buscar"
                            size="small"
                            type="text"
                            inputProps={{ style: { textTransform: "uppercase" } }}
                            value={searchTextN}
                            onChange={handleInputChange}
                        />
                    </form>
                </Grid>

                <Grid item xs={2} justifyContent="flex-end">
                    <Button
                        color="primary"
                        size="medium"
                        fullWidth
                        type="submit"
                        variant="contained"
                        onClick={handleSearch}
                        endIcon={<SearchIcon />}
                    >
                        {afiliadosFiltered.loading ? "Buscando..." : "Buscar"}
                    </Button>
                </Grid>
            </Grid>
            <br />

            <TablaAfiliados
                afiliadosFiltered={afiliadosFiltered}
                q={q} a={a} n={n}
                openModalJubilacion={openModalJubilacion}
                openModalRevertirJubilacion={openModalRevertirJubilacion}
                openModalAfiliacion={openModalAfiliacion}
                openModalBajaDefinitiva={openModalBajaDefinitiva}
                openModalRevertirBajaAfiliacion={openModalRevertirBajaAfiliacion}
                refrescar={refrescar} />

            <Jubilacion ver={abrirModalJubilacion} afiliado={afiliado} cerrar={closeModalCerrarJubilacion} permitirJubilacionDefinitiva={false} />
            <RevertirJubilacion ver={abrirModalRevertirJubilacion} afiliado={afiliado} cerrar={closeModalCerrarRevertirJubilacion} />
            <BajaAfiliacion ver={abrirModalAfiliacion} afiliado={afiliado} cerrar={closeModalCerrarAfiliacion} />
            <RevertirBajaAfiliacion ver={abrirModalRevertirBajaAfiliacion} afiliado={afiliado} cerrar={closeModalCerrarRevertirBajaAfiliacion} />
            <BajaDefinitiva ver={abrirModalBajaDefinitiva} afiliado={afiliado} cerrar={closeModalCerrarBajaDefinitiva} />
        </Page >
    )
}
