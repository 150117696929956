import React, { useState } from 'react'
import {
    Button,
    FormControl,
    InputLabel,
    Grid,
    TextField,
    Stack,
    AlertTitle
} from '@mui/material';
import { Snackbar } from '@mui/material'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useFetch } from '../../hooks/useFetch';
import { Page } from './../ui/Page';
import MuiAlert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import api from "../../service/api";
import { useHistory } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import Utils from '../../commons/Utils';

const useStyles = makeStyles({
    error: {
        backgroundColor: '#EFAFC0',
        '&:hover': {
            backgroundColor: '#DBA1B0 !important',
        },
    },
});

export const AfiliadoScreen = () => {
    const classes = useStyles();
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [open, setOpen] = React.useState({ open: false, status: 0 });
    const [afiliadosEncontrados, setAfiliadosEncontrados] = React.useState({ length: 0, data: null });
    const [cuilVerificado, setCuilVerificado] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const verificarExisteCuil = () => {
        if ((formik.values.prefijo && (formik.values.verificador || formik.values.verificador === 0) && formik.values.dni)
            || (!formik.values.prefijo && !formik.values.verificador && formik.values.dni)) {
            setVerificandoCuil(true);
            setCuilVerificado(false);
            const args = {
                'prefijo': formik.values.prefijo,
                'dni': formik.values.dni,
                'verificador': formik.values.verificador
            };
            api.buscarAfiliadoCuil(args)
                .then(resultado => {
                    if (resultado.data && resultado.data.length === 1) {
                        setAfiliadosEncontrados({
                            length: resultado.data.length,
                            data: resultado.data
                        });
                        setCuilVerificado(true);
                    } else if (resultado.data && resultado.data.length > 1) {
                        setAfiliadosEncontrados({
                            length: resultado.data.length,
                            data: resultado.data
                        });
                        setCuilVerificado(true);
                    } else {
                        setAfiliadosEncontrados({ length: 0 });
                        setCuilVerificado(true);
                    }
                    setVerificandoCuil(false);
                })
                .catch((error) => {
                    setAfiliadosEncontrados({ length: 0 });
                    setVerificandoCuil(false);
                    setCuilVerificado(false);
                    setMensajeAlert('Error al intentar verificar el DNI/CUIL');
                    setTipoMensaje('error');
                    setOpen({ open: true });
                });
        }
    }

    const history = useHistory();

    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Afiliado", ruta: "/afiliado" },
    ];

    const [submitting, setSubmitting] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState("");
    const [tipoMensaje, setTipoMensaje] = useState("");
    const [verificandoCuil, setVerificandoCuil] = useState(false);

    const aNumero = () => {
        const num = formik.values.telefonoActual + '';
        const cel = formik.values.celularActual + '';
        const result = typeof num === 'string' ? num.replace(/\D+/g, '') : ''
        const result1 = typeof num === 'string' ? cel.replace(/\D+/g, '') : ''
        formik.setFieldValue('telefonoActual', result)
        formik.setFieldValue('celularActual', result1)
    };

    var validationSchema = Yup.object().shape({
    });

    const formik = useFormik({
        initialValues: {
            apellido: '',
            nombre: '',
            prefijo: '',
            dni: '',
            verificador: '',
            nacionalidad: 'ARGENTINA',
            lugarDeNacimiento: '',
            departamentoDeNacimiento: '',
            provinciaDeNacimiento: '',
            fechaDeNacimiento: '',
            grupoSanguineo: '',
            domicilioActual: '',
            localidadActual: '',
            provinciaActual: '',
            departamentoActual: '',
            caracteristica_t: '',
            telefonoActual: '',
            caracteristica_c: '',
            celularActual: '',
            caracteristica_c1: '',
            celularActual1: '',
            email: '',
            estCivil: '',
            genero: '',
            chekeado: false,
            id_persona: '',
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitting(true);
            api.crearPersona(values)
                .then(respuesta => {
                    setMensajeAlert('¡El afiliado se almacenó exitosamente!');
                    setTipoMensaje('success');
                    setOpen({ open: true, status: respuesta.status });
                    history.replace('/empleo/' + respuesta.data.id_afiliado);
                })
                .catch(function (error) {
                    console.log('El error es =>', error)
                    setMensajeAlert('Error al guardar los datos del afiliado');
                    setTipoMensaje('error');
                    setSubmitting(false);
                    setOpen({ open: true, status: 422 });
                });
        },
        onReset: () => { },
    });

    const editarClick = () => {
        if (afiliadosEncontrados.length === 1) {
            history.replace('/afiliadoEdicion/'.concat(
                afiliadosEncontrados.data[0].id_afiliado,
                '?q=',
                afiliadosEncontrados.data[0].dni));
        }
    };

    const buscarAfiliadosClick = () => {
        if (afiliadosEncontrados.length > 1) {
            history.replace('/search?q='.concat(afiliadosEncontrados.data[0].dni));
        }
    };

    const cancelar = () => {
        formik.handleReset();
        history.replace('/inicio');
    };

    const listadoEstadoCivil = useFetch('getEstadosCiviles');
    const listadoGruposSanguineos = useFetch('getTodosLosGruposSanguineos');

    const listadoProvincias = useFetch('provincias');

    const [listadoDepartamentos, setListadoDepartamentos] = useState({ loading: false, data: [], error: null });
    const [listadoLocalidades, setListadoLocalidades] = useState({ loading: false, data: [], error: null });

    const [listadoDepartamentosActual, setListadoDepartamentosActual] = useState({ loading: false, data: [], error: null });
    const [listadoLocalidadesActual, setListadoLocalidadesActual] = useState({ loading: false, data: [], error: null });

    const provinciaDeNacimientoChanged = (e) => {
        formik.handleChange(e);
        formik.setFieldValue('departamentoDeNacimiento', '');
        formik.setFieldValue('lugarDeNacimiento', '');
        setListadoDepartamentos({ loading: true });
        api.getDepartamentos(e.target.value)
            .then((resp) => {
                setListadoDepartamentos({
                    loading: false,
                    data: resp.data,
                    error: null
                });
                setListadoLocalidades({ loading: false, data: null, error: null });
            })
            .catch((error) => {
                setListadoDepartamentos({
                    loading: false,
                    data: null,
                    error: true
                });
                setListadoLocalidades({ loading: false, data: null, error: null });
            });
    };

    const provinciaActualChanged = (e) => {
        formik.handleChange(e);
        formik.setFieldValue('departamentoActual', '');
        formik.setFieldValue('localidadActual', '');
        setListadoDepartamentosActual({ loading: true });
        api.getDepartamentos(e.target.value)
            .then((resp) => {
                setListadoDepartamentosActual({
                    loading: false,
                    data: resp.data,
                    error: null
                });
                setListadoLocalidadesActual({ loading: false, data: null, error: null });
            })
            .catch((error) => {
                setListadoDepartamentosActual({
                    loading: false,
                    data: null,
                    error: true
                });
                setListadoLocalidadesActual({ loading: false, data: null, error: null });
            });
    };

    const departamentoDeNacimientoChanged = (e) => {
        formik.handleChange(e);
        formik.setFieldValue('lugarDeNacimiento', '');
        setListadoLocalidades({ loading: true });
        api.getLocalidades(e.target.value)
            .then((resp) => {
                setListadoLocalidades({
                    loading: false,
                    data: resp.data,
                    error: null
                });
            })
            .catch((error) => {
                setListadoLocalidades({
                    loading: false,
                    data: null,
                    error: true
                });
            });
    };

    const departamentoActualChanged = (e) => {
        formik.handleChange(e);
        formik.setFieldValue('localidadActual', '');
        setListadoLocalidadesActual({ loading: true });
        api.getLocalidades(e.target.value)
            .then((resp) => {
                setListadoLocalidadesActual({
                    loading: false,
                    data: resp.data,
                    error: null
                });
            })
            .catch((error) => {
                setListadoLocalidadesActual({
                    loading: false,
                    data: null,
                    error: true
                });
            });
    };

    const cuilHandleChanged = (e) => {
        formik.handleChange(e);
        setCuilVerificado(false);
    };

    const keyDownHandler = (e) => {
        if (e.key === 'Enter') {
            verificarExisteCuil();
        }
    };

    return (
        <Page title="Nuevo Afiliado" breadcrumbs={breadcrumbs}>
            <Snackbar open={open.open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}>
                <Alert onClose={handleClose} severity={tipoMensaje} sx={{
                    width: '100%'
                }}>
                    {mensajeAlert}
                </Alert>
            </Snackbar>

            <form
                id="formAfiliado"
                name="formAfiliado"
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}
                autoComplete="off"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <FormControl sx={{ width: '20%' }}>
                            <TextField
                                autoComplete="off"
                                id="prefijo"
                                name="prefijo"
                                value={formik.values.prefijo}
                                type="text"
                                inputProps={{ maxLength: 2 }}
                                onChange={cuilHandleChanged}
                                error={formik.touched.prefijo && Boolean(formik.errors.prefijo)}
                                helperText={formik.touched.prefijo && formik.errors.prefijo}
                                onKeyDown={keyDownHandler}
                            />
                        </FormControl>

                        <FormControl sx={{ width: '60%' }}>
                            <TextField
                                autoComplete="off"
                                id="dni"
                                label="CUIL"
                                name="dni"
                                value={formik.values.dni}
                                type="number"
                                inputProps={{ maxLength: 8, min: 0 }}
                                onChange={cuilHandleChanged}
                                error={formik.touched.dni && Boolean(formik.errors.dni)}
                                helperText={formik.touched.dni && formik.errors.dni}
                                onKeyDown={keyDownHandler}
                            />
                        </FormControl>

                        <FormControl sx={{ width: '20%' }}>
                            <TextField
                                autoComplete="off"
                                id="verificador"
                                name="verificador"
                                value={formik.values.verificador}
                                type="number"
                                InputProps={{ maxLength: 1 }}
                                onChange={cuilHandleChanged}
                                error={formik.touched.verificador && Boolean(formik.errors.verificador)}
                                helperText={formik.touched.verificador && formik.errors.verificador}
                                onKeyDown={keyDownHandler}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={verificarExisteCuil}
                            disabled={verificandoCuil || submitting}
                        >
                            {verificandoCuil ? "Verificando..." : "Verificar existencia"}
                        </Button>
                    </Grid>
                </Grid>

                {cuilVerificado && afiliadosEncontrados.length === 1 &&
                    <>
                        <br />
                        <Alert severity="warning">
                            <AlertTitle>Cuidado</AlertTitle>
                            Ya existe un afiliado con DNI <strong>{afiliadosEncontrados.data[0].dni}</strong>. Nombre: <strong>{afiliadosEncontrados.data[0].apellido}, {afiliadosEncontrados.data[0].nombre}</strong>.
                            <br />
                            {afiliadosEncontrados.data[0].fecha_baja_afiliacion && <p>Fue dado de baja el <b>{afiliadosEncontrados.data[0].fecha_baja_afiliacion_string}</b>.</p>}
                            {afiliadosEncontrados.data[0].fecha_jubilacion && <p>Tiene fecha de jubilación el <b>{afiliadosEncontrados.data[0].fecha_jubilacion_string}</b>.</p>}
                            <br />
                            Puede editar la información o ingresar un nuevo documento/cuil.
                        </Alert>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    endIcon={<EditIcon />}
                                    disabled={submitting}
                                    onClick={editarClick}
                                >
                                    Editar
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
                {cuilVerificado && afiliadosEncontrados.length > 1 &&
                    <>
                        <br />
                        <Alert severity="warning">
                            <AlertTitle>Cuidado</AlertTitle>
                            Se encontraron {afiliadosEncontrados.length} afiliados con el DNI {afiliadosEncontrados.data[0].dni}.
                            <br />
                            Puede listar los afiliados encontrados o ingresar un nuevo documento/cuil.
                        </Alert>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    endIcon={<SearchIcon />}
                                    onClick={buscarAfiliadosClick}
                                >
                                    Listar afiliados
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
                {cuilVerificado && afiliadosEncontrados.length === 0 &&
                    <>
                        <br />
                        <Alert severity="success">
                            No se encontró otro afiliado con el mismo DNI.
                        </Alert>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} >
                                <FormControl fullWidth>
                                    <TextField
                                        variant="outlined"
                                        autoComplete="off"
                                        name="apellido"
                                        placeholder="Apellido"
                                        label="Apellido"
                                        type="text"
                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                        value={formik.values.apellido}
                                        onChange={(e) => Utils.toUpperCaseHandler(e, formik)}
                                        error={formik.touched.apellido && Boolean(formik.errors.apellido)}
                                        helperText={formik.touched.apellido && formik.errors.apellido}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6} >
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        name="nombre"
                                        placeholder="Nombre"
                                        label="Nombre"
                                        type="text"
                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                        value={formik.values.nombre}
                                        onChange={(e) => Utils.toUpperCaseHandler(e, formik)}
                                        error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                                        helperText={formik.touched.nombre && formik.errors.nombre}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        id="nacionalidad"
                                        label="Nacionalidad"
                                        name="nacionalidad"
                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                        value={formik.values.nacionalidad}
                                        onChange={(e) => Utils.toUpperCaseHandler(e, formik)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant='outlined'>
                                    <InputLabel htmlFor="provinciaDeNacimiento">Provincia De Nacimiento</InputLabel>
                                    <Select
                                        autoComplete="off"
                                        id="provinciaDeNacimiento"
                                        label="Provincia de Nacimiento"
                                        name="provinciaDeNacimiento"
                                        value={formik.values.provinciaDeNacimiento}
                                        onChange={provinciaDeNacimientoChanged}
                                    >
                                        <MenuItem value="">
                                            <em>Dejar sin dato</em>
                                        </MenuItem>
                                        {listadoProvincias.loading ?
                                            <MenuItem>Cargando...</MenuItem>
                                            : listadoProvincias.error ?
                                                <MenuItem className={classes.error} disabled="true">No se pudo cargar los datos.</MenuItem>
                                                : listadoProvincias.data && listadoProvincias.data.map((prov) => <MenuItem value={prov.id} key={prov.id}>{prov.descripcion}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant='outlined'>
                                    <InputLabel htmlFor="departamentoDeNacimiento">Departamento De Nacimiento</InputLabel>
                                    <Select
                                        autoComplete="off"
                                        id="departamentoDeNacimiento"
                                        label="Departamento de Nacimiento"
                                        name="departamentoDeNacimiento"
                                        value={formik.values.departamentoDeNacimiento}
                                        onChange={departamentoDeNacimientoChanged}
                                    >
                                        <MenuItem value="">
                                            <em>Dejar sin dato</em>
                                        </MenuItem>
                                        {listadoDepartamentos.loading ?
                                            <MenuItem>Cargando...</MenuItem>
                                            : !formik.values.provinciaDeNacimiento ?
                                                <MenuItem><em>Debe seleccionar una provincia primero</em></MenuItem>
                                                : listadoDepartamentos.error ?
                                                    <MenuItem className={classes.error} disabled="true">No se pudo cargar listado.</MenuItem>
                                                    : listadoDepartamentos.data && listadoDepartamentos.data.map((dto) => <MenuItem value={dto.id} key={dto.id}>{dto.descripcion}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="lugarDeNacimiento">Lugar De Nacimiento</InputLabel>
                                    <Select
                                        autoComplete="off"
                                        id="lugarDeNacimiento"
                                        label="Lugar de Nacimiento"
                                        name="lugarDeNacimiento"
                                        value={formik.values.lugarDeNacimiento}
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value="">
                                            <em>Dejar sin dato</em>
                                        </MenuItem>
                                        {listadoLocalidades.loading ?
                                            <MenuItem>Cargando...</MenuItem>
                                            : !formik.values.departamentoDeNacimiento ?
                                                <MenuItem><em>Debe seleccionar un departamento primero</em></MenuItem>
                                                : listadoLocalidades.error ?
                                                    <MenuItem className={classes.error} disabled="true">No se pudo cargar listado.</MenuItem>
                                                    : listadoLocalidades.data && listadoLocalidades.data.map((loca) => <MenuItem value={loca.id} key={loca.id}>{loca.descripcion}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br />

                        <Grid container spacing={2}>
                            <Grid item xs={6} md={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        id="fechaDeNacimiento"
                                        label="Fecha de Nacimiento"
                                        name="fechaDeNacimiento"
                                        value={formik.values.fechaDeNacimiento}
                                        onChange={formik.handleChange}
                                        error={formik.touched.fechaDeNacimiento && Boolean(formik.errors.fechaDeNacimiento)}
                                        helperText={formik.touched.fechaDeNacimiento && formik.errors.fechaDeNacimiento}
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="grupoSanguineo">Grupo Sanguineo</InputLabel>
                                    <Select
                                        autoComplete="off"
                                        id="grupoSanguineo"
                                        label="Grupo sanguineo"
                                        name="grupoSanguineo"
                                        value={formik.values.grupoSanguineo}
                                        onChange={formik.handleChange}
                                        type="date"
                                    >
                                        <MenuItem value="">
                                            <em>Dejar sin dato</em>
                                        </MenuItem>
                                        {listadoGruposSanguineos.loading ?
                                            <MenuItem>Cargando...</MenuItem>
                                            : listadoGruposSanguineos.error ?
                                                <MenuItem className={classes.error} disabled="true">No se pudo cargar listado.</MenuItem>
                                                : listadoGruposSanguineos.data && listadoGruposSanguineos.data.map((gs) => <MenuItem value={gs.id} key={gs.id}>{gs.descripcion}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="genero">Genero</InputLabel>
                                    <Select
                                        autoComplete="off"
                                        id="genero"
                                        label="Genero"
                                        name="genero"
                                        value={formik.values.genero}
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value="">
                                            <em>Dejar sin dato</em>
                                        </MenuItem>
                                        <MenuItem value='2' key={2}>Femenino</MenuItem>
                                        <MenuItem value='1' key={1}>Masculino</MenuItem>
                                        <MenuItem value='3' key={1}>X</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br />

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        id="domicilioActual"
                                        label="Domicilio Actual"
                                        name="domicilioActual"
                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                        value={formik.values.domicilioActual}
                                        onChange={(e) => Utils.toUpperCaseHandler(e, formik)}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br />

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="provinciaActual">Provincia</InputLabel>
                                    <Select
                                        autoComplete="off"
                                        id="provinciaActual"
                                        label="Provincia"
                                        name="provinciaActual"
                                        value={formik.values.provinciaActual}
                                        onChange={provinciaActualChanged}
                                    >
                                        <MenuItem value="">
                                            <em>Dejar sin dato</em>
                                        </MenuItem>
                                        {listadoProvincias.loading ?
                                            <MenuItem>Cargando...</MenuItem>
                                            : listadoProvincias.error ?
                                                <MenuItem className={classes.error} disabled="true">No se pudo cargar listado.</MenuItem>
                                                : listadoProvincias.data && listadoProvincias.data.map((provActual) => <MenuItem value={provActual.id} key={provActual.id}>{provActual.descripcion}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="departamentoActual">Departamento</InputLabel>
                                    <Select
                                        autoComplete="off"
                                        id="departamentoActual"
                                        label="Departamento"
                                        name="departamentoActual"
                                        value={formik.values.departamentoActual}
                                        onChange={departamentoActualChanged}
                                    >
                                        <MenuItem value="">
                                            <em>Dejar sin dato</em>
                                        </MenuItem>
                                        {listadoDepartamentosActual.loading ?
                                            <MenuItem>Cargando...</MenuItem>
                                            : !formik.values.provinciaActual ?
                                                <MenuItem><em>Debe seleccionar una provincia primero</em></MenuItem>
                                                : listadoDepartamentosActual.error ?
                                                    <MenuItem className={classes.error} disabled="true">No se pudo cargar listado.</MenuItem>
                                                    : listadoDepartamentosActual.data && listadoDepartamentosActual.data.map((dtoActual) => <MenuItem value={dtoActual.id} key={dtoActual.id}>{dtoActual.descripcion}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="localidadActual">Localidad</InputLabel>
                                    <Select
                                        autoComplete="off"
                                        id="localidadActual"
                                        label="Localidad"
                                        name="localidadActual"
                                        value={formik.values.localidadActual}
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value="">
                                            <em>Dejar sin dato</em>
                                        </MenuItem>
                                        {listadoLocalidadesActual.loading ?
                                            <MenuItem>Cargando...</MenuItem>
                                            : !formik.values.departamentoActual ?
                                                <MenuItem><em>Deber seleccionar un departamento primero</em></MenuItem>
                                                : listadoLocalidadesActual.error ?
                                                    <MenuItem className={classes.error} disabled="true">No se pudo cargar listado.</MenuItem>
                                                    : listadoLocalidadesActual.data && listadoLocalidadesActual.data.map((locaActual) => <MenuItem value={locaActual.id} key={locaActual.id}>{locaActual.descripcion}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br />

                        <Grid container spacing={2}>
                            <Grid item xs={2} md={1}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        id="caracteristica_t"
                                        label="Característica"
                                        name="caracteristica_t"
                                        value={formik.values.caracteristica_t}
                                        onChange={formik.handleChange}
                                        onBlur={aNumero}
                                        error={formik.touched.caracteristica_t && Boolean(formik.errors.caracteristica_t)}
                                        helperText={formik.touched.caracteristica_t && formik.errors.caracteristica_t}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        id="telefonoActual"
                                        label="Teléfono"
                                        name="telefonoActual"
                                        value={formik.values.telefonoActual}
                                        onChange={formik.handleChange}
                                        onBlur={aNumero}
                                        error={formik.touched.telefonoActual && Boolean(formik.errors.telefonoActual)}
                                        helperText={formik.touched.telefonoActual && formik.errors.telefonoActual}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={2} md={1}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        id="caracteristica_c"
                                        label="Característica"
                                        name="caracteristica_c"
                                        value={formik.values.caracteristica_c}
                                        onChange={formik.handleChange}
                                        onBlur={aNumero}
                                        error={formik.touched.caracteristica_c && Boolean(formik.errors.caracteristica_c)}
                                        helperText={formik.touched.caracteristica_c && formik.errors.caracteristica_c}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        id="celularActual"
                                        label="Celular"
                                        name="celularActual"
                                        min="0"
                                        value={formik.values.celularActual}
                                        onBlur={aNumero}
                                        onChange={formik.handleChange}
                                        error={formik.touched.celularActual && Boolean(formik.errors.celularActual)}
                                        helperText={formik.touched.celularActual && formik.errors.celularActual}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={2} md={1}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        id="caracteristica_c1"
                                        label="Característica"
                                        name="caracteristica_c1"
                                        value={formik.values.caracteristica_c1}
                                        onChange={formik.handleChange}
                                        onBlur={aNumero}
                                        error={formik.touched.caracteristica_c1 && Boolean(formik.errors.caracteristica_c1)}
                                        helperText={formik.touched.caracteristica_c1 && formik.errors.caracteristica_c1}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        id="celularActual1"
                                        label="Celular"
                                        name="celularActual1"
                                        min="0"
                                        value={formik.values.celularActual1}
                                        onBlur={aNumero}
                                        onChange={formik.handleChange}
                                        error={formik.touched.celularActual1 && Boolean(formik.errors.celularActual1)}
                                        helperText={formik.touched.celularActual1 && formik.errors.celularActual1}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br />

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        id="email"
                                        label="Email"
                                        name="email"
                                        inputProps={{ style: { textTransform: "lowercase" } }}
                                        value={formik.values.email}
                                        onChange={(e) => Utils.toLowerCaseHandler(e, formik)}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="estCivil">Estado Civil</InputLabel>
                                    <Select
                                        autoComplete="off"
                                        id="estCivil"
                                        label="Estado Civil"
                                        name="estCivil"
                                        value={formik.values.estCivil}
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem value="">
                                            <em>Dejar sin dato</em>
                                        </MenuItem>
                                        {listadoEstadoCivil.loading ?
                                            <MenuItem>Cargando...</MenuItem>
                                            : listadoEstadoCivil.error ?
                                                <MenuItem className={classes.error} disabled="true">No se pudo cargar listado.</MenuItem>
                                                : listadoEstadoCivil.data && listadoEstadoCivil.data.map((EstCivil) => <MenuItem value={EstCivil.id} key={EstCivil.id}>{EstCivil.descripcion}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="primary"
                                        onClick={e => formik.submitForm(e)}
                                        disabled={submitting}
                                        endIcon={<SaveIcon />}
                                    >
                                        {submitting ? "Guardando..." : "Guardar y seguir"}
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        size="medium"
                                        color="secondary"
                                        endIcon={<CloseIcon />}
                                        disabled={submitting}
                                        onClick={cancelar}
                                    > Cancelar
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </>
                }
            </form>
        </Page >
    )
}
