import React from 'react';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import logo_1 from '../../assets/logo.png';

export const ReportesListadosAdherenteCompletoPdf = (props) => {

    const { apellido, celular, celular1, cuil, domicilio, edad, email,
        estado_civil, fecha_nacimiento, grupo_sanguineo, localidad,
        localidad_nac, nacionalidad, nombre, telefono, prov, pnac,
        caracteristica_c, caracteristica_c1, caracteristica_t,
        empresa, faf, fecha_afiliacion,
        fecha_ingreso, fondo_compensador,
        funcion, obra_social, plan_especial, res2689, sistema_nacional,
        sistema_provicional, os_pre, os_post, os_numero, os_extra, departamento } = props.afiliado
    var today = new Date(),
        dateNow = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

    const handleClick = async () => {
        const documento = new jsPDF('p', 'pt', 'a4')
        const datamostrar = props.datos.map(e => {
            return Object.values(e);
        });

        documento.setFontSize(11);
        documento.setFont(undefined, 'bold')
        documento.text('DNI/CUIL:', 30, 150)
        documento.setFont(undefined, 'normal')
        documento.text(cuil === null || cuil === undefined ? "" : cuil, 95, 150)

        documento.setFont(undefined, 'bold')
        documento.text('Nombre:', 30, 162)
        documento.setFont(undefined, 'normal')
        documento.text(nombre + ', ' + apellido, 85, 162)

        documento.setFont(undefined, 'bold')
        documento.text('Fecha naciemiento:', 30, 174)
        documento.setFont(undefined, 'normal')
        documento.text(fecha_nacimiento + ' - ' + edad, 140, 174)

        documento.setFontSize(11);
        documento.setFont(undefined, 'bold')
        documento.text('Grupo sanguíneo:', 30, 186)
        documento.setFont(undefined, 'normal')
        documento.text((grupo_sanguineo === null || grupo_sanguineo === undefined ? '' : grupo_sanguineo), 130, 186)

        documento.setFont(undefined, 'bold')
        documento.text('Nacionalidad:', 30, 198)
        documento.setFont(undefined, 'normal')
        documento.text(nacionalidad, 110, 198)

        documento.setFont(undefined, 'bold')
        documento.text('Lugar de nacimiento:', 30, 210)
        documento.setFont(undefined, 'normal')
        documento.text(pnac === null || pnac === undefined ? "" + (localidad_nac === null || localidad_nac === undefined ? "" : localidad_nac)
            : pnac + (localidad_nac === null || localidad_nac === undefined ? "" : ', ' + localidad_nac), 160, 210)

        documento.setFont(undefined, 'bold')
        documento.text('Estado civil:', 300, 162)
        documento.setFont(undefined, 'normal')
        documento.text(estado_civil === null || estado_civil === undefined ? "" : estado_civil, 375, 162)
        documento.setFont(undefined, 'bold')
        documento.text('Provincia actual:', 30, 230)
        documento.setFont(undefined, 'normal')
        documento.text(prov === null || prov === undefined ? "" : prov, 130, 230)
        documento.setFont(undefined, 'bold')
        documento.text('Localidad actual:', 30, 254)
        documento.setFont(undefined, 'normal')
        documento.text((localidad === null || localidad === undefined ? ' ' : localidad), 130, 254)
        documento.setFont(undefined, 'bold')
        documento.text('Departamento actual:', 30, 242)
        documento.setFont(undefined, 'normal')
        documento.text((departamento === null || departamento === undefined ? '' : departamento), 150, 242)
        documento.setFont(undefined, 'bold')
        documento.text('Dirección actual:', 30, 266)
        documento.setFont(undefined, 'normal')
        documento.text(domicilio === null || domicilio === undefined ? "" : domicilio, 130, 266)
        documento.setFont(undefined, 'bold')
        documento.text('Teléfono:', 300, 230)
        documento.setFont(undefined, 'normal')
        documento.text(telefono === null || telefono === undefined ? "" : (caracteristica_t === null ? '' :
            caracteristica_t === null || caracteristica_t === undefined ? "" : '(' + caracteristica_t + ') ') + telefono, 355, 230)

        documento.setFont(undefined, 'bold')
        documento.text('Celular:', 300, 242)
        documento.setFont(undefined, 'normal')
        documento.text((celular === null || celular === undefined ? "" : (caracteristica_c === null ? '' :
            caracteristica_c === null || caracteristica_c === undefined ? "" : '(' + caracteristica_c + ') ') + celular) +
            ((celular1 === null || celular1 === undefined ? "" : (caracteristica_c1 === null ? '' :
                caracteristica_c1 === null || caracteristica_c1 === undefined ? "" : '(' + caracteristica_c1 + ') ') + celular1)), 350, 242)
        documento.setFont(undefined, 'bold')
        documento.text('E-mail:', 300, 254)
        documento.setFont(undefined, 'normal')
        documento.text(email === null || email === undefined ? "" : email, 350, 254)
        documento.setFont(undefined, 'bold')
        documento.text('Trabajo:', 30, 286)
        documento.setFont(undefined, 'normal')
        documento.text(empresa === null || empresa === undefined ? "" : empresa, 80, 286)
        documento.setFont(undefined, 'bold')
        documento.text('Obra Social:', 300, 286)
        documento.setFont(undefined, 'normal')
        documento.text(obra_social === null || obra_social === undefined ? "" : obra_social, 370, 286)

        documento.setFont(undefined, 'bold')
        documento.text('Fecha de ingreso:', 30, 298)
        documento.setFont(undefined, 'normal')
        documento.text(fecha_ingreso === null || fecha_ingreso === undefined ? '' : fecha_ingreso, 130, 298)

        documento.setFont(undefined, 'bold')
        documento.text('Nº de Obra Social:', 300, 298)
        documento.setFont(undefined, 'normal')
        documento.text(os_numero === null || os_numero === undefined ? '' : ''.concat(os_pre, ' - ', os_numero, ' - ', os_post, ' / ', os_extra), 400, 298)

        documento.setFont(undefined, 'bold')
        documento.text('Función:', 30, 310)
        documento.setFont(undefined, 'normal')
        documento.text(funcion === null || funcion === undefined ? '' : funcion, 80, 310)

        documento.setFont(undefined, 'bold')
        documento.text('Aporte solidario (Plan especial):', 300, 310)
        documento.setFont(undefined, 'normal')
        documento.text(parseInt(plan_especial) === 1 ? 'Sí' : 'No', 473, 310)

        documento.setFont(undefined, 'bold')
        documento.text('Fondo de Auxilio Financiero (FAF):', 300, 322)
        documento.setFont(undefined, 'normal')
        documento.text(parseInt(faf) === 1 ? 'Sí' : 'No', 488, 322)

        documento.setFont(undefined, 'bold')
        documento.text('Caja Jubilación Provicional:', 30, 342)
        documento.setFont(undefined, 'normal')
        documento.text(sistema_provicional === null || sistema_provicional === undefined ? "No" : 'Sí', 185, 342)

        documento.setFont(undefined, 'bold')
        documento.text('Fecha afiliación:', 300, 342)
        documento.setFont(undefined, 'normal')
        documento.text(fecha_afiliacion === null || fecha_afiliacion === undefined ? "" : fecha_afiliacion, 395, 342)

        documento.setFont(undefined, 'bold')
        documento.text('Caja Jubilación Nacional:', 30, 354)
        documento.setFont(undefined, 'normal')
        documento.text(sistema_nacional === null || sistema_nacional === undefined ? 'No' : 'Sí', 170, 354)

        documento.setFont(undefined, 'bold')
        documento.text('Fondo Compensador:', 300, 354)
        documento.setFont(undefined, 'normal')
        documento.text(fondo_compensador === null || fondo_compensador === undefined ? "" : fondo_compensador, 420, 354)

        documento.setFont(undefined, 'bold')
        documento.text('Cuál:', 30, 366)
        documento.setFont(undefined, 'normal')
        documento.text(sistema_nacional === null || sistema_nacional === undefined ? "" : sistema_nacional, 60, 366)

        documento.setFont(undefined, 'bold')
        documento.text('Res. 268/9:', 30, 378)
        documento.setFont(undefined, 'normal')
        documento.text(parseInt(res2689) === 1 ? 'Sí' : 'No', 92, 378)

        //margen x- margen y - datos - cabecera - settings
        documento.setFontSize(10);
        documento.text('Adherentes:', 30, 400)
        autoTable(documento, {
            columns: props.headerTabla,
            body: datamostrar,
            margin: { top: 420 },
            styles: { fontSize: 8 }
        });
        const pageCount = documento.internal.getNumberOfPages();

        for (let i = 1; i <= pageCount; i++) {
            documento.setPage(i);
            const pageSize = documento.internal.pageSize;
            const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            documento.setFontSize(8);
            const header = documento.addImage(logo_1, 'PNG', 250, 20, 80, 80) + '' + documento.text("Informe del " + props.nombre, 260, 120, { baseline: 'center' }) + '' + documento.text('Fecha de impresión ' + dateNow, 440, 120);
            const footer = `Página ${i} de ${pageCount}`;
            documento.text(header, -1, -3);
            documento.text(footer, pageWidth / 2 - (documento.getTextWidth(footer) / 2), pageHeight - 15, { baseline: 'bottom' });
        }
        documento.output('dataurlnewwindow', `informe_${new Date().toISOString()}.pdf`);
    }

    return (
        <Tooltip title="Imprimir reporte">
            <IconButton
                color="primary"
                size="small"
                variant="outlined"
                onClick={handleClick}
                disabled={props.datos === null}
            >
                <PrintIcon />
            </IconButton>
        </Tooltip>
    )
}