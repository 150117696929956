import React, { useEffect } from 'react'
import { useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import api from '../../service/api';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import { Button, Checkbox, FormControl, FormControlLabel, Grid, Stack, TextField } from '@mui/material';
import Modal from '../ui/Modal';
import { ComentarioListado } from './ComentarioListado';

export const Jubilacion = ({
    ver,
    afiliado,
    cerrar,
    permitirJubilacionDefinitiva
}) => {
    const {
        apellido,
        fecha_jubilacion,
        id_afiliado,
        nombre,
        jubilacion_definitiva
    } = afiliado

    const [fJubilacion, setFJubilacion] = useState('');
    const [comentariosListado, setComentariosListado] = useState(false);
    useEffect(() => {
        if (!fecha_jubilacion) setFJubilacion('')
        if (fecha_jubilacion) {
            if (fecha_jubilacion.search('-') > 0) {
                setFJubilacion(fecha_jubilacion)
            }
            else {
                const fecha_ = fecha_jubilacion?.split('/')
                if (fecha_) setFJubilacion(fecha_[2] + '-' + fecha_[1] + '-' + fecha_[0])
            }
        }
        if (ver) {
            setValido(false);
            api.getComentariosAfiliados({ id_afiliado: id_afiliado, accion: 'J' })
                .then(respuesta => setComentariosListado(respuesta.data))
        } else {
            setComentariosListado([]);
        }
    }, [id_afiliado, fecha_jubilacion, ver]);

    const handleCloseModalXs = () => {
        formik.resetForm();
    };
    const [submitting, setSubmitting] = useState(false);
    var validationSchema = Yup.object().shape({
        fecha_jubilacion: Yup.date().required('Requerido')
    });
    const formik = useFormik({
        initialValues: {
            id_afiliado: id_afiliado,
            fecha_jubilacion: fJubilacion,
            vigente: 0,
            comentario: '',
            jubilacion_definitiva: jubilacion_definitiva === 1
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitting(true);
            if (permitirJubilacionDefinitiva) {
                api.jubilarAfiliado(values)
                    .then((res) => {
                        formik.resetForm()
                        cerrar({ mensaje: 'Carga de jubilación exitosa.', error: 'success' })
                        setSubmitting(false);
                    })
                    .catch(function (error) {
                        formik.resetForm()
                        cerrar({ mensaje: error, error: 'error' })
                        setSubmitting(false);
                    });
            } else {
                api.prejubilarAfiliado(values)
                    .then((res) => {
                        formik.resetForm()
                        cerrar({ mensaje: 'Carga de jubilación exitosa.', error: 'success' })
                        setSubmitting(false);
                    })
                    .catch(function (error) {
                        formik.resetForm()
                        cerrar({ mensaje: error, error: 'error' })
                        setSubmitting(false);
                    });
            }
        },
        onReset: () => { },
    });

    const cerrar01 = (resultado) => {
        formik.resetForm();
        cerrar({ mensaje: 'Acción cancelada por el usuario.', error: 'warning' });
    };
    const [valido, setValido] = useState(false);
    const vencimiento = (e) => {
        if (e.target.value !== '') {
            const today = new Date(e.target.value);
            today.setDate(today.getDate() + 180);
            const mes = ((today.getMonth() + 1) < 10) ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1);
            const dia = ((today.getDate() + 1) < 10) ? '0' + today.getDate() : today.getDate();
            const hoy = dia + '/' + mes + '/' + today.getFullYear();
            setValido(hoy);
        } else {
            setValido(false);
        }
    };

    return (
        <Modal open={ver} handleClose={handleCloseModalXs} size="sm">
            <form
                id="formAltaObra"
                name="formAltaObra"
                onSubmit={formik.handleSubmit}
                onReset={formik.handleReset}
                autoComplete="off"
            >
                <Typography variant="titulo" paragraph>
                    {permitirJubilacionDefinitiva ? "Jubilación de afiliado" : "Prejubilación de afiliado"}
                </Typography>
                <Grid container>
                    <p>Afiliado: <b>{apellido}, {nombre}</b></p>
                    <FormControl fullWidth>
                        <TextField
                            autoComplete="off"
                            label="Fecha Jubilación"
                            name="fecha_jubilacion"
                            id="fecha_jubilacion"
                            type="date"
                            focused
                            variant="outlined"
                            value={formik.values.fecha_jubilacion}
                            onChange={e => { formik.handleChange(e); vencimiento(e); }}
                            error={formik.touched.fecha_jubilacion && Boolean(formik.errors.fecha_jubilacion)}
                            helperText={formik.touched.fecha_jubilacion && formik.errors.fecha_jubilacion}
                        />
                    </FormControl>
                    {valido && <p>Afiliado habilitado hasta el {valido}</p>}
                </Grid>
                {permitirJubilacionDefinitiva &&
                    <Grid item xs={10}>
                        <FormControlLabel
                            name="jubilacion_definitiva"
                            value={formik.values.jubilacion_definitiva}
                            checked={formik.values.jubilacion_definitiva}
                            control={<Checkbox />}
                            label="Jubilación definitiva"
                            onChange={formik.handleChange}
                        />
                    </Grid>
                }
                <br />
                <br />
                <Grid container>
                    <FormControl fullWidth>
                        <TextField
                            autoComplete="off"
                            label="Comentario"
                            name="comentario"
                            id="comentario"
                            type="textarea"
                            variant="outlined"
                            value={formik.values.comentario}
                            onChange={formik.handleChange}
                            error={formik.touched.comentario && Boolean(formik.errors.comentario)}
                            helperText={formik.touched.comentario && formik.errors.comentario}
                            multiline
                        />
                    </FormControl>
                </Grid>

                <ComentarioListado comentariosListado={comentariosListado} />
                <br></br>
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="contained"
                        size="medium"
                        color="warning"
                        disabled={submitting}
                        onClick={() => formik.submitForm()}
                        endIcon={<SaveIcon />}
                    >
                        {submitting ? "Guardando..." : "Guardar"}
                    </Button>
                    <Button
                        variant="secondary"
                        size="medium"
                        color="secondary"
                        onClick={() => cerrar01(false)}
                        disabled={submitting}
                        endIcon={<CloseIcon />}
                    >Cerrar
                    </Button>
                </Stack>
            </form>
        </Modal>
    )
}