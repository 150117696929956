import { useEffect } from "react";
import { useState } from "react";
import api from "../service/api";

export const useAfiliados = (buscar = '', buscarA = '', buscarN = '', refrescar, habilitarSinFiltro) => {
    const [datosList, setDatosListState] = useState({ data: null, loading: true, error: true });
    useEffect(() => {
        let ignore = false;
        let cleanedBuscar = buscar !== '' ? buscar : null
        let cleanedBuscarA = buscarA !== '' ? buscarA : null
        let cleanedBuscarN = buscarN !== '' ? buscarN : null
        //Si no hay busqueda no consultar retorna null. Salvo que se pase habilitarSinFiltro en true.
        if (!habilitarSinFiltro && cleanedBuscar === null && cleanedBuscarA === null && cleanedBuscarN === null) {
            setDatosListState({ loading: false, data: null, error: null });
        } else {
            api.getAfiliadosActivos_VW(cleanedBuscar, cleanedBuscarA, cleanedBuscarN)
                .then(resultado => {
                    if (!ignore) {
                        const error = (resultado.data.length > 0) ? false : true;
                        setDatosListState({
                            loading: false,
                            error: error,
                            data: resultado.data
                        });
                    }
                })
                .catch(function (error) {
                    if (!ignore) {
                        setDatosListState({
                            loading: false,
                            error: true,
                            data: null
                        });
                        console.log("getAfiliadosActivos_VW ", error);
                    }
                });
        }
        return () => { ignore = true; }
    }, [buscar, refrescar, buscarA, buscarN, habilitarSinFiltro]);

    return datosList;
}
