import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import MuiIconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { AuthContext } from '../../auth/AuthContext'
import { useHistory } from "react-router-dom";
import { types } from "../../types/types";
import api from "../../service/api";

const IconButton = styled(MuiIconButton)({
  padding: 0.5,
  marginRight: 1,
});

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    border: "1px solid #d3d4d5",
  },
}));

const UserMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorNotificacion, setAnchorNotificacion] = useState(null);
  const [anchorAvisos, setAnchorAvisos] = useState(null);
  const [notificaciones, setNotificaciones] = useState([]);
  const [notificacionesCant, setNotificacionesCant] = useState(0);
  const [refrescar, setRefrescar] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickNotificacion = (event) => {
    setAnchorNotificacion(event.currentTarget);
  };

  const handleCloseNotificacion = () => {
    setAnchorNotificacion(null);
    setRefrescar(refrescar + 1);
  };

  const handleCloseAvisos = () => {
    setAnchorAvisos(null);
  };

  const history = new useHistory();

  const { user: { name, codigo }, dispatch } = useContext(AuthContext)

  const logOut = async () => {
    let datos = {
      id: localStorage.getItem("user_id"),
    };
    api
      .logOutUsuario(datos)
      .then(function (resp) {

        history.replace('/login')
        dispatch({
          type: types.logout
        })

      })
      .catch(function (error) { console.log('El error es =>', error) })
  }

  const handleLogout = () => {
    logOut();
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setRefrescar(Date.now());
    }, 50000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let procesar = true;
    api.obtenerNotificaciones()
      .then((resultado => {
        if (procesar) {
          setNotificaciones(resultado.data.avisos);
          setNotificacionesCant(resultado.data.cantidad);
        }
      })).catch((error) => {
        if (error === 401) {
          dispatch({
            type: types.logout
          });
        }
      });
    return () => procesar = false;
  }, [refrescar, dispatch]);

  return (
    <>
      <Typography
        component="p"
        variant="body2"
        sx={{
          fontWeight: "bold",
          color: "gray",
          mr: 1,
          display: { xs: "none", md: "block" },
        }}
        noWrap
      >
        {codigo} - {name}
      </Typography>
      <Badge badgeContent={notificacionesCant} color="primary" variant="dot">
        <IconButton sx={{ color: "gray" }} onClick={handleClickNotificacion} title="Notificaciones">
          <NotificationsOutlinedIcon />
        </IconButton>
      </Badge>
      <IconButton sx={{ color: "gray" }} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component={Link} to="/perfil" onClick={handleClose}>
          <ListItemIcon>
            <PersonOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary="Perfil"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleLogout();
            handleClose();
          }}
        >
          <ListItemIcon>
            <ExitToAppOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary="Cerrar Sesión"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </StyledMenu>

      <Popover
        id="avisos-menu"
        open={Boolean(anchorAvisos)}
        anchorEl={anchorAvisos}
        onClose={handleCloseAvisos}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: "350px",
            maxWidth: "100%",
          }
        }}
      >
      </Popover>

      <Popover
        id="notificacion-menu"
        open={Boolean(anchorNotificacion)}
        anchorEl={anchorNotificacion}
        onClose={handleCloseNotificacion}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: "350px",
            maxWidth: "100%",
          }
        }}
      >
        <List>
          <ListSubheader component="div" id="notificaciones-subheader">
            NOTIFICACIONES
          </ListSubheader>
          {notificaciones.map((notificacion, index) => (
            <ListItemButton key={index} divider>
              <ListItemText
                secondary={notificacion.mensaje}
                secondaryTypographyProps={{ variant: "body2" }}
              />
            </ListItemButton>
          ))}
        </List>

      </Popover>
    </>
  );
};

export default UserMenu;
