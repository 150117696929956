import axios from "axios";

const baseUrl = String(process.env.REACT_APP_API_URL);
axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("api_token");
    if (accessToken) {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  }, (error) => {
    var respError = null;
    if (error.response && error.response.data) {
      if (error.response.data.status) {
        respError = Promise.reject(error.response.data.status);
      } else if (error.response.data.message) {
        respError = Promise.reject(error.response.data.message);
      }
    }
    if (respError)
      return respError;
    return Promise.reject(error.message);
  }
);

const api = {
  usuarioConectado: () => {
    return axios.get(`${baseUrl}/usuario_conectado`);
  },
  login: (body) => {
    return axios.post(`${baseUrl}/login`, body);
  },
  logOutUsuario: (body) => {
    return axios.post(`${baseUrl}/logout`, body);
  },
  getEmpresas: (params) => {
    return axios.get(`${baseUrl}/empresas`, { params });
  },
  getObrasSociales: (params) => {
    return axios.get(`${baseUrl}/obrassociales`, { params });
  },
  getSistNac: () => {
    return axios.get(`${baseUrl}/sistemasNacionales`);
  },
  getRelaciones: () => {
    return axios.get(`${baseUrl}/relaciones`);
  },
  getEstadosCiviles: () => {
    return axios.get(`${baseUrl}/getEstadosCiviles`);
  },
  getDepartamentos: (body) => {
    return axios.get(`${baseUrl}/departamentos/${body}`);
  },
  getLocalidades: (body) => {
    return axios.get(`${baseUrl}/localidades/${body}`);
  },
  crearPersona: (body) => {
    return axios.post(`${baseUrl}/postPersona`, body);
  },
  actualizarPersona: (body) => {
    return axios.post(`${baseUrl}/actualizarPersona`, body);
  },
  postAfiliadosEmpresas: (body) => {
    return axios.post(`${baseUrl}/postAfiliadosEmpresas`, body);
  },
  postAfiliadosEmpresasObraSocial: (body) => {
    return axios.post(`${baseUrl}/postAfiliadosEmpresasObraSocial`, body);
  },
  postAfiliadosEmpresasJubilacion: (body) => {
    return axios.post(`${baseUrl}/postAfiliadosEmpresasJubilacion`, body);
  },
  postAfiliadosEmpresasObraSocialYJubilacion: (body) => {
    return axios.post(`${baseUrl}/postAfiliadosEmpresasObraSocialYJubilacion`, body);
  },
  getAfiliadoVW: (params) => {
    return axios.get(`${baseUrl}/afiliadoVW`, { params });
  },
  getAfiliadoCompletoVW: (params) => {
    return axios.get(`${baseUrl}/getAfiliadoCompletoVW`, { params });
  },
  getAfiliadosActivos_VW: (datoD, datoA, datoN) => {
    return axios.get(`${baseUrl}/getAfiliadosActivos_VW/${datoD}/${datoA}/${datoN}`);
  },
  getAfiliadosAdherenteVW: (body) => {
    return axios.get(`${baseUrl}/getAfiliadosAdherente_VW/${body}`);
  },
  getAfiliadoAdherentesAll: (body) => {
    return axios.get(`${baseUrl}/getAfiliadoAdherentesAll/${body}`);
  },
  postAfiliadoAdherente: (body) => {
    return axios.post(`${baseUrl}/postAfiliadoAdherente`, body);
  },
  putAfiliadoAdherente: (body) => {
    return axios.put(`${baseUrl}/putAfiliadoAdherente`, body);
  },
  putDeleteAdherete: (body) => {
    return axios.put(`${baseUrl}/putDeleteAdherete`, body);
  },
  putUndeleteAdherete: (body) => {
    return axios.put(`${baseUrl}/putUndeleteAdherete`, body);
  },
  putDeleteAdhereteDefinitivo: (body) => {
    return axios.put(`${baseUrl}/putDeleteAdhereteDefinitivo`, body);
  },
  buscarAfiliadoPorId: (body) => {
    return axios.post(`${baseUrl}/buscarAfiliadoPorId`, body);
  },
  cargarParaEditarAfiliadoPersonaPorId: (body) => {
    return axios.post(`${baseUrl}/cargarParaEditarAfiliadoPersonaPorId`, body);
  },
  prejubilarAfiliado: (body) => {
    return axios.post(`${baseUrl}/prejubilarAfiliado`, body);
  },
  revertirPrejubilarAfiliado: (body) => {
    return axios.post(`${baseUrl}/revertirPrejubilarAfiliado`, body);
  },
  jubilarAfiliado: (body) => {
    return axios.post(`${baseUrl}/jubilarAfiliado`, body);
  },
  revertirJubilarAfiliado: (body) => {
    return axios.post(`${baseUrl}/revertirJubilarAfiliado`, body);
  },
  bajaDefinitivaAfiliado: (body) => {
    return axios.post(`${baseUrl}/bajaDefinitivaAfiliado`, body);
  },
  jubiladosNuevos: (body) => {
    return axios.post(`${baseUrl}/jubiladosNuevos`, body);
  },
  jubiladosBuscar: (body) => {
    return axios.post(`${baseUrl}/jubiladosBuscar/${body}`, body);
  },
  bajaAfiliado: (body) => {
    return axios.post(`${baseUrl}/bajaAfiliado`, body);
  },
  revertirBajaAfiliado: (body) => {
    return axios.post(`${baseUrl}/revertirBajaAfiliado`, body);
  },
  getComentariosAfiliados: (params) => {
    return axios.get(`${baseUrl}/getComentariosAfiliados`, { params })
  },
  buscarAfiliadoCuil: (body) => {
    return axios.post(`${baseUrl}/buscarAfiliadoCuil`, body);
  },
  buscarAfiliados: (body) => {
    return axios.post(`${baseUrl}/afiliados/buscar`, body);
  },
  obtenerNotificaciones: () => {
    return axios.get(`${baseUrl}/afiliados/notificaciones`);
  },
  buscarVotantes: (body) => {
    return axios.post(`${baseUrl}/votantes`, body);
  },
  buscarPostulantes: (body) => {
    return axios.post(`${baseUrl}/postulantes`, body);
  },
  calcularAntiguedad: (body) => {
    return axios.post(`${baseUrl}/calcularAntiguedad`, body);
  },
  obrasSociales: {
    buscarObras: (params) => {
      return axios.get(`${baseUrl}/buscar_obras`, { params });
    },
    modificarObra: (body) => {
      return axios.post(`${baseUrl}/modificar_obra`, body);
    },
    altaObra: (body) => {
      return axios.post(`${baseUrl}/alta_obra`, body);
    },
    eliminarObras: (body) => {
      return axios.post(`${baseUrl}/obras/eliminar`, body);
    },
  },
  empresas: {
    buscarEmpresas: (params) => {
      return axios.get(`${baseUrl}/buscar_empresas`, { params });
    },
    modificarEmpresa: (body) => {
      return axios.post(`${baseUrl}/modificar_empresa`, body);
    },
    altaEmpresa: (body) => {
      return axios.post(`${baseUrl}/alta_empresa`, body);
    },
    eliminarEmpresas: (body) => {
      return axios.post(`${baseUrl}/empresas/eliminar`, body);
    },
  },
  usuarios: {
    buscarPerfiles: (params) => {
      return axios.get(`${baseUrl}/usuarios/perfiles`, { params });
    },
    buscarUsuarios: (body) => {
      return axios.get(`${baseUrl}/usuarios/listado`, body);
    },
    buscarUsuario: (params) => {
      return axios.get(`${baseUrl}/usuarios/id`, { params });
    },
    altaUsuario: (body) => {
      return axios.post(`${baseUrl}/usuarios/crear`, body);
    },
    modificarUsuario: (body) => {
      return axios.post(`${baseUrl}/usuarios/editar`, body);
    },
    bloquearUsuario: (body) => {
      return axios.post(`${baseUrl}/usuarios/bloquearUsuario`, body);
    },
    desbloquearUsuario: (body) => {
      return axios.post(`${baseUrl}/usuarios/desbloquearUsuario`, body);
    },
    modificarPasswordUsuario: (body) => {
      return axios.post(`${baseUrl}/usuarios/editar/password`, body);
    },
  },
  audit: {
    buscarAuditIngresos: (params) => {
      return axios.get(`${baseUrl}/auditoria/buscarAuditIngresos`, { params });
    },
    buscarAuditAfiliados: (params) => {
      return axios.get(`${baseUrl}/auditoria/buscarAuditAfiliados`, { params });
    },
    buscarAuditEmpresas: (params) => {
      return axios.get(`${baseUrl}/auditoria/buscarAuditEmpresas`, { params });
    },
    buscarAuditObrasSociales: (params) => {
      return axios.get(`${baseUrl}/auditoria/buscarAuditObrasSociales`, { params });
    },
    buscarAuditUsuarios: (params) => {
      return axios.get(`${baseUrl}/auditoria/buscarAuditUsuarios`, { params });
    },
    getCambiosAfiliadoByIdAudit: (params) => {
      return axios.get(`${baseUrl}/auditoria/getCambiosAfiliadoByIdAudit`, { params });
    },
    getCambiosEmpresaByIdAudit: (params) => {
      return axios.get(`${baseUrl}/auditoria/getCambiosEmpresaByIdAudit`, { params });
    },
    getCambiosObraSocialByIdAudit: (params) => {
      return axios.get(`${baseUrl}/auditoria/getCambiosObraSocialByIdAudit`, { params });
    },
    getCambiosUsuarioByIdAudit: (params) => {
      return axios.get(`${baseUrl}/auditoria/getCambiosUsuarioByIdAudit`, { params });
    },
    guardarAuditorias: (body) => {
      return axios.post(`${baseUrl}/auditoria/guardar`, body);
    },
    buscarInfAudit: (body) => {
      return axios.post(`${baseUrl}/auditoria/buscar`, body);
    },
  }
};

export default api;
