import React, { useContext, useState } from 'react'
import { AuthContext } from '../auth/AuthContext'
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { useForm } from '../hooks/useForm';
import {
    Table, TableBody,
    TableCell, TableContainer, TableHead,
    TableRow, Button, Grid, Tooltip, TextField,
    Snackbar
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Alert } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import { Page } from '../components/ui/Page';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import { Jubilacion } from '../components/afiliado/Jubilacion';
import { useJubilados } from '../hooks/useJubilados';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    root: {
        flexGrow: 1,
    },
    cabeceraTabla: {
        backgroundColor: 'darkgrey',
        color: 'white'
    },
    jubilado: {
        backgroundColor: '#EFAFC0 !important',
        '&:hover': {
            backgroundColor: '#DBA1B0 !important',
        },
    },
    deshabilitado: {
        backgroundColor: '#FFCF8F !important',
        '&:hover': {
            backgroundColor: '#F1C589 !important',
        },
    }
});

export const SearchJubilados = ({ history }) => {
    const { user: { canJubilar } } = useContext(AuthContext)
    const classes = useStyles();
    const [refrescar, setRefrescar] = useState(0);
    const { search } = useLocation();
    const { q = '' } = queryString.parse(search);

    const [formValues, handleInputChange] = useForm({
        searchText: q
    });

    const { searchText } = formValues;

    const jubilados = useJubilados(q, refrescar);
    const handleSearch = (e) => {
        e.preventDefault();
        setRefrescar(refrescar + 1);
        history.push(`?q=${searchText}`)
    }

    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Jubilado Listado", ruta: "/searchJubilados" },
    ];

    const [abrirModal, setAbrirModal] = useState(false);
    const [afiliado, setAfiliado] = useState(false);

    const toggleModalAbrir = (afiliado) => {
        setAfiliado(afiliado)
        setAbrirModal(!abrirModal)
    };
    const toggleModalCerrar = (resultado) => {
        setMensajeAlert(resultado.mensaje);
        setTipoMensaje(resultado.error);
        setAbrirModal(!abrirModal)
        setOpen(true)
        if (resultado.error !== 'warning')
            setRefrescar(refrescar + 1);
    };

    const [mensajeAlert, setMensajeAlert] = useState('Dato almacenado exitosamente');
    const [tipoMensaje, setTipoMensaje] = useState('success');
    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <Page title="Listado de Jubilados" breadcrumbs={breadcrumbs}>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}>
                <Alert onClose={handleClose} severity={tipoMensaje} sx={{
                    width: '100%'
                }}>
                    {mensajeAlert}
                </Alert>
            </Snackbar>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <form onSubmit={handleSearch}>
                        <TextField
                            autoComplete="off"
                            fullWidth
                            name="searchText"
                            placeholder="Ingresar texto a buscar"
                            size="small"
                            type="text"
                            inputProps={{ style: { textTransform: "uppercase" } }}
                            value={searchText}
                            onChange={handleInputChange}
                        />
                    </form>
                </Grid>
                <Grid item xs={2} justifyContent="flex-end">
                    <Button
                        color="primary"
                        size="medium"
                        fullWidth
                        type="submit"
                        variant="contained"
                        onClick={handleSearch}
                    >
                        Buscar
                    </Button>
                </Grid>
            </Grid>
            <br />

            <Grid className="classes.root">
                {
                    (q !== '' && jubilados.loading) &&
                    <Alert severity="info">
                        Buscando...
                    </Alert>
                }
                {
                    (q !== '' && !jubilados.loading && jubilados.error) &&
                    <Alert severity="error">
                        No se ha encontrado ningún afiliado con {q}
                    </Alert>
                }
                {
                    (q === '') &&
                    <Alert severity="info">
                        Para buscar un Afiliado debe de introducir un texto.
                    </Alert>
                }
                {(q !== '' && !jubilados.error && jubilados.data) &&
                    <Grid container>
                        <TableContainer>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead className={classes.cabeceraTabla}>
                                    <TableRow>
                                        <TableCell align="center">Documento</TableCell>
                                        <TableCell align="center">Nombre</TableCell>
                                        <TableCell align="center">Afiliación</TableCell>
                                        <TableCell align="center">Jubilación</TableCell>
                                        <TableCell align="center">Definitiva</TableCell>
                                        <TableCell align="center">Tarea</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {jubilados.data.map((row) => (
                                        <TableRow hover key={row.id_afiliado} className={
                                            row.jubilacion_definitiva !== null ? classes.jubilado : ''
                                        }>
                                            <TableCell align="center">{row.dni}</TableCell>
                                            <TableCell component="th" scope="row">{row.apellido}, {row.nombre}</TableCell>
                                            <TableCell align="center">{row.fecha_afiliacion}</TableCell>
                                            <TableCell align="center">{row.fecha_jubilacion}</TableCell>
                                            <TableCell align="center">{parseInt(row.jubilacion_definitiva) === 1 ? 'Sí' : 'No'} </TableCell>
                                            <TableCell align="center">
                                                {canJubilar ?
                                                    <>
                                                        <Tooltip title="Jubilación">
                                                            <IconButton
                                                                color="warning"
                                                                size="small"
                                                                type="submit"
                                                                variant="outlined"
                                                                onClick={e => toggleModalAbrir(row)}
                                                            >
                                                                <WorkOffIcon />
                                                            </IconButton >
                                                        </Tooltip>
                                                    </>
                                                    : ''
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                }
            </Grid>
            {canJubilar &&
                <Jubilacion ver={abrirModal} afiliado={afiliado} cerrar={toggleModalCerrar} permitirJubilacionDefinitiva={true} />
            }
        </Page >
    )
}
