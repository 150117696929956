import React from 'react';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import logo_1 from '../../assets/logo.png';

export const DocumentoListadoPdf = (props) => {
    var today = new Date(),
        dateNow = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

    const handleClick = async () => {
        const documento = new jsPDF('p', 'pt', 'a4')
        if (props.nombre === 'Usuarios') {
            const datamostrar = props.datos.map(e => {
                return {
                    Documento: e.dni.toString(),
                    Nombre: e.nombre.toString(),
                    Apellido: e.apellido.toString(),
                    Perfil: e.perfil.toString(),
                };
            });
            const datamostrar2 = datamostrar.map(e => {
                return Object.values(e);
            });
            autoTable(documento, {
                columns: props.headerTabla,
                body: datamostrar2,
                margin: { top: 150 },
                style: { fontSize: 9 }
            });
        } else {
            const datamostrar = props.datos.map(e => {
                return Object.values(e);
            });
            autoTable(documento, {
                columns: props.headerTabla,
                body: datamostrar,
                margin: { top: 150 },
                styles: { fontSize: 6 }
            });
        }

        const pageCount = documento.internal.getNumberOfPages();

        for (let i = 1; i <= pageCount; i++) {
            documento.setPage(i);
            const pageSize = documento.internal.pageSize;
            const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            documento.setFontSize(8);
            const header = documento.addImage(logo_1, 'PNG', 250, 20, 80, 80) + '' + documento.text("Listado de " + props.nombre, 260, 120, { baseline: 'center' }) + '' + documento.text('Fecha de impresión ' + dateNow, 440, 120);
            const footer = `Página ${i} de ${pageCount}`;
            documento.text(header, -1, -3);
            documento.text(footer, pageWidth / 2 - (documento.getTextWidth(footer) / 2), pageHeight - 15, { baseline: 'bottom' });
        }

        documento.save(`informe_${new Date().toISOString()}.pdf`);
    }

    return (
        <Tooltip title="Imprimir reporte">
            <IconButton
                color="primary"
                size="small"
                variant="outlined"
                onClick={handleClick}
                disabled={props.datos === null}
            >
                <PrintIcon />
            </IconButton>
        </Tooltip>
    )
}