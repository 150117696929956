import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../auth/AuthContext'
import {
    Table, TableBody,
    TableCell, TableContainer, TableHead,
    TableRow, Grid, Tooltip,
    Snackbar, Alert
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Page } from '../../components/ui/Page';
import { Jubilacion } from '../../components/afiliado/Jubilacion';
import IconButton from '@mui/material/IconButton';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import api from '../../service/api';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    root: {
        flexGrow: 1,
    },
    cabeceraTabla: {
        backgroundColor: 'darkgrey',
        color: 'white'
    },
    jubilado: {
        backgroundColor: '#EFAFC0 !important',
    },
    deshabilitado: {
        backgroundColor: '#FFCF8F !important',
    }
});

export const JubNuevos = (props) => {
    const { user: { canJubilar } } = useContext(AuthContext)
    const classes = useStyles();

    const [afiliadosFiltered, setAfiliadosFiltered] = useState([]);
    const buscarJubilados = async () => {
        api
            .jubiladosNuevos()
            .then(function (resp) {
                setAfiliadosFiltered(resp.data);
            })
            .catch(function (error) {
                console.log('El error es =>', error)
            })
    };

    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Afiliado Jubilado", ruta: "/jubilados" },
    ];

    const [abrirModal, setAbrirModal] = useState(false);
    const [afiliado, setAfiliado] = useState(false);

    const toggleModalCerrar = (resultado) => {
        setMensajeAlert(resultado.mensaje);
        setTipoMensaje(resultado.error);
        setAbrirModal(!abrirModal)
        setOpen(true)
        if (resultado.error !== 'warning') {
            setRefrescar(refrescar + 1);
        }
    }

    const [mensajeAlert, setMensajeAlert] = useState('Dato almacenado exitosamente');
    const [tipoMensaje, setTipoMensaje] = useState('success');
    const [open, setOpen] = useState(false);

    const toggleModalAbrir = (afiliado) => {
        setAfiliado(afiliado)
        setAbrirModal(!abrirModal)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const [refrescar, setRefrescar] = useState(0);
    useEffect(() => {
        buscarJubilados();
    }, [refrescar]);

    return (
        <Page title="Listado de Jubilados" breadcrumbs={breadcrumbs}>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}>
                <Alert onClose={handleClose} severity={tipoMensaje} sx={{
                    width: '100%'
                }}>
                    {mensajeAlert}
                </Alert>
            </Snackbar>
            <Grid className="classes.root">
                <Grid container>
                    <TableContainer>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead className={classes.cabeceraTabla}>
                                <TableRow>
                                    <TableCell align="center">Documento</TableCell>
                                    <TableCell align="center">Nombre</TableCell>
                                    <TableCell align="center">Fecha Afiliacion</TableCell>
                                    <TableCell align="center">Fecha Jubilacion</TableCell>
                                    <TableCell align="center">Definitiva</TableCell>
                                    <TableCell align="center">Tarea</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {afiliadosFiltered.map((row) => (
                                    <TableRow hover key={row.id_afiliado}>
                                        <TableCell align="center">{row.dni}</TableCell>
                                        <TableCell component="th" scope="row">{row.apellido}, {row.nombre}</TableCell>
                                        <TableCell align="center">{row.fecha_afiliacion}</TableCell>
                                        <TableCell align="center">{row.fecha_jubilacion}</TableCell>
                                        <TableCell align="center">{row.jubilacion_definitiva === 1 ? 'Sí' : 'No'} </TableCell>

                                        <TableCell align="center">
                                            {canJubilar &&
                                                <>
                                                    <Tooltip title="Jubilación">
                                                        <IconButton
                                                            color="warning"
                                                            size="small"
                                                            type="submit"
                                                            variant="outlined"
                                                            onClick={e => toggleModalAbrir(row)}
                                                        >
                                                            <WorkOffIcon />
                                                        </IconButton >
                                                    </Tooltip>
                                                </>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {canJubilar &&
                <Jubilacion ver={abrirModal} afiliado={afiliado} cerrar={toggleModalCerrar} permitirJubilacionDefinitiva={true} />
            }
        </Page >
    )
}