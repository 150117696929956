import React, { useContext, useEffect, useState } from 'react'
import { Button, Container } from '@mui/material';
import api from '../../service/api';
import Divider from '@mui/material/Divider';
import { useParams } from 'react-router';
import { Page } from '../ui/Page';
import { Alert } from '@mui/material'
import { Link } from 'react-router-dom';
import { AdherenteListado } from './AdherenteListado';
import { ReportesListadosAdherenteCompletoPdf } from '../ui/ReportesListadosAdherenteCompletoPdf';
import { AuthContext } from '../../auth/AuthContext';

export const AfiliadoInformacionScreen = ({ location }) => {
    const {
        user: {
            isAdmin,
            canReportesListadosAdherenteCompletoPdf
        }
    } = useContext(AuthContext);
    const { id } = useParams();

    const aux = location.search.split('&')[3];

    const ir = aux === 'u=u' ? 'busqueda' : 'search';

    const [afiliado, setAfiliado] = useState({
        loading: true,
        data: {
            id_afiliado: id,
            nombre_afiliado: '',
            dni_afiliado: '',
            obra_social_afiliado: '',
            empresa_afiliado: '',
            apellido: '',
            aporte_caja_jub: '',
            aporte_sist_nac: '',
            celular: '',
            cuil: '',
            domicilio: '',
            email: '',
            empresa: '',
            fecha_afiliacion: '',
            fecha_baja_afiliacion: '',
            fecha_ingreso: null,
            fecha_jubilacion: '',
            fecha_nacimiento: '',
            fondo_compensador: null,
            funcion: null,
            id_empresa: null,
            id_estado_civil: '',
            id_grupo_sanguineo: '',
            id_localidad: '',
            id_localidad_nac: '',
            id_obra_social: '',
            id_persona: '',
            id_plan: null,
            id_provincia: '',
            id_provincia_nac: '',
            id_sistema_nacional: null,
            id_sistema_provicional: null,
            ingresos: null,
            nacionalidad: '',
            nombre: '',
            obra_social: null,
            res2689: null,
            sindicato: null,
            telefono: '',
            genero: '',
            vigente: 0,
            caracteristica_c: '',
            caracteristica_t: '',
            os_pre: '',
            os_post: '',
            os_numero: '',
            os_extra: '',
            departamento: ''
        },
        error: null
    });
    const [adherentes, setAdherentes] = useState({ data: null, loading: true, error: null });

    const headers = [
        'Cuil',
        'Apellido',
        'Nombre',
        'Fecha Nac.',
        'Parentezco',
        'Genero',
        'Osfatlyf',
        'Nro. Obra Social',
        'Discapacidad'
    ];
    const [adherentes_, setAdherentes_] = useState([]);
    useEffect(() => {
        if (adherentes.data) {
            setAdherentes_(adherentes.data.map(e => {
                const pre_nro = e.pre_nro === null || e.pre_nro === undefined ? "" : e.pre_nro;
                const numero = e.numero === null || e.numero === undefined ? "" : e.numero;
                const post_nro = e.post_nro === null || e.post_nro === undefined ? "" : e.post_nro;
                const extra_nro = e.extra_nro === null || e.extra_nro === undefined ? "" : e.extra_nro;
                const hasNro = pre_nro || numero || post_nro || extra_nro;
                return {
                    Cuil: e.cuil ? e.cuil.toString() : '',
                    Apellido: e.apellido ? e.apellido : '',
                    Nombre: e.nombre ? e.nombre : '',
                    'Fecha Nac.': e.fecha_nacimiento ? e.fecha_nacimiento : '',
                    Parentezco: e.relacion ? e.relacion : '',
                    Genero: e.genero ? e.genero : '',
                    Osfatlyf: e.osfatlyf === 1 ? 'Sí' : 'No',
                    NroOS: hasNro ? ''.concat(pre_nro, '-', numero, '-', post_nro, '/', extra_nro) : '',
                    Discapacidad: e.discapacidad === 1 ? 'Sí' : 'No',
                };
            }));
        } else {
            setAdherentes_([]);
        }
    }, [adherentes]);

    useEffect(() => {
        api.getAfiliadoCompletoVW({ 'id': id })
            .then(respuesta => {
                setAfiliado({
                    loading: false,
                    data: {
                        apellido: respuesta.data.apellido,
                        aporte_caja_jub: respuesta.data.aporte_caja_jub,
                        aporte_sist_nac: respuesta.data.aporte_sist_nac,
                        celular: respuesta.data.celular,
                        cuil: respuesta.data.cuil,
                        prov: respuesta.data.prov,
                        pnac: respuesta.data.pnac,
                        dni: respuesta.data.dni,
                        domicilio: respuesta.data.domicilio,
                        edad: respuesta.data.edad ?? '',
                        email: respuesta.data.email,
                        empresa: respuesta.data.empresa,
                        estado_civil: respuesta.data.estado_civil,
                        fecha_afiliacion: respuesta.data.fecha_afiliacion,
                        fecha_baja_afiliacion: respuesta.data.fecha_baja_afiliacion,
                        fecha_ingreso: respuesta.data.fecha_ingreso,
                        fecha_jubilacion: respuesta.data.fecha_jubilacion,
                        fecha_nacimiento: respuesta.data.fecha_nacimiento ?? '',
                        fondo_compensador: respuesta.data.fondo_compensador,
                        faf: respuesta.data.faf,
                        plan_especial: respuesta.data.plan_especial,
                        funcion: respuesta.data.funcion,
                        grupo_sanguineo: respuesta.data.grupo_sanguineo,
                        id_afiliado: respuesta.data.id_afiliado,
                        id_persona: respuesta.data.id_persona,
                        ingresos: respuesta.data.ingresos,
                        localidad: respuesta.data.localidad,
                        localidad_nac: respuesta.data.localidad_nac,
                        nacionalidad: respuesta.data.nacionalidad,
                        nombre: respuesta.data.nombre,
                        obra_social: respuesta.data.obra_social,
                        sindicato: respuesta.data.sindicato,
                        sistema_nacional: respuesta.data.sistema_nacional,
                        sistema_provicional: respuesta.data.sistema_provicional,
                        telefono: respuesta.data.telefono,
                        res2689: respuesta.data.res2689,
                        vigente: respuesta.data.vigente,
                        genero: respuesta.data.genero,
                        caracteristica_c: respuesta.data.caracteristica_c,
                        caracteristica_t: respuesta.data.caracteristica_t,
                        os_pre: respuesta.data.os_pre,
                        os_post: respuesta.data.os_post,
                        os_numero: respuesta.data.os_numero,
                        os_extra: respuesta.data.os_extra,
                        departamento: respuesta.data.departamento
                    },
                    error: null
                });
                api.getAfiliadosAdherenteVW(id)
                    .then(resp_ad => {
                        setAdherentes({
                            loading: false,
                            error: null,
                            data: resp_ad.data
                        });
                    })
                    .catch((error) => {
                        setAdherentes({
                            loading: false,
                            error: true,
                            data: null
                        });
                    });
            })
            .catch((error) => {
                setAfiliado({
                    loading: false,
                    error: true,
                    data: null
                });
            });
    }, [id])

    const { apellido,
        aporte_sist_nac,
        celular,
        cuil,
        domicilio,
        edad,
        email,
        empresa,
        estado_civil,
        fecha_afiliacion,
        fecha_baja_afiliacion,
        fecha_ingreso,
        fecha_nacimiento,
        fondo_compensador,
        funcion,
        grupo_sanguineo,
        id_afiliado,
        id_persona,
        localidad,
        localidad_nac,
        nacionalidad,
        nombre,
        obra_social,
        sistema_nacional,
        telefono,
        prov,
        plan_especial,
        faf,
        pnac,
        res2689,
        genero,
        caracteristica_c,
        caracteristica_t
    } = afiliado.data ?? {};

    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Listado Afiliados", ruta: "/search" },
    ];

    const pageTitle = "Información del Afiliado";
    if (afiliado.loading) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Alert severity="info">
                    Cargando...
                </Alert>
            </Page>
        );
    } else {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                {canReportesListadosAdherenteCompletoPdf &&
                    <ReportesListadosAdherenteCompletoPdf
                        nombre="afiliado"
                        headerTabla={headers}
                        datos={adherentes_}
                        afiliado={afiliado.data}
                    >
                    </ReportesListadosAdherenteCompletoPdf>
                }
                <Container>
                    <p><b style={{ fontSize: 25 }}>{nombre}, {apellido}</b> &nbsp;&nbsp;&nbsp;&nbsp;<b>DNI/CUIL:</b> {cuil}</p>
                    {isAdmin && <p style={{ fontSize: 'smaller' }}><b>N° Interno: </b> {id_persona}/{id_afiliado}</p>}
                    <p>{fecha_nacimiento != null && <><b>Fecha naciemiento:</b> {fecha_nacimiento} - {edad} &nbsp;&nbsp;&nbsp;&nbsp;</>}<b>Grupo sanguineo:</b> {grupo_sanguineo} {genero && <>&nbsp;&nbsp;&nbsp;&nbsp;<b>Genero:</b> {genero} </>}</p>
                    <p><b>Nacionalidad:</b> {nacionalidad} &nbsp;&nbsp;&nbsp;&nbsp;<b>Lugar de naciemiento:</b> {pnac}, {localidad_nac}</p>
                    <p><b>Dirección:</b> {domicilio}, {localidad}, {prov}</p>
                    <p><b>Teléfono:</b> ({caracteristica_t}) {telefono} &nbsp;&nbsp;&nbsp;&nbsp;<b>Celular:</b> ({caracteristica_c}) {celular}</p>
                    <p><b>E-mail:</b> {email} &nbsp;&nbsp;&nbsp;&nbsp;<b>Estado civil:</b> {estado_civil}</p>
                </Container>
                <Divider /> Datos Laborales
                <Container>
                    <p><b>Trabajo:</b> {empresa} &nbsp;&nbsp;&nbsp;&nbsp;<b>Función:</b> {funcion} &nbsp;&nbsp;&nbsp;&nbsp;<b>Ingreso:</b> {fecha_ingreso}</p>
                    <p><b>Obra Social:</b> {obra_social} &nbsp;&nbsp;&nbsp;&nbsp;</p>
                    <p><b>Sistema Jubilación:</b> {aporte_sist_nac !== 'No' ? 'Sistema Nacional' : 'Sistema Provicional'} &nbsp;&nbsp;&nbsp;&nbsp;<b>Cuál:</b> {sistema_nacional}</p>
                    <p><b>Fecha afiliación:</b> {fecha_afiliacion}</p>
                    {fecha_baja_afiliacion != null && fecha_baja_afiliacion !== '00/00/0000' &&
                        <p><b>Fecha baja:</b> {fecha_baja_afiliacion}</p>
                    }
                    <p><b>Planes:</b>
                        {parseInt(plan_especial) === 1 ? 'Aporte Solidario OSFATLYF (Plan Especial), ' : ' '}
                        {parseInt(faf) === 1 ? 'Fondo de Auxilio Financiero (FAF), ' : ' '}
                        {parseInt(res2689) === 1 ? 'Res. 268/9' : ' '}
                    </p>
                    <p><b>Fondo Compensador:</b> {fondo_compensador != null ? fondo_compensador : ''}</p>
                </Container>
                <Divider />

                <AdherenteListado adherentes={adherentes} edicion={false} />

                <Container>
                    <br />
                    <Link style={{ textDecoration: 'none' }} to={`/${ir}${location.search}`}>
                        <Button variant="contained" underline="none">
                            Volver
                        </Button>
                    </Link>
                </Container>
            </Page>
        )
    }
}