const toUpperCaseHandler = (e, formik) => {
    const v = e.target.value || "";
    formik.setFieldValue(e.target.name, v.toUpperCase());
};

const toLowerCaseHandler = (e, formik) => {
    const v = e.target.value || "";
    formik.setFieldValue(e.target.name, v.toLowerCase());
}

const Utils = {
    toLowerCaseHandler: toLowerCaseHandler,
    toUpperCaseHandler: toUpperCaseHandler
}

export default Utils