import React, { useState, useEffect, useContext } from 'react';
import {
    Tooltip,
    Table, TableBody,
    TableCell, TableContainer, TableHead, InputLabel,
    TableRow, Button, Grid, FormControl, TextField, Stack, Snackbar
} from '@mui/material';
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import LockResetIcon from '@mui/icons-material/LockReset';
import RefreshIcon from '@mui/icons-material/Refresh';
import Modal from '../ui/Modal';
import { Page } from '../ui/Page';
import api from "../../service/api";
import { DocumentoListadoPdf } from '../ui/ReportesListadosPdf';
import { AuthContext } from '../../auth/AuthContext';
import { LockOpen, Lock } from '@mui/icons-material';
import axios from 'axios';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    root: {
        flexGrow: 1,
    },
    cabeceraTabla: {
        backgroundColor: 'darkgrey',
        color: 'white'
    },
    deshabilitado: {
        backgroundColor: '#FFCF8F !important',
        '&:hover': {
            backgroundColor: '#F1C589 !important',
        },
    }
});

export const Usuario = (props) => {
    const { user: {
        isAdmin,
        isSuperAdmin,
        codPerfilAdminGeneral,
        codPerfilAdminGremial
    } } = useContext(AuthContext);
    const [dniMostrar, setDniMostrar] = useState('');
    const [nombreMostrar, setNombreMostrar] = useState('');
    const [apellidoMostrar, setApellidoMostrar] = useState('');
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [openModalEditPass, setOpenModalEditPass] = useState(false);
    const [openModalBloqueo, setOpenModalBloqueo] = useState(false);
    const [openModalDesbloqueo, setOpenModalDesbloqueo] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submittingMod, setSubmittingMod] = useState(false);
    const [submittingModPass, setSubmittingModPass] = useState(false);
    const [submittingModBloqueo, setSubmittingModBloqueo] = useState(false);
    const [submittingModDesbloqueo, setSubmittingModDesbloqueo] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState("");
    const [tipoMensaje, setTipoMensaje] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [refrescar, setRefrescar] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loadingUser, setLoadingUser] = useState(false);

    const classes = useStyles();
    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Usuarios", ruta: "" },
    ];

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [perfiles, setPerfiles] = useState([]);
    const [usuarios, setUsuarios] = useState([]);

    var validationSchema = Yup.object().shape({
        doc: Yup.number()
            .required("Requerido"),
        nombre: Yup.string()
            .required("Requerido")
            .min(2, "Mínimo 2 caracteres")
            .max(100, "Máximo 20 caracteres"),
        apellido: Yup.string()
            .required("Requerido")
            .min(2, "Mínimo 2 caracteres")
            .max(100, "Máximo 100 caracteres"),
        perfil: Yup.number()
            .required("Requerido"),
        password: Yup.string()
            .required("Requerido")
            .min(6, 'Mínimo 6 caracteres')
            .max(12, 'Máximo 12 caracteres'),
    });

    const aNumero = () => {
        const num = formik.values.telefonoActual + '';
        const cel = formik.values.celularActual + '';
        const result = typeof num === 'string' ? num.replace(/\D+/g, '') : ''
        const result1 = typeof num === 'string' ? cel.replace(/\D+/g, '') : ''
        formik.setFieldValue('telefonoActual', result)
        formik.setFieldValue('celularActual', result1)
    }

    const aNumeroMod = () => {
        const carac = formikMod.values.caracteristica_cMod + '';
        const cel = formikMod.values.celularMod + '';
        let result = typeof carac === 'string' ? carac.replace(/\D+/g, '') : ''
        let result1 = typeof cel === 'string' ? cel.replace(/\D+/g, '') : ''

        if (parseInt(result1.substring(0, 2)) === 15)
            result1 = parseInt(result1.substring(2))

        if (parseInt(result.substring(0, 1)) === 0)
            result = parseInt(result.substring(1))

        formikMod.setFieldValue('caracteristica_cMod', result)
        formikMod.setFieldValue('celularMod', result1)
    }

    const formik = useFormik({
        initialValues: {
            doc: "",
            nombre: "",
            apellido: "",
            fecha: "",
            dire: "",
            caracteristica_t: '',
            telefono: "",
            caracteristica_c: '',
            celular: "",
            email: "",
            perfil: "",
            password: "",
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitting(true);
            api.usuarios.altaUsuario({
                dni: values.doc,
                nombre: values.nombre,
                apellido: values.apellido,
                fechanac: values.fecha,
                direccion: values.dire,
                telefono: values.telefono,
                caracteristica_c: values.caracteristica_c,
                celular: values.celular,
                email: values.email,
                perfil: values.perfil,
                contrasenia: values.password,
            })
                .then((res) => {
                    setOpen(true);
                    setMensajeAlert('Se guardó con éxito');
                    setTipoMensaje('success');

                    setTimeout(() => {
                        setSubmitting(false);
                        setOpenModal(false);
                        formik.handleReset();
                        setRefrescar(refrescar + 1);
                    }, 1000);
                })
                .catch(function (error) {
                    setSubmitting(false);
                    setOpen(true);
                    setMensajeAlert('Error al guardar');
                    setTipoMensaje('error');
                    setSubmitting(false);
                    console.log('El error es =>', error)
                });
        },
        onReset: () => { },
    });

    var validationSchemaMod = Yup.object().shape({
        docMod: Yup.number()
            .required("Requerido"),
        nombreMod: Yup.string()
            .required("Requerido")
            .min(2, "Mínimo 2 caracteres")
            .max(100, "Máximo 20 caracteres"),
        apellidoMod: Yup.string()
            .required("Requerido")
            .min(2, "Mínimo 2 caracteres")
            .max(100, "Máximo 100 caracteres"),
        perfilMod: Yup.number()
            .required("Requerido"),
    });

    const formikMod = useFormik({
        initialValues: {
            id: "",
            nombreMod: "",
            apellidoMod: "",
            docMod: "",
            fechaMod: "",
            direMod: "",
            telefonoMod: "",
            caracteristica_cMod: "",
            celularMod: "",
            emailMod: "",
            perfilMod: "",
        },
        enableReinitialize: true,
        validationSchema: validationSchemaMod,
        onSubmit: (values) => {
            setSubmittingMod(true);
            api.usuarios
                .modificarUsuario({
                    id: values.id,
                    dni: values.docMod,
                    nombre: values.nombreMod,
                    apellido: values.apellidoMod,
                    nacimiento: values.fechaMod,
                    direccion: values.direMod,
                    telefono: values.telefonoMod,
                    caracteristica_c: values.caracteristica_cMod,
                    celular: values.celularMod,
                    email: values.emailMod,
                    perfil: values.perfilMod,
                })
                .then((res) => {
                    setOpen(true);
                    setMensajeAlert('Se modificó el usuario con éxito');
                    setTipoMensaje('success');

                    setTimeout(() => {
                        setSubmittingMod(false);
                        setOpenModalEdit(false);
                        formikMod.handleReset();
                        setRefrescar(refrescar + 1);
                    }, 1000);
                })
                .catch(function (error) {
                    setSubmittingMod(false);
                    setOpen(true);
                    setMensajeAlert('Error al modificar');
                    setTipoMensaje('error');
                    setSubmittingMod(false);
                    console.log('El error es =>', error)
                });
        },
        onReset: () => { },
    });

    var validationSchemaModPass = Yup.object().shape({
        passwordMod: Yup.string()
            .required("Requerido")
            .min(6, 'Mínimo 6 caracteres')
            .max(12, 'Máximo 12 caracteres'),
    });

    const formikModPass = useFormik({
        initialValues: {
            id: "",
            passwordMod: ""
        },
        enableReinitialize: true,
        validationSchema: validationSchemaModPass,
        onSubmit: (values) => {
            setSubmittingModPass(true);
            api.usuarios
                .modificarPasswordUsuario({
                    id: values.id,
                    contrasenia: values.passwordMod
                })
                .then((res) => {
                    setOpen(true);
                    setMensajeAlert('Se modificó la contraseña de usuario con éxito');
                    setTipoMensaje('success');

                    setTimeout(() => {
                        setSubmittingModPass(false);
                        setOpenModalEditPass(false);
                        formikModPass.handleReset();
                        setRefrescar(refrescar + 1);
                    }, 1000);
                })
                .catch(function (error) {
                    setSubmittingModPass(false);
                    setOpen(true);
                    setMensajeAlert('Error al modificar la contraseña');
                    setTipoMensaje('error');
                    setSubmittingMod(false);
                    console.log('El error es =>', error)
                });
        },
        onReset: () => { },
    });

    const formikModBloqueo = useFormik({
        initialValues: {
            id: ""
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            setSubmittingModBloqueo(true);
            api.usuarios
                .bloquearUsuario({
                    id: values.id,
                    contrasenia: values.passwordMod
                })
                .then((res) => {
                    setOpen(true);
                    setMensajeAlert('Se modificó la contraseña de usuario con éxito');
                    setTipoMensaje('success');

                    setTimeout(() => {
                        setSubmittingModBloqueo(false);
                        setOpenModalBloqueo(false);
                        formikModBloqueo.handleReset();
                        setRefrescar(refrescar + 1);
                    }, 1000);
                })
                .catch(function (error) {
                    setSubmittingModBloqueo(false);
                    setOpen(true);
                    setMensajeAlert('Error al modificar la contraseña');
                    setTipoMensaje('error');
                    setSubmittingModBloqueo(false);
                    console.log('El error es =>', error)
                });
        },
        onReset: () => { },
    });

    const formikModDesbloqueo = useFormik({
        initialValues: {
            id: ""
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            setSubmittingModDesbloqueo(true);
            api.usuarios
                .desbloquearUsuario({
                    id: values.id,
                })
                .then((res) => {
                    setOpen(true);
                    setMensajeAlert('Se desbloqueo usuario con éxito');
                    setTipoMensaje('success');

                    setTimeout(() => {
                        setSubmittingModDesbloqueo(false);
                        setOpenModalDesbloqueo(false);
                        formikModDesbloqueo.handleReset();
                        setRefrescar(refrescar + 1);
                    }, 1000);
                })
                .catch(function (error) {
                    setSubmittingModDesbloqueo(false);
                    setOpen(true);
                    setMensajeAlert('Error al desbloquear');
                    setTipoMensaje('error');
                    console.log('El error es =>', error)
                });
        },
        onReset: () => { },
    });

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleCloseModalEdit = () => {
        setOpenModalEdit(false);
        formikMod.handleReset();
    };

    const handleCloseModalEditPass = () => {
        setOpenModalEditPass(false);
    };

    const handleCloseModalBloquear = () => {
        setOpenModalBloqueo(false);
    };

    const handleCloseModalDesbloquear = () => {
        setOpenModalBloqueo(false);
    };

    const aceptarModal = () => {
        setOpenModal(false);
    };

    const handleClick = () => {
        formik.handleReset();
        setOpenModal(true);
    };

    const handleClickModificar = (e) => {
        setLoadingUser(true);
        api.usuarios
            .buscarUsuario({
                usuario_id: e,
            })
            .then(function (resp) {
                setLoadingUser(false);
                formikMod.setFieldValue("id", e);
                formikMod.setFieldValue("nombreMod", resp.data.usuario[0].nombre);
                formikMod.setFieldValue("apellidoMod", resp.data.usuario[0].apellido);
                formikMod.setFieldValue("docMod", resp.data.usuario[0].dni);
                formikMod.setFieldValue("fechaMod", resp.data.usuario[0].nacimiento);
                formikMod.setFieldValue("direMod", resp.data.usuario[0].domicilio);
                formikMod.setFieldValue("telefonoMod", resp.data.usuario[0].telefono);
                formikMod.setFieldValue("caracteristica_cMod", resp.data.usuario[0].caracteristica_c);
                formikMod.setFieldValue("celularMod", resp.data.usuario[0].celular);
                formikMod.setFieldValue("emailMod", resp.data.usuario[0].email);
                formikMod.setFieldValue("perfilMod", resp.data.usuario[0].idperfil);
                formikMod.setFieldValue("id", e);
                setOpenModalEdit(true);
            })
            .catch(function (error) {
                setLoadingUser(false);
                setOpen(true);
                setTipoMensaje('error');
                setMensajeAlert('Error al cargar datos del usuario.');
        });
    };

    const handleClickModificarContraseña = (e, dni, nom, ape) => {
        setDniMostrar(dni);
        setNombreMostrar(nom);
        setApellidoMostrar(ape);
        setOpenModalEditPass(true);
        formikModPass.setFieldValue("id", e);
    };

    const handleClickBloquearUsuario = (e, dni, nom, ape) => {
        setDniMostrar(dni);
        setNombreMostrar(nom);
        setApellidoMostrar(ape);
        setOpenModalBloqueo(true);
        formikModBloqueo.setFieldValue("id", e);
    };

    const handleClickDesbloquearUsuario = (e, dni, nom, ape) => {
        setDniMostrar(dni);
        setNombreMostrar(nom);
        setApellidoMostrar(ape);
        setOpenModalDesbloqueo(true);
        formikModDesbloqueo.setFieldValue("id", e);
    };

    const confirmar = () => {
        formik.submitForm();
    };

    const confirmarMod = () => {
        formikMod.submitForm();
    };

    const confirmarModPass = () => {
        formikModPass.submitForm();
    };

    const confirmarModBloqueo = () => {
        formikModBloqueo.submitForm();
    };

    const confirmarModDesbloqueo = () => {
        formikModDesbloqueo.submitForm();
    };

    const headers = [
        'Dni',
        'Nombre',
        'Apellido',
        'Perfil de usuario',
    ]

    const recargar = (e) => {
        setRefrescar(refrescar + 1);
    }

    useEffect(() => {
        setLoading(true);
        let procesar = true;
        axios
            .all([
                api.usuarios.buscarPerfiles(),
                api.usuarios.buscarUsuarios()
            ])
            .then(axios.spread((respPerfiles, respUsuarios) => {
                if (procesar) {
                    setPerfiles(respPerfiles.data.perfiles);
                    setUsuarios(respUsuarios.data.usuarios);
                    setLoading(false);
                }
            }))
            .catch(error => {
                if (procesar) {
                    console.log(error);
                    setLoading(false);
                }
            });
        return () => {
            procesar = false;
        }
    }, [refrescar]);

    const getClassName = (row) => {
        if (row.vigente === 0 || row.intentos_ingreso > 3)
            return classes.deshabilitado;
        return '';
    }

    return (
        <Page title="Adminitración de Usuarios" breadcrumbs={breadcrumbs}>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}>
                <Alert onClose={handleClose} severity={tipoMensaje} sx={{
                    width: '100%'
                }}>
                    {mensajeAlert}
                </Alert>
            </Snackbar>
            <Grid container>
                <Grid item xs={6} align="left">
                    <DocumentoListadoPdf
                        nombre="Usuarios"
                        headerTabla={headers}
                        datos={usuarios}
                    >
                    </DocumentoListadoPdf>
                    <IconButton
                        color="primary"
                        size="small"
                        type="submit"
                        variant="outlined"
                        onClick={recargar}
                    >
                        <RefreshIcon />
                    </IconButton>
                </Grid>

                {isAdmin &&
                    <Grid item xs={6} align="right">
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={handleClick}
                            endIcon={<AddIcon />}
                        >Nuevo </Button>
                    </Grid>
                }
            </Grid>
            <br></br>
            <Grid container>
                {loading ?
                    <Grid item xs={12}>
                        <Alert severity="info">
                            Cargando...
                        </Alert>
                    </Grid>
                    :
                    <TableContainer>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead className={classes.cabeceraTabla}>
                                <TableRow>
                                    <TableCell align="center">Dni</TableCell>
                                    <TableCell align="center">Nombres</TableCell>
                                    <TableCell align="center">Domicilio</TableCell>
                                    <TableCell align="center">Telefono</TableCell>
                                    <TableCell align="center">Celular</TableCell>
                                    <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Perfil</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usuarios.map((row) => (
                                    <TableRow key={row.id} className={getClassName(row)}>
                                        <TableCell align="center">{row.dni}</TableCell>
                                        <TableCell align="center">{row.apellido + ', ' + row.nombre}</TableCell>
                                        <TableCell align="center">{row.domicilio}</TableCell>
                                        <TableCell align="center">{row.telefono}</TableCell>
                                        <TableCell align="center">{row.caracteristica_c}-{row.celular}</TableCell>
                                        <TableCell align="center">{row.email}</TableCell>
                                        {(row.codperfil === codPerfilAdminGremial || row.codperfil === codPerfilAdminGeneral) ?
                                            (<TableCell align="center" style={{ color: '#1976d2', }}><strong>{row.perfil + ' (cod: ' + row.codperfil + ')'}</strong></TableCell>)
                                            : (<TableCell align="center"><strong>{row.perfil + ' (cod: ' + row.codperfil + ')'}</strong></TableCell>)
                                        }
                                        {isSuperAdmin ?
                                            <TableCell align="center" width="10%">
                                                <Tooltip title="Modificar usuario">
                                                    <IconButton
                                                        color="primary"
                                                        size="small"
                                                        variant="outlined"
                                                        onClick={() => handleClickModificar(row.id)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title="Blanqueo de contraseña">
                                                    <IconButton
                                                        color="primary"
                                                        size="small"
                                                        variant="outlined"
                                                        onClick={() => handleClickModificarContraseña(row.id, row.dni, row.nombre, row.apellido)}
                                                    >
                                                        <LockResetIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                {parseInt(row.vigente) === 1 && row.intentos_ingreso < 4?
                                                    <Tooltip title="Bloqueo de usuario">
                                                        <IconButton
                                                            color="success"
                                                            size="small"
                                                            variant="outlined"
                                                            onClick={() => handleClickBloquearUsuario(row.id, row.dni, row.nombre, row.apellido)}
                                                        >
                                                            <LockOpen />
                                                        </IconButton>
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title="Desbloquear usuario">
                                                        <IconButton
                                                            color="error"
                                                            size="small"
                                                            variant="outlined"
                                                            onClick={() => handleClickDesbloquearUsuario(row.id, row.dni, row.nombre, row.apellido)}
                                                        >
                                                            <Lock />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </TableCell>
                                            : null
                                        }
                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Grid>
            <Modal open={openModal} handleClose={handleCloseModal} size="lg">
                <form
                    id="formAltaUsuario"
                    name="formAltaUsuario"
                    onSubmit={formik.handleSubmit}
                    onReset={formik.handleReset}
                    autoComplete="off"
                >
                    <Typography variant="titulo" paragraph>
                        Nuevo Usuario
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={2} >
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    placeholder="Documento"
                                    label="Documento"
                                    name="doc"
                                    id="doc"
                                    type="number"
                                    variant="outlined"
                                    value={formik.values.doc}
                                    onChange={formik.handleChange}
                                    error={formik.touched.doc && Boolean(formik.errors.doc)}
                                    helperText={formik.touched.doc && formik.errors.doc}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={5} >
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    placeholder="Nombres"
                                    label="Nombres"
                                    name="nombre"
                                    id="nombre"
                                    type="text"
                                    variant="outlined"
                                    value={formik.values.nombre}
                                    onChange={formik.handleChange}
                                    error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                                    helperText={formik.touched.nombre && formik.errors.nombre}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={5} >
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    placeholder="Apellido"
                                    label="Apellido"
                                    name="apellido"
                                    id="apellido"
                                    type="text"
                                    variant="outlined"
                                    value={formik.values.apellido}
                                    onChange={formik.handleChange}
                                    error={formik.touched.apellido && Boolean(formik.errors.apellido)}
                                    helperText={formik.touched.apellido && formik.errors.apellido}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br></br>
                    <Grid container spacing={2}>
                        <Grid item xs={2} >
                            <FormControl
                                variant="outlined"
                                error={
                                    formik.touched.fecha && Boolean(formik.errors.fecha)
                                }
                                fullWidth
                            >
                                <TextField
                                    id="fecha"
                                    name="fecha"
                                    type="date"
                                    value={formik.values.fecha}
                                    label="Fecha de nacimiento"
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    InputLabelProps={{ shrink: true }} />
                                <FormHelperText>
                                    {formik.touched.fecha && formik.errors.fecha}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={10} >
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    placeholder="Dirección"
                                    label="Dirección"
                                    name="dire"
                                    id="dire"
                                    type="text"
                                    variant="outlined"
                                    value={formik.values.dire}
                                    onChange={formik.handleChange}
                                    error={formik.touched.dire && Boolean(formik.errors.dire)}
                                    helperText={formik.touched.dire && formik.errors.dire}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br></br>
                    <Grid container spacing={2}>
                        <Grid item xs={3} >
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    placeholder="Teléfono"
                                    label="Teléfono"
                                    name="telefono"
                                    id="telefono"
                                    type="text"
                                    variant="outlined"
                                    value={formik.values.telefono}
                                    onChange={formik.handleChange}
                                    error={formik.touched.telefono && Boolean(formik.errors.telefono)}
                                    helperText={formik.touched.telefono && formik.errors.telefono}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={2} md={1}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    id="caracteristica_c"
                                    label="Característica"
                                    name="caracteristica_c"
                                    value={formik.values.caracteristica_c}
                                    onChange={formik.handleChange}
                                    onBlur={aNumero}
                                    error={formik.touched.caracteristica_c && Boolean(formik.errors.caracteristica_c)}
                                    helperText={formik.touched.caracteristica_c && formik.errors.caracteristica_c}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={3} >
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    placeholder="Celular"
                                    label="Celular"
                                    name="celular"
                                    id="celular"
                                    type="text"
                                    onBlur={aNumero}
                                    variant="outlined"
                                    value={formik.values.celular}
                                    onChange={formik.handleChange}
                                    error={formik.touched.celular && Boolean(formik.errors.celular)}
                                    helperText={formik.touched.celular && formik.errors.celular}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} >
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    placeholder="Email"
                                    label="Email"
                                    name="email"
                                    id="email"
                                    type="text"
                                    variant="outlined"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br></br>
                    <Grid container>
                        <Grid item xs={12} >
                            <FormControl fullWidth>
                                <InputLabel htmlFor="perfil">Perfil de usuario</InputLabel>
                                <Select
                                    autoComplete="off"
                                    label="Perfil de usuario"
                                    name="perfil"
                                    id="perfil"
                                    value={formik.values.perfil}
                                    onChange={formik.handleChange}
                                    error={formik.touched.perfil && Boolean(formik.errors.perfil)}
                                    helperText={formik.touched.perfil && formik.errors.perfil}
                                >
                                    {perfiles.map((row) => (
                                        <MenuItem key={row.id} value={row.id}>{row.descripcion + ' (' + row.codigo + ')'}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br></br>
                    <Grid container>
                        <Grid item xs={12} >
                            <FormControl fullWidth>
                                <TextField
                                    variant="outlined"
                                    id="password"
                                    name="password"
                                    label="Constraseña"
                                    type={showPassword ? "text" : "password"}
                                    value={formik.values.password}
                                    autoComplete="current-password"
                                    onChange={formik.handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br></br>
                    <br></br>
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            disabled={submitting}
                            onClick={confirmar}
                            endIcon={<SaveIcon />}
                        >
                            {submitting ? "Guardando..." : "Guardar"}
                        </Button>
                        <Button
                            variant="secondary"
                            size="medium"
                            color="secondary"
                            onClick={aceptarModal}
                            endIcon={<CloseIcon />}
                        >Cerrar
                        </Button>
                    </Stack>
                </form>
            </Modal>

            <Modal open={openModalEdit} handleClose={handleCloseModalEdit} size="lg">
                <Typography variant="titulo" paragraph>
                    Modificar usuario
                </Typography>
                {loadingUser ?
                    <Alert severity="info">
                        Cargando...
                    </Alert>
                    :
                    <form
                        id="formModUsuario"
                        name="formModUsuario"
                        onSubmit={formikMod.handleSubmit}
                        onReset={formikMod.handleReset}
                        autoComplete="off"
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={2} >
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        placeholder="Documento"
                                        label="Documento"
                                        name="docMod"
                                        id="docMod"
                                        type="number"
                                        variant="outlined"
                                        value={formikMod.values.docMod}
                                        onChange={formikMod.handleChange}
                                        error={formikMod.touched.docMod && Boolean(formikMod.errors.docMod)}
                                        helperText={formikMod.touched.docMod && formikMod.errors.docMod}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={5} >
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        placeholder="Nombres"
                                        label="Nombres"
                                        name="nombreMod"
                                        id="nombreMod"
                                        type="text"
                                        variant="outlined"
                                        value={formikMod.values.nombreMod}
                                        onChange={formikMod.handleChange}
                                        error={formikMod.touched.nombreMod && Boolean(formikMod.errors.nombreMod)}
                                        helperText={formikMod.touched.nombreMod && formikMod.errors.nombreMod}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={5} >
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        placeholder="Apellido"
                                        label="Apellido"
                                        name="apellidoMod"
                                        id="apellidoMod"
                                        type="text"
                                        variant="outlined"
                                        value={formikMod.values.apellidoMod}
                                        onChange={formikMod.handleChange}
                                        error={formikMod.touched.apellidoMod && Boolean(formikMod.errors.apellidoMod)}
                                        helperText={formikMod.touched.apellidoMod && formikMod.errors.apellidoMod}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br></br>
                        <Grid container spacing={2}>
                            <Grid item xs={2} >
                                <FormControl
                                    variant="outlined"
                                    error={
                                        formikMod.touched.fechaMod && Boolean(formikMod.errors.fechaMod)
                                    }
                                    fullWidth
                                >
                                    <TextField
                                        id="fechaMod"
                                        name="fechaMod"
                                        type="date"
                                        value={formikMod.values.fechaMod}
                                        label="Fecha de nacimiento"
                                        variant="outlined"
                                        onChange={formikMod.handleChange}
                                        InputLabelProps={{ shrink: true }} />
                                    <FormHelperText>
                                        {formikMod.touched.fechaMod && formikMod.errors.fechaMod}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={10} >
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        placeholder="Dirección"
                                        label="Dirección"
                                        name="direMod"
                                        id="direMod"
                                        type="text"
                                        variant="outlined"
                                        value={formikMod.values.direMod}
                                        onChange={formikMod.handleChange}
                                        error={formikMod.touched.direMod && Boolean(formikMod.errors.direMod)}
                                        helperText={formikMod.touched.direMod && formikMod.errors.direMod}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br></br>
                        <Grid container spacing={2}>
                            <Grid item xs={3} >
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        placeholder="Teléfono"
                                        label="Teléfono"
                                        name="telefonoMod"
                                        id="telefonoMod"
                                        type="text"
                                        variant="outlined"
                                        value={formikMod.values.telefonoMod}
                                        onChange={formikMod.handleChange}
                                        error={formikMod.touched.telefonoMod && Boolean(formikMod.errors.telefonoMod)}
                                        helperText={formikMod.touched.telefonoMod && formikMod.errors.telefonoMod}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={2} md={1}>
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        label="Característica"
                                        id="caracteristica_cMod"
                                        name="caracteristica_cMod"
                                        value={formikMod.values.caracteristica_cMod}
                                        onChange={formikMod.handleChange}
                                        onBlur={aNumeroMod}
                                        error={formikMod.touched.caracteristica_cMod && Boolean(formikMod.errors.caracteristica_cMod)}
                                        helperText={formikMod.touched.caracteristica_cMod && formikMod.errors.caracteristica_cMod}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={3} >
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        placeholder="Celular"
                                        label="Celular"
                                        name="celularMod"
                                        id="celularMod"
                                        onBlur={aNumeroMod}
                                        type="text"
                                        variant="outlined"
                                        value={formikMod.values.celularMod}
                                        onChange={formikMod.handleChange}
                                        error={formikMod.touched.celularMod && Boolean(formikMod.errors.celularMod)}
                                        helperText={formikMod.touched.celularMod && formikMod.errors.celularMod}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} >
                                <FormControl fullWidth>
                                    <TextField
                                        autoComplete="off"
                                        placeholder="Email"
                                        label="Email"
                                        name="emailMod"
                                        id="emailMod"
                                        type="text"
                                        variant="outlined"
                                        value={formikMod.values.emailMod}
                                        onChange={formikMod.handleChange}
                                        error={formikMod.touched.emailMod && Boolean(formikMod.errors.emailMod)}
                                        helperText={formikMod.touched.emailMod && formikMod.errors.emailMod}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br></br>
                        <Grid container>
                            <Grid item xs={12} >
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="perfil">Perfil de usuario</InputLabel>
                                    <Select
                                        autoComplete="off"
                                        label="Perfil de usuario"
                                        name="perfilMod"
                                        id="perfilMod"
                                        value={formikMod.values.perfilMod}
                                        onChange={formikMod.handleChange}
                                        error={formikMod.touched.perfilMod && Boolean(formikMod.errors.perfilMod)}
                                        helperText={formikMod.touched.perfilMod && formikMod.errors.perfilMod}
                                    >
                                        {perfiles.map((row) => (
                                            <MenuItem key={row.id} value={row.id}>{row.descripcion + ' (' + row.codigo + ')'}</MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br></br>
                        <br></br>
                        <Stack direction="row" spacing={2}>
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                disabled={submittingMod}
                                onClick={confirmarMod}
                                endIcon={<SaveIcon />}
                            >
                                {submittingMod ? "Modificando..." : "Modificar"}
                            </Button>
                            <Button
                                variant="secondary"
                                size="medium"
                                color="secondary"
                                onClick={handleCloseModalEdit}
                                endIcon={<CloseIcon />}
                            >Cerrar
                            </Button>
                        </Stack>
                    </form>
                }
            </Modal>
            <Modal open={openModalEditPass} handleClose={handleCloseModalEdit} size="sm">
                <Typography variant="titulo" paragraph>
                    Modificar contraseña
                </Typography>
                <Typography variant="overline">
                    {apellidoMostrar + ', ' + nombreMostrar + ' - (' + dniMostrar + ')'}
                </Typography>
                <form
                    id="formModContrasenia"
                    name="formModContrasenia"
                    onSubmit={formikModPass.handleSubmit}
                    onReset={formikModPass.handleReset}
                    autoComplete="off"
                >
                    <br></br>
                    <Grid container>
                        <Grid item xs={12} >
                            <FormControl fullWidth>
                                <TextField
                                    variant="outlined"
                                    id="passwordMod"
                                    name="passwordMod"
                                    label="Nueva constraseña"
                                    type={showPassword ? "text" : "password"}
                                    value={formikModPass.values.passwordMod}
                                    autoComplete="current-password"
                                    onChange={formikModPass.handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff />
                                                    ) : (
                                                        <Visibility />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={formikModPass.touched.passwordMod && Boolean(formikModPass.errors.passwordMod)}
                                    helperText={formikModPass.touched.passwordMod && formikModPass.errors.passwordMod}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br></br>
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            disabled={submittingModPass}
                            onClick={confirmarModPass}
                            endIcon={<SaveIcon />}
                        >
                            {submittingModPass ? "Modificando..." : "Modificar"}
                        </Button>
                        <Button
                            variant="secondary"
                            size="medium"
                            color="secondary"
                            onClick={handleCloseModalEditPass}
                            endIcon={<CloseIcon />}
                        >Cerrar
                        </Button>
                    </Stack>
                </form>
            </Modal>

            <Modal open={openModalBloqueo} handleClose={handleCloseModalBloquear} size="sm">
                <Typography variant="titulo" paragraph>
                    Deshabilitación de Usuario
                </Typography>
                <Typography variant="overline">
                    {apellidoMostrar + ', ' + nombreMostrar + ' - (' + dniMostrar + ')'}
                </Typography>
                <form
                    id="formModBloquear"
                    name="formModBloquear"
                    onSubmit={formikModBloqueo.handleSubmit}
                    onReset={formikModBloqueo.handleReset}
                    autoComplete="off"
                >
                    <br></br>

                    <br></br>
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            disabled={submittingModBloqueo}
                            onClick={confirmarModBloqueo}
                            endIcon={<SaveIcon />}
                        >
                            {submittingModBloqueo ? "Bloqueando..." : "Bloquear"}
                        </Button>
                        <Button
                            variant="secondary"
                            size="medium"
                            color="secondary"
                            onClick={handleCloseModalBloquear}
                            endIcon={<CloseIcon />}
                        >Cerrar
                        </Button>
                    </Stack>
                </form>
            </Modal>

            <Modal open={openModalDesbloqueo} handleClose={handleCloseModalDesbloquear} size="sm">
                <Typography variant="titulo" paragraph>
                    Habilitación de Usuario
                </Typography>
                <Typography variant="overline">
                    {apellidoMostrar + ', ' + nombreMostrar + ' - (' + dniMostrar + ')'}
                </Typography>
                <form
                    id="formModDesbloquear"
                    name="formModDesbloquear"
                    onSubmit={formikModDesbloqueo.handleSubmit}
                    onReset={formikModDesbloqueo.handleReset}
                    autoComplete="off"
                >
                    <br></br>

                    <br></br>
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            disabled={submittingModDesbloqueo}
                            onClick={confirmarModDesbloqueo}
                            endIcon={<SaveIcon />}
                        >
                            {submittingModDesbloqueo ? "Desbloqueando..." : "Desbloquear"}
                        </Button>
                        <Button
                            variant="secondary"
                            size="medium"
                            color="secondary"
                            onClick={handleCloseModalDesbloquear}
                            endIcon={<CloseIcon />}
                        >Cerrar
                        </Button>
                    </Stack>
                </form>
            </Modal>
        </Page>
    )
}