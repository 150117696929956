import React, { useState, useEffect, useContext } from 'react';
import {
    Tooltip,
    Table, TableBody,
    TableCell, TableContainer, TableHead,
    TableRow, Button, Grid, FormControl, TextField, Stack, Snackbar
} from '@mui/material';
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import Modal from '../ui/Modal';
import { Page } from '../ui/Page';
import api from "../../service/api";
import { DocumentoListadoPdf } from '../ui/ReportesListadosPdf';
import { AuthContext } from '../../auth/AuthContext';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    root: {
        flexGrow: 1,
    },
    cabeceraTabla: {
        backgroundColor: 'darkgrey',
        color: 'white'
    },
    deshabilitado: {
        backgroundColor: '#FFCF8F !important',
        '&:hover': {
            backgroundColor: '#F1C589 !important',
        },
    }
});

export const Empresa = (props) => {
    const { user: { canABMEmpresas } } = useContext(AuthContext);

    const [open, setOpen] = useState(false);
    const [openModalXsNew, setOpenModalXsNew] = useState(false);
    const [openModalXsEdit, setOpenModalXsEdit] = useState(false);
    const [openModalXsDelete, setOpenModalXsDelete] = useState(false);
    const [submittingNew, setSubmittingNew] = useState(false);
    const [submittingEdit, setSubmittingEdit] = useState(false);
    const [submittingDelete, setSubmittingDelete] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState("");
    const [tipoMensaje, setTipoMensaje] = useState("");
    const [nombreMostrar, setNombreMostrar] = useState("");
    const [id, setId] = useState("");
    const [refrescar, setRefrescar] = useState(0);
    const [empresas, setEmpresa] = useState({ loading: true, data: [], error: false });

    const classes = useStyles();
    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Empresas", ruta: "/empresa" },
    ];

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const getClassName = (row) => {
        if (row.activo === 'N')
            return classes.deshabilitado;
        return '';
    }

    var validationSchemaNew = Yup.object().shape({
        codEmpresa: Yup.string()
            .required("Requerido")
            .typeError("Solo números"),
        cuitEmpresa: Yup.string()
            .required("Requerido")
            .typeError("Solo números"),
        nombreEmpresa: Yup.string()
            .required("Requerido")
            .min(2, "Mínimo 2 caracteres")
            .max(100, "Máximo 100 caracteres")
    });

    const formikNew = useFormik({
        initialValues: {
            nombreEmpresa: "",
            codEmpresa: "",
            cuitEmpresa: "",
            dirEmpresa: "",
            telEmpresa: "",
            emailEmpresa: "",
            responsableEmpresa: "",
        },
        enableReinitialize: true,
        validationSchema: validationSchemaNew,
        onSubmit: (values) => {
            setSubmittingNew(true);
            api.empresas
                .altaEmpresa({
                    nombre: values.nombreEmpresa,
                    codigo: values.codEmpresa,
                    cuit: values.cuitEmpresa,
                    direccion: values.dirEmpresa,
                    telefono: values.telEmpresa,
                    email: values.emailEmpresa,
                    responsable: values.responsableEmpresa,
                })
                .then((res) => {
                    setRefrescar(refrescar + 1);
                    formikNew.handleReset();
                    setOpen(true);
                    setMensajeAlert('Se gurdó con éxito');
                    setTipoMensaje('success');
                    setSubmittingNew(false);
                    setOpenModalXsNew(false);
                })
                .catch(function (error) {
                    setOpen(true);
                    setMensajeAlert('Error al guardar. Verifique los datos ingresados');
                    setTipoMensaje('error');
                    setSubmittingNew(false);
                    console.log('El error es =>', error)
                })
        },
        onReset: () => { },
    });

    var validationSchemaEdit = Yup.object().shape({
        codEmpresaMod: Yup.string()
            .required("Requerido")
            .typeError("Solo números"),
        cuitEmpresaMod: Yup.string()
            .required("Requerido")
            .typeError("Solo números"),
        nombreEmpresaMod: Yup.string()
            .required("Requerido")
            .min(2, "Mínimo 2 caracteres")
            .max(100, "Máximo 100 caracteres")
    });

    const formikEdit = useFormik({
        initialValues: {
            id: "",
            nombreEmpresaMod: "",
            codEmpresaMod: "",
            cuitEmpresaMod: "",
            dirEmpresaMod: "",
            telEmpresaMod: "",
            emailEmpresaMod: "",
            responsableEmpresaMod: ""
        },
        enableReinitialize: true,
        validationSchema: validationSchemaEdit,
        onSubmit: (values) => {
            setSubmittingEdit(true);
            api.empresas
                .modificarEmpresa({
                    id: values.id,
                    nombre: values.nombreEmpresaMod,
                    codigo: values.codEmpresaMod,
                    cuit: values.cuitEmpresaMod,
                    direccion: values.dirEmpresaMod,
                    telefono: values.telEmpresaMod,
                    email: values.emailEmpresaMod,
                    responsable: values.responsableEmpresaMod
                })
                .then((res) => {
                    setRefrescar(refrescar + 1);
                    setOpen(true);
                    setMensajeAlert('Se modificó con éxito');
                    setTipoMensaje('success');
                    setSubmittingEdit(false);
                    setOpenModalXsEdit(false);
                    formikEdit.handleReset();
                })
                .catch(function (error) {
                    setSubmittingEdit(false);
                    setOpen(true);
                    setMensajeAlert('Error al modificar. Verifique los datos ingresados');
                    setTipoMensaje('error');
                    setSubmittingEdit(false);
                    console.log('El error es =>', error)
                })
        },
        onReset: () => { },
    });

    const handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleCloseModalXsNew = () => {
        setOpenModalXsNew(false);
    };

    const handleCloseModalXsEdit = () => {
        setOpenModalXsEdit(false);
    };

    const handleCloseModalXsDelete = () => {
        setOpenModalXsDelete(false);
    };

    const handleClickNew = () => {
        formikNew.handleReset();
        setOpenModalXsNew(true);
    };

    const handleClickEdit = (e, data, datacod, datacuit, datadir, datatel, dataemail, dataresponsable) => {
        formikEdit.setFieldValue("id", e);
        formikEdit.setFieldValue("nombreEmpresaMod", data);
        formikEdit.setFieldValue("codEmpresaMod", datacod);
        formikEdit.setFieldValue("cuitEmpresaMod", datacuit);
        formikEdit.setFieldValue("dirEmpresaMod", datadir);
        formikEdit.setFieldValue("telEmpresaMod", datatel);
        formikEdit.setFieldValue("emailEmpresaMod", dataemail);
        formikEdit.setFieldValue("responsableEmpresaMod", dataresponsable);

        setOpenModalXsEdit(true);
    };

    const handleClickDelete = (e, desc, cod) => {
        setId(e);
        setNombreMostrar('(' + cod + ') ' + desc);
        setOpenModalXsDelete(true);
    };

    const confirmarNew = () => {
        formikNew.submitForm();
    };

    const confirmarEdit = () => {
        formikEdit.submitForm();
    };

    const confirmarDelete = (id) => {
        setSubmittingDelete(true);
        api.empresas
            .eliminarEmpresas({
                'id': id,
            })
            .then(function (resp) {
                setRefrescar(refrescar + 1);
                setOpenModalXsDelete(false);
                setOpen(true);
                setMensajeAlert('Se eliminó con éxito');
                setTipoMensaje('success');
                setSubmittingDelete(false);
            })
            .catch(function (error) {
                setSubmittingDelete(false);
                setOpen(true);
                console.log('El error es =>', error)
                setMensajeAlert('Error al eliminar');
                setTipoMensaje('error');
            });
    };

    const headers = [
        'Id',
        'Codigo',
        'Cuit',
        'Nombre',
        'Dirección',
        'Teléfono',
        'Email',
        'Responsable',
    ];

    useEffect(() => {
        let procesar = true;
        api.empresas
            .buscarEmpresas()
            .then(function (resp) {
                if (procesar)
                    setEmpresa({ loading: false, data: resp.data, error: false });
            })
            .catch(function (error) {
                if (procesar)
                    setEmpresa({ error: true, loading: false, data: [] });
                console.log('El error es =>', error)
            });
        return () => procesar = false;
    }, [refrescar]);

    const empresasPdf = (empresas.data &&
        empresas.data.map((e) => {
            return {
                id: e.id,
                codigo: e.codigo,
                cuit: e.cuit,
                nombre: e.descripcion,
                direccion: e.direccion,
                telefono: e.telefono,
                email: e.email,
                responsable: e.responsable,
            };
        })) || [];

    const pageTitle = "Empresas";
    if (empresas.loading) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Grid item xs={12}>
                    <Alert severity="info">
                        Cargando...
                    </Alert>
                </Grid>
            </Page>
        )
    } else if (empresas.error) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Grid item xs={12}>
                    <Alert severity="error">
                        No se pudo cargar listado de empresas.
                    </Alert>
                </Grid>
            </Page>
        )
    } else {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Snackbar open={open} autoHideDuration={2000} onClose={handleCloseAlert} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}>
                    <Alert onClose={handleCloseAlert} severity={tipoMensaje} sx={{
                        width: '100%'
                    }}>
                        {mensajeAlert}
                    </Alert>
                </Snackbar>
                {canABMEmpresas &&
                    <Grid container>
                        <Grid item xs={6} align="left">
                            <DocumentoListadoPdf
                                nombre="Empresas"
                                headerTabla={headers}
                                datos={empresasPdf}
                            >
                            </DocumentoListadoPdf>
                        </Grid>
                        <Grid item xs={6} align="right">
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={handleClickNew}
                                endIcon={<AddIcon />}
                            >
                                Nuevo
                            </Button>
                        </Grid>
                    </Grid>
                }
                <br></br>
                <Grid container>
                    <Grid item>Empresas encontradas: {empresas.data.length}</Grid>
                    <TableContainer>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead className={classes.cabeceraTabla}>
                                <TableRow>
                                    <TableCell>Codigo</TableCell>
                                    <TableCell align="center">Cuit</TableCell>
                                    <TableCell align="center">Nombre</TableCell>
                                    <TableCell align="center">Dirección</TableCell>
                                    <TableCell align="center">Teléfono</TableCell>
                                    <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Responsable</TableCell>
                                    {canABMEmpresas &&
                                        <TableCell align="center"></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {empresas.data.map((row) => (
                                    <TableRow key={row.id} className={getClassName(row)}>
                                        <TableCell align="right">{row.codigo}</TableCell>
                                        <TableCell align="right">{row.cuit}</TableCell>
                                        <TableCell align="left">{row.descripcion}</TableCell>
                                        <TableCell align="left">{row.direccion}</TableCell>
                                        <TableCell align="right">{row.telefono}</TableCell>
                                        <TableCell align="left">{row.email}</TableCell>
                                        <TableCell align="left">{row.responsable}</TableCell>
                                        {canABMEmpresas &&
                                            <TableCell align="right">
                                                <Tooltip title="Modificar empresa">
                                                    <IconButton
                                                        color="primary"
                                                        size="small"
                                                        variant="outlined"
                                                        onClick={() => handleClickEdit(row.id, row.descripcion, row.codigo, row.cuit, row.direccion, row.telefono, row.email, row.responsable)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Eliminar empresa">
                                                    <IconButton
                                                        color="primary"
                                                        size="small"
                                                        variant="outlined"
                                                        onClick={() => handleClickDelete(row.id, row.descripcion, row.codigo)}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {canABMEmpresas &&
                    <>
                        <Modal open={openModalXsNew} handleClose={handleCloseModalXsNew} size="sm">
                            <form
                                id="formAltaEmpresa"
                                name="formAltaEmpresa"
                                onSubmit={formikNew.handleSubmit}
                                onReset={formikNew.handleReset}
                                autoComplete="off"
                            >
                                <Typography variant="titulo" paragraph>
                                    Nueva empresa
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete="off"
                                                placeholder="Código"
                                                label="Código"
                                                name="codEmpresa"
                                                id="codEmpresa"
                                                type="number"
                                                variant="outlined"
                                                value={formikNew.values.codEmpresa}
                                                onChange={formikNew.handleChange}
                                                error={formikNew.touched.codEmpresa && Boolean(formikNew.errors.codEmpresa)}
                                                helperText={formikNew.touched.codEmpresa && formikNew.errors.codEmpresa}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete="off"
                                                placeholder="Cuit"
                                                label="Cuit"
                                                name="cuitEmpresa"
                                                id="cuitEmpresa"
                                                type="number"
                                                variant="outlined"
                                                value={formikNew.values.cuitEmpresa}
                                                onChange={formikNew.handleChange}
                                                error={formikNew.touched.cuitEmpresa && Boolean(formikNew.errors.cuitEmpresa)}
                                                helperText={formikNew.touched.cuitEmpresa && formikNew.errors.cuitEmpresa}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <br></br>
                                <Grid container>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete="off"
                                            placeholder="Nombre de la empresa"
                                            label="Nombre de la empresa"
                                            name="nombreEmpresa"
                                            id="nombreEmpresa"
                                            type="text"
                                            variant="outlined"
                                            value={formikNew.values.nombreEmpresa}
                                            onChange={formikNew.handleChange}
                                            error={formikNew.touched.nombreEmpresa && Boolean(formikNew.errors.nombreEmpresa)}
                                            helperText={formikNew.touched.nombreEmpresa && formikNew.errors.nombreEmpresa}
                                        />
                                    </FormControl>
                                </Grid>
                                <br></br>
                                <Grid container>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete="off"
                                            placeholder="Dirección"
                                            label="Dirección"
                                            name="dirEmpresa"
                                            id="dirEmpresa"
                                            type="text"
                                            variant="outlined"
                                            value={formikNew.values.dirEmpresa}
                                            onChange={formikNew.handleChange}
                                            error={formikNew.touched.dirEmpresa && Boolean(formikNew.errors.dirEmpresa)}
                                            helperText={formikNew.touched.dirEmpresa && formikNew.errors.dirEmpresa}
                                        />
                                    </FormControl>
                                </Grid>
                                <br></br>
                                <Grid container>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete="off"
                                            placeholder="Teléfono"
                                            label="Teléfono"
                                            name="telEmpresa"
                                            id="telEmpresa"
                                            type="number"
                                            variant="outlined"
                                            value={formikNew.values.telEmpresa}
                                            onChange={formikNew.handleChange}
                                            error={formikNew.touched.telEmpresa && Boolean(formikNew.errors.telEmpresa)}
                                            helperText={formikNew.touched.telEmpresa && formikNew.errors.telEmpresa}
                                        />
                                    </FormControl>
                                </Grid>
                                <br></br>
                                <Grid container>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete="off"
                                            placeholder="Email"
                                            label="Email"
                                            name="emailEmpresa"
                                            id="emailEmpresa"
                                            type="text"
                                            variant="outlined"
                                            value={formikNew.values.emailEmpresa}
                                            onChange={formikNew.handleChange}
                                            error={formikNew.touched.emailEmpresa && Boolean(formikNew.errors.emailEmpresa)}
                                            helperText={formikNew.touched.emailEmpresa && formikNew.errors.emailEmpresa}
                                        />
                                    </FormControl>
                                </Grid>
                                <br></br>
                                <Grid container>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete="off"
                                            placeholder="Responsable"
                                            label="Responsable"
                                            name="responsableEmpresa"
                                            id="responsableEmpresa"
                                            type="text"
                                            variant="outlined"
                                            value={formikNew.values.responsableEmpresa}
                                            onChange={formikNew.handleChange}

                                        />
                                    </FormControl>
                                </Grid>
                                <br></br>
                                <br></br>
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="primary"
                                        disabled={submittingNew}
                                        onClick={confirmarNew}
                                        endIcon={<SaveIcon />}
                                    >
                                        {submittingNew ? "Guardando..." : "Guardar"}
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        size="medium"
                                        color="secondary"
                                        onClick={handleCloseModalXsNew}
                                        endIcon={<CloseIcon />}
                                    >Cerrar
                                    </Button>
                                </Stack>
                            </form>
                        </Modal>
                        <Modal open={openModalXsEdit} handleClose={handleCloseModalXsEdit} size="sm">
                            <form
                                id="formModEmpresa"
                                name="formModEmpresa"
                                onSubmit={formikEdit.handleSubmit}
                                onReset={formikEdit.handleReset}
                                autoComplete="off"
                            >
                                <Typography variant="titulo" paragraph>
                                    Modificar empresa
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete="off"
                                                placeholder="Código"
                                                label="Código"
                                                name="codEmpresaMod"
                                                id="codEmpresaMod"
                                                type="number"
                                                variant="outlined"
                                                value={formikEdit.values.codEmpresaMod}
                                                onChange={formikEdit.handleChange}
                                                error={formikEdit.touched.codEmpresaMod && Boolean(formikEdit.errors.codEmpresaMod)}
                                                helperText={formikEdit.touched.codEmpresaMod && formikEdit.errors.codEmpresaMod}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete="off"
                                                placeholder="Cuit"
                                                label="Cuit"
                                                name="cuitEmpresaMod"
                                                id="cuitEmpresaMod"
                                                type="number"
                                                variant="outlined"
                                                value={formikEdit.values.cuitEmpresaMod}
                                                onChange={formikEdit.handleChange}
                                                error={formikEdit.touched.cuitEmpresaMod && Boolean(formikEdit.errors.cuitEmpresaMod)}
                                                helperText={formikEdit.touched.cuitEmpresaMod && formikEdit.errors.cuitEmpresaMod}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <br></br>
                                <Grid container>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete="off"
                                            placeholder="Nombre de la empresa"
                                            label="Nombre de la empresa"
                                            name="nombreEmpresaMod"
                                            id="nombreEmpresaMod"
                                            type="text"
                                            variant="outlined"
                                            value={formikEdit.values.nombreEmpresaMod}
                                            onChange={formikEdit.handleChange}
                                            error={formikEdit.touched.nombreEmpresaMod && Boolean(formikEdit.errors.nombreEmpresaMod)}
                                            helperText={formikEdit.touched.nombreEmpresaMod && formikEdit.errors.nombreEmpresaMod}
                                        />
                                    </FormControl>
                                </Grid>
                                <br></br>
                                <Grid container>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete="off"
                                            placeholder="Dirección"
                                            label="Dirección"
                                            name="dirEmpresaMod"
                                            id="dirEmpresaMod"
                                            type="text"
                                            variant="outlined"
                                            value={formikEdit.values.dirEmpresaMod}
                                            onChange={formikEdit.handleChange}
                                            error={formikEdit.touched.dirEmpresaMod && Boolean(formikEdit.errors.dirEmpresaMod)}
                                            helperText={formikEdit.touched.dirEmpresaMod && formikEdit.errors.dirEmpresaMod}
                                        />
                                    </FormControl>
                                </Grid>
                                <br></br>
                                <Grid container>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete="off"
                                            placeholder="Teléfono"
                                            label="Teléfono"
                                            name="telEmpresaMod"
                                            id="telEmpresaMod"
                                            type="number"
                                            variant="outlined"
                                            value={formikEdit.values.telEmpresaMod}
                                            onChange={formikEdit.handleChange}
                                            error={formikEdit.touched.telEmpresaMod && Boolean(formikEdit.errors.telEmpresaMod)}
                                            helperText={formikEdit.touched.telEmpresaMod && formikEdit.errors.telEmpresaMod}
                                        />
                                    </FormControl>
                                </Grid>
                                <br></br>
                                <Grid container>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete="off"
                                            placeholder="Email"
                                            label="Email"
                                            name="emailEmpresaMod"
                                            id="emailEmpresaMod"
                                            type="text"
                                            variant="outlined"
                                            value={formikEdit.values.emailEmpresaMod}
                                            onChange={formikEdit.handleChange}
                                            error={formikEdit.touched.emailEmpresaMod && Boolean(formikEdit.errors.emailEmpresaMod)}
                                            helperText={formikEdit.touched.emailEmpresaMod && formikEdit.errors.emailEmpresaMod}
                                        />
                                    </FormControl>
                                </Grid>
                                <br></br>
                                <Grid container>
                                    <FormControl fullWidth>
                                        <TextField
                                            autoComplete="off"
                                            placeholder="Responsable"
                                            label="Responsable"
                                            name="responsableEmpresaMod"
                                            id="responsableEmpresaMod"
                                            type="text"
                                            variant="outlined"
                                            value={formikEdit.values.responsableEmpresaMod}
                                            onChange={formikEdit.handleChange}
                                        />
                                    </FormControl>
                                </Grid>
                                <br></br>
                                <br></br>
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="primary"
                                        onClick={confirmarEdit}
                                        endIcon={<EditIcon />}
                                    >
                                        {submittingEdit ? "Modificando..." : "Modificar"}
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        size="medium"
                                        color="secondary"
                                        onClick={handleCloseModalXsEdit}
                                        endIcon={<CloseIcon />}
                                    >Cerrar
                                    </Button>
                                </Stack>
                            </form>
                        </Modal>
                        <Modal open={openModalXsDelete} handleClose={handleCloseModalXsDelete} size="sm">
                            <Typography variant="titulo" paragraph>
                                Eliminar empresa
                            </Typography>
                            <Typography variant='cuerpo'><b>{nombreMostrar}</b></Typography>
                            <br></br>
                            <br></br>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    onClick={() => confirmarDelete(id)}
                                >
                                    {submittingDelete ? "Eliminando..." : "Eliminar"}
                                </Button>
                                <Button
                                    variant="secondary"
                                    size="medium"
                                    color="secondary"
                                    onClick={handleCloseModalXsDelete}
                                    endIcon={<CloseIcon />}
                                >Cerrar
                                </Button>
                            </Stack>
                        </Modal>
                    </>
                }
            </Page>
        )
    }
}