import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";


const BreadcrumbsContainer = styled(AppBar)(({ theme }) => ({
  marginTop: 56,
  height: 49,
  backgroundColor: "white",
  borderBottom: "1px solid #e0e0e0",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    paddingLeft: 256,
  },
}));

const BreadcrumbsContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    marginLeft: 24,
  },
  [theme.breakpoints.down("md")]: {
    paddingLeft: theme.spacing(2),
  },
}));

const StyledLink = styled(MuiLink)(({ theme }) => ({
  color: "#799F4F",
  "&:hover": {
    color: "#799F4F",
  },
}));

const PageBreadcrumbs = (props) => {
  const crumbs = [];

  props.data.forEach((elemento) => {
    let item = null;

    if (elemento.ruta !== "") {
      item = (
        <StyledLink
          component={Link}
          key={elemento.ruta}
          to={elemento.ruta}
          variant="body2"
        >
          <Typography variant="body2">{elemento.nombre}</Typography>
        </StyledLink>
      );
    } else {
      item = (
        <Typography key={elemento.ruta} variant="secondary" color="secondary">
          {elemento.nombre}
        </Typography>
      );
    }

    crumbs.push(item);
  });


  return (
    <BreadcrumbsContainer elevation={0}>
      <BreadcrumbsContent>
        <MuiBreadcrumbs aria-label="breadcrumbs">{crumbs}</MuiBreadcrumbs>
      </BreadcrumbsContent>
    </BreadcrumbsContainer>
  );
};

export default PageBreadcrumbs;
