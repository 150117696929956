import React from 'react'
import { Stack } from '@mui/material'

export const ComentarioListado = ({comentariosListado}) => {
    return (
        <>
            <br></br>
            <Stack direction="row" spacing={2}>
                Comentarios
                <ul>
                    {comentariosListado && comentariosListado.map(comentario =>
                        (<li key={comentario.id}>{comentario.comentario}</li>))}
                </ul>
            </Stack>
        </>
    )
}

