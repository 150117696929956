import React, { useState, useEffect, useRef } from 'react';
import {
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Grid, Alert, Stack,
    Button,
    TextField
} from '@mui/material';
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import { Page } from '../ui/Page';
import api from "../../service/api";
import SearchIcon from '@mui/icons-material/Search';
import DataTables from "datatables.net-dt";
import 'datatables.net-dt/css/dataTables.dataTables.css';
import { InformesAuditPdf } from '../../commons/InformesAuditPdf';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        width: '100% !important',
    },
    root: {
        flexGrow: 1,
    },
    cabeceraTabla: {
        backgroundColor: 'darkgrey',
        color: 'white'
    }
});

const TablaAudit = ({
    datos
}) => {
    const tableRef = useRef();
    const classes = useStyles();

    useEffect(() => {
        const dt = new DataTables(tableRef.current, {
            searching: true,
            order: [[2, 'desc']],
        });
        return () => {
            dt.destroy();
        };
    }, [datos]);

    const get_tipo_display = (tipo) => {
        switch (tipo) {
            case 0: return "INGRESO";
            case 1: return "FALLO";
            case 2: return "BLOQUEADO";
            default: return "SIN DESCRIPCION (".concat(tipo, ")");
        }
    };

    if (datos.loading) {
        return (
            <Grid item xs={12} >
                <Alert severity="info">
                    Cargando...
                </Alert>
            </Grid>
        );
    } else if (datos.error) {
        return (<Grid item xs={12} >
            <Alert severity="error">
                No se pudo cargar la información solicitada.
            </Alert>
        </Grid>
        );
    } else if (datos.data) {
        return (
            <Grid container>
                {
                    datos.data.desde && datos.data.hasta &&
                    <p>Ingresos desde <b>{datos.data.desde}</b> hasta <b>{datos.data.hasta}</b>.</p>
                }
                <Grid container>
                    <InformesAuditPdf
                        nombre="Auditorías de Acceso al sistema "
                        datos={datos.data.informes_audit.map((row) => {
                            return {
                                nombre: row.nombre_usuario,
                                dni: row.dni,
                                fecha_hora: row.fecha_hora,
                                codigo_perfil: row.codigo_perfil,
                                tipo: get_tipo_display(row.tipo),
                                intentos: row.intentos_ingreso
                            };
                        })}
                        headerTabla={[
                            'Nombre',
                            'DNI',
                            'Fecha hora',
                            'Perfil ingreso',
                            'Tipo',
                            'Intentos'
                        ]}
                    ></InformesAuditPdf>
                </Grid>
                <Grid container>
                    <Grid item xs={12} >
                        <TableContainer>
                            <Table ref={tableRef} className={`${classes.table} stripe compact`} size="small" aria-label="a dense table">
                                <TableHead className={classes.cabeceraTabla}>
                                    <TableRow>
                                        <TableCell align="left" className="dt-head-left">Nombre</TableCell>
                                        <TableCell align="left" className="dt-head-left">Dni</TableCell>
                                        <TableCell align="right" className="dt-head-right">Fecha hora</TableCell>
                                        <TableCell align="right" className="dt-head-right">Perfil ingreso</TableCell>
                                        <TableCell align="center" className="dt-head-center">Tipo</TableCell>
                                        <TableCell align="right" className="dt-head-right">Intento</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {datos.data.informes_audit.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell align="left" className='dt-body-left'>{row.nombre_usuario}</TableCell>
                                            <TableCell align="left" className='dt-body-left'>{row.dni}</TableCell>
                                            <TableCell align="right" className='dt-body-right' data-order={row.fecha_hora_iso}>{row.fecha_hora}</TableCell>
                                            <TableCell align="right" className='dt-body-right'>{row.codigo_perfil}</TableCell>
                                            <TableCell align="center" className='dt-body-center'>{get_tipo_display(row.tipo)}</TableCell>
                                            <TableCell align="right" className='dt-body-right'>{row.intentos_ingreso}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid item xs={12} >
                <Alert severity="error">
                    Error inesperado.
                </Alert>
            </Grid>
        );
    }
}

export const AuditarIngresos = (props) => {
    const [datos, setDatos] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const classes = useStyles();
    const pageTitle = "Auditoria ingresos";

    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: pageTitle, ruta: "/auditorias/ingresos" },
    ];

    const { fechaDesdeIso, fechaHastaIso } = datos !== null && datos.data ?
        datos.data
        : { fechaDesdeIso: '', fechaHastaIso: '' };

    const getAuditoria = (values) => {
        setSubmitting(true);
        setDatos({ loading: true });
        api.audit.buscarAuditIngresos(values)
            .then((resp) => {
                setDatos({ data: resp.data });
                setSubmitting(false);
            })
            .catch((error) => {
                setDatos({ error: true });
                setSubmitting(false);
                console.log('El error es => ', error);
            });
    };

    var validationSchema = Yup.object().shape({
    });
    const formik = useFormik({
        initialValues: {
            fechaDesde: fechaDesdeIso,
            fechaHasta: fechaHastaIso,
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: getAuditoria,
        onReset: () => { },
    });

    useEffect(() => {
        getAuditoria();
    }, []);

    return (
        <Page title={pageTitle} breadcrumbs={breadcrumbs}>
            <Grid className={classes.root}>
                <form
                    id="formBuscar"
                    name="formBuscar"
                    onSubmit={formik.handleSubmit}
                    onReset={formik.handleReset}
                    autoComplete="off"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                autoComplete="off"
                                id="fechaDesde"
                                label="Fecha de Desde"
                                name="fechaDesde"
                                value={formik.values.fechaDesde}
                                onChange={formik.handleChange}
                                error={formik.touched.fechaDesde && Boolean(formik.errors.fechaDesde)}
                                helperText={formik.touched.fechaDesde && formik.errors.fechaDesde}
                                type="date"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                autoComplete="off"
                                id="fechaHasta"
                                label="Fecha de Hasta"
                                name="fechaHasta"
                                value={formik.values.fechaHasta}
                                onChange={formik.handleChange}
                                error={formik.touched.fechaHasta && Boolean(formik.errors.fechaHasta)}
                                helperText={formik.touched.fechaHasta && formik.errors.fechaHasta}
                                type="date"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            disabled={submitting}
                            onClick={formik.handleSubmit}
                            endIcon={<SearchIcon />}
                        >
                            {submitting ? "Buscando..." : "Buscar"}
                        </Button>
                    </Stack>
                </form>
                <br />
                {
                    datos !== null &&
                    <TablaAudit datos={datos} />
                }
            </Grid>
        </Page>
    );
}