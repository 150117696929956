import React, { useEffect, useState } from 'react'
import {
    Button, FormControl, InputLabel, Grid, Container,
    TextField, Select, MenuItem, Divider,
    Snackbar,
    FormHelperText,
    Checkbox
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router';
import { Page } from './../ui/Page';
import api from '../../service/api';
import { useFetch } from '../../hooks/useFetch';
import { AdherenteListado } from './AdherenteListado';
import { Link } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import { AdherenteBajaDefinitiva } from './AdherenteBajaDefinitiva';
import Utils from '../../commons/Utils';

export const AdherenteEdicion = ({ location }) => {
    const useStyles = makeStyles({
        error: {
            backgroundColor: '#EFAFC0',
            '&:hover': {
                backgroundColor: '#DBA1B0 !important',
            },
        },
    });
    const classes = useStyles();
    const { id } = useParams();
    const [submitting, setSubmitting] = useState(false);
    const [eliminando, setEliminando] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState("");
    const [tipoMensaje, setTipoMensaje] = useState("");
    const [nuevo, setNuevo] = useState(true);
    const [checkOsfatlyf, setCheckOsfatlyf] = useState(false);
    const actuCheckOsfatlyf = (e) => {
        setCheckOsfatlyf(!checkOsfatlyf)
    }
    const [checkDiscapacidad, setCheckDiscapacidad] = useState(false);
    const actuCheckDiscapacidad = (e) => {
        setCheckDiscapacidad(!checkDiscapacidad)
    }
    const [adherenteSeleccionado, setAdherenteSeleccionado] = useState({
        id_persona: 0,
        vigente: 0,
    });
    const [adherenteBajaDefinitiva, setAdherenteBajaDefinitva] = useState({});
    const [abrirModalBajaDefinitiva, setAbrirModalBajaDefinitiva] = useState(false);

    var validationSchema = Yup.object().shape({});
    const formik = useFormik({
        initialValues: {
            id_afiliado: id,
            id_persona: '',
            apellido: '',
            nombre: '',
            prefijo: '',
            dni: '',
            verificador: '',
            fechaDeNacimiento: '',
            relacion: '',
            telefono: null,
            celular: null,
            domicilio: null,
            email: null,
            id_grupo_sanguineo: null,
            pre_nro: '',
            numero: '',
            post_nro: '',
            extra_nro: '',
            osfatlyf: '',
            genero: '',
            discapacidad: ''
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitting(true);
            if (!nuevo) {
                api.putAfiliadoAdherente(values)
                    .then(respuesta => {
                        setMensajeAlert('El Adherente se gurdó con éxito');
                        setTipoMensaje('success');
                        setOpen(true);
                        setAdherente({ loading: true, error: null, data: null });
                        api.getAfiliadoAdherentesAll(id)
                            .then(resp_ad => {
                                setAdherente({
                                    loading: false,
                                    error: null,
                                    data: resp_ad.data
                                });
                                setSubmitting(false);
                                reiniciarForm();
                            })
                            .catch(function (error) {
                                setMensajeAlert('Error al obtener listado de adherentes');
                                setTipoMensaje('error');
                                setOpen(true);
                                setSubmitting(false);
                                setAdherente({ loading: false, error: true, data: null });
                                reiniciarForm();
                            });
                    })
                    .catch(function (error) {
                        setMensajeAlert('Error al guardar los datos del Adherente');
                        setTipoMensaje('error');
                        setSubmitting(false);
                        setOpen(true);
                    });
            } else {
                api.postAfiliadoAdherente(values)
                    .then(respuesta => {
                        setMensajeAlert('El Adherente nuevo se gurdó con éxito');
                        setTipoMensaje('success');
                        setOpen(true);
                        setAdherente({ loading: true, error: null, data: null });
                        api.getAfiliadoAdherentesAll(id)
                            .then(resp_ad => {
                                setAdherente({
                                    loading: false,
                                    error: null,
                                    data: resp_ad.data
                                });
                                setSubmitting(false);
                                reiniciarForm();
                            })
                            .catch(function (error) {
                                setMensajeAlert('Error al obtener listado de adherentes');
                                setTipoMensaje('error');
                                setSubmitting(false);
                                setOpen(true);
                                setAdherente({ loading: false, error: true, data: null });
                                reiniciarForm();
                            });
                    })
                    .catch(function (error) {
                        setMensajeAlert('Error al guardar los datos del Adherente');
                        setTipoMensaje('error');
                        setSubmitting(false);
                        setOpen(true);
                    });
            }
        },
        onReset: () => { },
    });

    const [edad, setEdad] = useState();

    useEffect(
        () => {
            const fechaNac = formik.values.fechaDeNacimiento.split('-')
            if (fechaNac.length >= 2) {
                api.calcularAntiguedad(fechaNac)
                    .then(resultado => {
                        const dias = resultado.data
                        if (parseInt(dias) >= 365) {
                            const anios = Math.floor(dias / 365)
                            const anios_ = anios > 0 ? anios + " años " : ''
                            const dias_ = dias - (anios * 365)
                            const meses = (dias_ >= 30) ? Math.floor(dias_ / 30) + " meses " : ''
                            setEdad(anios_ + meses)
                        } else {
                            const anios = Math.floor(dias / 365)
                            const dias_ = dias - (anios * 365)
                            const meses = (dias_ >= 30) ? Math.floor(dias_ / 30) + " meses " : ''
                            setEdad(meses)
                        }
                    });
            }
        },
        [formik.values.fechaDeNacimiento],
    );

    const reiniciarForm = () => {
        formik.resetForm();
        setNuevo(true);
        setEdad('');
        setAdherenteSeleccionado({
            id_persona: 0,
            vigente: 0
        });
        setAdherenteBajaDefinitva({});
        setCheckOsfatlyf(false);
        setCheckDiscapacidad(false);
    };
    const deleteAdherete = () => {
        setEliminando(true);
        api.putDeleteAdherete(formik.values)
            .then(respuesta => {
                setMensajeAlert('El Adherente se eliminó con éxito');
                setTipoMensaje('success');
                setOpen(true);
                setAdherente({ loading: true, error: null, data: null });
                api.getAfiliadoAdherentesAll(id)
                    .then(resp_ad => {
                        setAdherente({
                            loading: false,
                            error: null,
                            data: resp_ad.data
                        });
                        setEliminando(false);
                        reiniciarForm();
                    })
                    .catch(function (error) {
                        setMensajeAlert('Error al obtener listado de adherentes');
                        setTipoMensaje('error');
                        setEliminando(false);
                        setOpen(true);
                        setAdherente({ loading: false, error: true, data: null });
                        reiniciarForm();
                    });
            })
            .catch(error => {
                setMensajeAlert('Error al eliminar el Adherente');
                setTipoMensaje('error');
                setEliminando(false);
                setOpen(true);
            })
    };
    const undeleteAdherete = () => {
        setEliminando(true);
        api.putUndeleteAdherete(formik.values)
            .then(respuesta => {
                setMensajeAlert('El Adherente fue restaurado con éxito');
                setTipoMensaje('success');
                setOpen(true);
                setAdherente({ loading: true, error: null, data: null });
                api.getAfiliadoAdherentesAll(id)
                    .then(resp_ad => {
                        setAdherente({
                            loading: false,
                            error: null,
                            data: resp_ad.data
                        });
                        setEliminando(false);
                        reiniciarForm();
                    })
                    .catch(function (error) {
                        setMensajeAlert('Error al obtener listado de adherentes');
                        setTipoMensaje('error');
                        setEliminando(false);
                        setOpen(true);
                        setAdherente({ loading: false, error: true, data: null });
                        reiniciarForm();
                    });
            })
            .catch(error => {
                setMensajeAlert('Error al restaurar el Adherente');
                setTipoMensaje('error');
                setEliminando(false);
                setOpen(true);
            })
    };
    const recargarAdherentes = () => {
        setAdherente({ loading: true, error: null, data: null });
        api.getAfiliadoAdherentesAll(id)
            .then(resp_ad => {
                setAdherente({
                    loading: false,
                    error: null,
                    data: resp_ad.data
                });
            })
            .catch(function (error) {
                setMensajeAlert('Error al obtener listado de adherentes');
                setTipoMensaje('error');
                setOpen(true);
                setAdherente({ loading: false, error: true, data: null });
            });
    }

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [open, setOpen] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSubmitting(false);
        setOpen(false);
    };

    const [afiliado, setAfiliado] = useState({
        data: {
            id_afiliado: id,
            nombre_afiliado: '',
            dni_afiliado: '',
            obra_social_afiliado: '',
            empresa_afiliado: '',
        },
        loading: true,
        error: null
    });
    const [adherente, setAdherente] = useState({ data: null, loading: true, error: null });
    useEffect(() => {
        api.getAfiliadoVW({ 'id': id })
            .then(respuesta => {
                setAfiliado({
                    loading: false,
                    data: {
                        id_afiliado: respuesta.data.id_afiliado,
                        nombre_afiliado: respuesta.data.apellido + ', ' + respuesta.data.nombre,
                        dni_afiliado: respuesta.data.dni,
                        obra_social_afiliado: respuesta.data.obra_social,
                        empresa_afiliado: respuesta.data.empresa,
                    },
                    error: null
                });
                api.getAfiliadoAdherentesAll(id)
                    .then(resp_ad => {
                        setAdherente({
                            loading: false,
                            error: null,
                            data: resp_ad.data
                        });
                    })
                    .catch(error => {
                        setMensajeAlert('Error al cargar los datos de los adherentes');
                        setTipoMensaje('error');
                        setSubmitting(false);
                        setOpen(true);
                        setAdherente({ loading: false, error: true, data: null });
                    });
            })
            .catch(error => {
                setMensajeAlert('Error al cargar los datos del afiliado');
                setTipoMensaje('error');
                setSubmitting(false);
                setOpen(true);
            });
    }, [id]);
    const { nombre_afiliado, dni_afiliado, obra_social_afiliado, empresa_afiliado } = afiliado.data ?? {
        nombre_afiliado: '',
        dni_afiliado: '',
        obra_social_afiliado: '',
        empresa_afiliado: '',
    };

    const listadoRelaciones = useFetch('relaciones');

    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Afiliado", ruta: "/afiliado" },
        { nombre: "Adherente", ruta: "/adherente" },
    ];

    const parentezco = async (relacion) => {
        const devolver = await relacion ?
            listadoRelaciones.data.find(e => e.descripcion === relacion)
            : { id: '' };
        return devolver.id
    }

    const generoStringToInt = (genero) => {
        switch (genero) {
            case "Femenino": return 2;
            case "Masculino": return 1;
            case "X": return 3;
            default: return "";
        }
    }

    const seleccionado = async (resultado) => {
        const cuil = resultado.cuil ? resultado.cuil.split('-') : ''
        await formik.setFieldValue('apellido', resultado.apellido ?? '');
        await formik.setFieldValue('nombre', resultado.nombre ?? '');
        await formik.setFieldValue('dni', resultado.dni ?? '');

        await formik.setFieldValue('prefijo', cuil[0]);
        await formik.setFieldValue('verificador', cuil[2]);

        const nacimiento = resultado.fecha_nacimiento ? resultado.fecha_nacimiento.split('/') : '';
        if (nacimiento) {
            await formik.setFieldValue('fechaDeNacimiento', nacimiento[2] + '-' + nacimiento[1] + '-' + nacimiento[0]);
        } else {
            await formik.setFieldValue('fechaDeNacimiento', '');
        }

        await formik.setFieldValue('id_afiliado', resultado.id_afiliado);
        await formik.setFieldValue('id_persona', resultado.id_persona);
        await formik.setFieldValue('relacion', await parentezco(resultado.relacion));

        await formik.setFieldValue('pre_nro', resultado.pre_nro ?? '');
        await formik.setFieldValue('numero', resultado.numero ?? '');
        await formik.setFieldValue('post_nro', resultado.post_nro ?? '');
        await formik.setFieldValue('extra_nro', resultado.extra_nro ?? '');

        await formik.setFieldValue('genero', generoStringToInt(resultado.genero));

        if (parseInt(resultado.osfatlyf) === 1) {
            await formik.setFieldValue('osfatlyf', true);
            setCheckOsfatlyf(true);
        } else {
            await formik.setFieldValue('osfatlyf', false);
            setCheckOsfatlyf(false);
        }
        if (parseInt(resultado.discapacidad) === 1) {
            await formik.setFieldValue('discapacidad', true);
            setCheckDiscapacidad(true);
        } else {
            await formik.setFieldValue('discapacidad', false);
            setCheckDiscapacidad(false);
        }

        setNuevo(false);
        setAdherenteSeleccionado({
            id_adherente: resultado.id_adherente,
            id_persona: resultado.id_persona,
            vigente: resultado.vigente
        });
    }

    const toggleModalBajaDefinitiva = (adherente) => {
        setAdherenteBajaDefinitva(adherente);
        setAbrirModalBajaDefinitiva(!abrirModalBajaDefinitiva);
    };
    const toggleModalCerrarBajaDefinitiva = (resultado) => {
        if (resultado.error === 'success' && adherenteBajaDefinitiva.id_adherente === adherenteSeleccionado.id_adherente) {
            reiniciarForm();
        }
        setMensajeAlert(resultado.mensaje);
        setTipoMensaje(resultado.error);
        setAbrirModalBajaDefinitiva(!abrirModalBajaDefinitiva);
        setOpen(true);
        recargarAdherentes();
    }

    const pageTitle = "Adherente/s de Afiliado";

    if (afiliado.loading) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Alert severity="info">
                    Cargando...
                </Alert>
            </Page>
        );
    } else if (!afiliado.loading && afiliado.error) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Alert severity="error">
                    No se ha encontrado ningún afiliado.
                </Alert>
                <br />
                <Grid item xs={12} md={12}>
                    <Link style={{ textDecoration: 'none' }} to={'/inicio'}>
                        <Button variant="contained" underline="none" endIcon={<CloseIcon />}>
                            Volver
                        </Button>
                    </Link>
                </Grid>
            </Page>
        );
    } else if (!afiliado.loading && afiliado.data) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}>
                    <Alert onClose={handleClose} severity={tipoMensaje} sx={{
                        width: '100%'
                    }}>
                        {mensajeAlert}
                    </Alert>
                </Snackbar>
                <Container>
                    <p><b>Nombre:</b> {nombre_afiliado}&nbsp;&nbsp;&nbsp;&nbsp;<b>Documento:</b> {dni_afiliado}</p>
                    <p><b>Empleado en:</b> {empresa_afiliado}&nbsp;&nbsp;&nbsp;&nbsp;<b>Obra Social:</b> {obra_social_afiliado}</p>
                </Container>
                <Divider />
                <br />
                <form
                    id="formAdherente"
                    name="formAdherente"
                    onSubmit={formik.handleSubmit}
                    onReset={formik.handleReset}
                    autoComplete="off"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3}>
                            <FormControl sx={{ width: '20%' }}>
                                <TextField
                                    autoComplete="off"
                                    id="prefijo"
                                    name="prefijo"
                                    value={formik.values.prefijo}
                                    type="text"
                                    inputProps={{ maxLength: 2 }}
                                    onChange={formik.handleChange}
                                    error={formik.touched.prefijo && Boolean(formik.errors.prefijo)}
                                    helperText={formik.touched.prefijo && formik.errors.prefijo}
                                />
                            </FormControl>

                            <FormControl sx={{ width: '60%' }}>
                                <TextField
                                    autoComplete="off"
                                    id="dni"
                                    label="CUIL"
                                    name="dni"
                                    value={formik.values.dni}
                                    type="number"
                                    inputProps={{ maxLength: 8, min: 0 }}
                                    onChange={formik.handleChange}
                                    error={formik.touched.dni && Boolean(formik.errors.dni)}
                                    helperText={formik.touched.dni && formik.errors.dni}
                                />
                            </FormControl>

                            <FormControl sx={{ width: '20%' }}>
                                <TextField
                                    autoComplete="off"
                                    id="verificador"
                                    name="verificador"
                                    value={formik.values.verificador}
                                    type="number"
                                    InputProps={{ maxLength: 1 }}
                                    onChange={formik.handleChange}
                                    error={formik.touched.verificador && Boolean(formik.errors.verificador)}
                                    helperText={formik.touched.verificador && formik.errors.verificador}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    error={formik.touched.apellido && Boolean(formik.errors.apellido)}
                                    helperText={formik.touched.apellido && formik.errors.apellido}
                                    name="apellido"
                                    placeholder="Apellido"
                                    label="Apellido"
                                    type="text"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    value={formik.values.apellido}
                                    onChange={(e) => Utils.toUpperCaseHandler(e, formik)}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                                    helperText={formik.touched.nombre && formik.errors.nombre}
                                    name="nombre"
                                    placeholder="Nombre"
                                    label="Nombre"
                                    type="text"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    value={formik.values.nombre}
                                    variant="outlined"
                                    onChange={(e) => Utils.toUpperCaseHandler(e, formik)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br />

                    <Grid container spacing={2}>
                        <Grid item xs={8} md={2.5} >
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    error={formik.touched.fechaDeNacimiento && Boolean(formik.errors.fechaDeNacimiento)}
                                    helperText={formik.touched.fechaDeNacimiento && formik.errors.fechaDeNacimiento}
                                    id="standard-adornment-fechaDeNacimiento"
                                    label="Fecha de Nacimiento"
                                    name="fechaDeNacimiento"
                                    value={formik.values.fechaDeNacimiento}
                                    onChange={formik.handleChange}
                                    type="date"
                                    focused
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={2} md={2.5}>
                            <p>{edad}</p>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <FormControl fullWidth variant='outlined'>
                                <InputLabel htmlFor="standard-adornment-relacion">Parentezco</InputLabel>
                                <Select
                                    autoComplete="off"
                                    error={formik.touched.relacion && Boolean(formik.errors.relacion)}
                                    id="standard-adornment-relacion"
                                    label="Parentezco"
                                    name="relacion"
                                    value={formik.values.relacion}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value="">
                                        <em>Dejar sin dato</em>
                                    </MenuItem>
                                    {listadoRelaciones.loading ?
                                        <MenuItem>Cargando...</MenuItem>
                                        : listadoRelaciones.error ?
                                            <MenuItem className={classes.error} disabled="true">No se pudo cargar los datos.</MenuItem>
                                            : listadoRelaciones.data && listadoRelaciones.data.map((relacion) => <MenuItem value={relacion.id} key={relacion.id}>{relacion.descripcion}</MenuItem>)
                                    }
                                </Select>
                                {formik.errors.relacion && formik.touched.relacion ? (<FormHelperText error>Requerido</FormHelperText>) : null}
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="genero">Genero</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="genero"
                                    label="Genero"
                                    name="genero"
                                    value={formik.values.genero}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value=''>
                                        <em>Dejar sin dato</em>
                                    </MenuItem>
                                    <MenuItem value='2' key={2}>Femenino</MenuItem>
                                    <MenuItem value='1' key={1}>Masculino</MenuItem>
                                    <MenuItem value='3' key={1}>X</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br />

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                            <FormControl sx={{ width: '20%' }}>
                                <TextField
                                    autoComplete="off"
                                    id="pre_nro"
                                    name="pre_nro"
                                    value={formik.values.pre_nro}
                                    type="text"
                                    onChange={formik.handleChange}
                                />
                            </FormControl>

                            <FormControl sx={{ width: '40%' }}>
                                <TextField
                                    autoComplete="off"
                                    id="numero"
                                    label="Número Obra Social"
                                    name="numero"
                                    value={formik.values.numero}
                                    type="text"
                                    onChange={formik.handleChange}
                                />
                            </FormControl>

                            <FormControl sx={{ width: '15%' }}>
                                <TextField
                                    autoComplete="off"
                                    id="post_nro"
                                    name="post_nro"
                                    value={formik.values.post_nro}
                                    type="text"
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                            <FormControl sx={{ width: '20%' }}>
                                <TextField
                                    autoComplete="off"
                                    id="extra_nro"
                                    name="extra_nro"
                                    value={formik.values.extra_nro}
                                    type="text"
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            OSFATLYF
                            <Checkbox
                                name='osfatlyf'
                                checked={checkOsfatlyf}
                                value={formik.values.osfatlyf}
                                onChange={e => { formik.handleChange(e); actuCheckOsfatlyf(e); }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            Cert. Discapacidad
                            <Checkbox
                                name='discapacidad'
                                value={formik.values.discapacidad}
                                onChange={e => { formik.handleChange(e); actuCheckDiscapacidad(e); }}
                                checked={checkDiscapacidad}
                            />
                        </Grid>
                    </Grid>
                </form>

                <br />
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <Button fullWidth
                            disabled={eliminando || submitting}
                            variant="contained"
                            color="primary" p={3}
                            onClick={formik.submitForm}
                            endIcon={<SaveIcon />} size="medium">
                            {submitting ?
                                "Guardando..."
                                : nuevo ? "Guardar nuevo" : "Modificar"}
                        </Button>
                    </Grid>

                    {
                        !nuevo &&
                        <Grid item xs={6} md={3}>
                            <Button
                                disabled={eliminando || submitting}
                                fullWidth
                                xs={6} md={3}
                                variant="secondary"
                                color="secondary"
                                onClick={reiniciarForm}
                                endIcon={<CloseIcon />}>
                                Cancelar edición
                            </Button>
                        </Grid>
                    }

                    <Grid item xs={6} md={2}>
                        {!nuevo && (
                            adherenteSeleccionado.vigente ?
                                <Button
                                    fullWidth
                                    xs={6} md={2}
                                    color="error"
                                    disabled={eliminando || submitting}
                                    onClick={deleteAdherete}
                                    endIcon={<DeleteIcon />}>
                                    Borrar
                                </Button>
                                : <Button
                                    fullWidth
                                    xs={6} md={2}
                                    color="warning"
                                    disabled={eliminando || submitting}
                                    onClick={undeleteAdherete}
                                    endIcon={<RestoreFromTrashIcon />}>
                                    Restaurar
                                </Button>
                        )
                        }
                    </Grid>

                    {location.search ?
                        <Grid item xs={6} md={2}>
                            <Link style={{ textDecoration: 'none' }} to={`/search${location.search}`}>
                                <Button variant="outlined" underline="none" endIcon={<ArrowBackIcon />}>
                                    Volver
                                </Button>
                            </Link>
                        </Grid>
                        :
                        <Grid item xs={6} md={2}>
                            <Link style={{ textDecoration: 'none' }} to={`/search?q=${dni_afiliado}`}>
                                <Button variant="outlined" underline="none" endIcon={<CloseIcon />}>
                                    Terminar
                                </Button>
                            </Link>
                        </Grid>
                    }
                    <AdherenteListado
                        adherentes={adherente}
                        edicion={true}
                        cerrar={seleccionado}
                        adherenteSeleccionado={adherenteSeleccionado}
                        recargar={recargarAdherentes}
                        onAdherenteBajaDefinitiva={toggleModalBajaDefinitiva}
                    />
                    <AdherenteBajaDefinitiva
                        ver={abrirModalBajaDefinitiva}
                        adherente={adherenteBajaDefinitiva}
                        cerrar={toggleModalCerrarBajaDefinitiva} />
                </Grid>
            </Page >
        )
    }
}