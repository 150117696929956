import React, {
    useRef, useEffect
} from 'react';
import {
    Table, TableBody,
    TableCell, TableContainer, TableHead, Tooltip,
    TableRow, Button, Alert, Grid
} from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { makeStyles } from '@mui/styles';
import DataTables from "datatables.net-dt";
import 'datatables.net-dt/css/dataTables.dataTables.css';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        width: '100% !important',
    },
    root: {
        flexGrow: 1,
    },
    cabeceraTabla: {
        backgroundColor: 'darkgrey',
        color: 'white'
    },
    error: {
        backgroundColor: '#EFAFC0',
        '&:hover': {
            backgroundColor: '#DBA1B0 !important',
        },
    },
});

export const TableAfiliados = React.memo(({
    afiliados,
    toggleModalInformacion,
}) => {
    const classes = useStyles();

    const tableRef = useRef();

    useEffect(() => {
        const dt = new DataTables(tableRef.current, {
            order: [[1, 'asc']],
            columnDefs: [{
                targets: '_all',
                className: 'dt-body-center dt-head-center'
            }]
        });
        return () => {
            dt.destroy();
        };
    }, [afiliados]);

    if (afiliados.loading)
        return (
            <Grid item xs={12} >
                <Alert severity="info">
                    Cargando...
                </Alert>
            </Grid>
        );
    else if (!afiliados.loading && !afiliados.error && afiliados.data && afiliados.data.length === 0) {
        return (
            <Grid item xs={12} >
                <Alert severity="error">
                    No se ha encontrado ningún afiliado con los datos especificados.
                </Alert>
            </Grid>
        );
    } else {
        return (
            <Grid item xs={12} >
                <TableContainer>
                    <Table ref={tableRef} className={`${classes.table} stripe compact`} size="small" aria-label="a dense table">
                        <TableHead className={classes.cabeceraTabla}>
                            <TableRow>
                                <TableCell align="center">Dni</TableCell>
                                <TableCell align="center">Nombre</TableCell>
                                <TableCell align="center">Provincia</TableCell>
                                <TableCell align="center">Departamento</TableCell>
                                <TableCell align="center">Localidad</TableCell>
                                <TableCell align="center">Empresa</TableCell>
                                <TableCell align="center">Obra Social</TableCell>
                                <TableCell align="center">Fecha Jubilación</TableCell>
                                <TableCell align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {afiliados.data && afiliados.data.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="center" component="th" scope="row">{row.dni}</TableCell>
                                    <TableCell align="center">{row.apellido + ', ' + row.nombre}</TableCell>
                                    <TableCell align="center">{row.provincia}</TableCell>
                                    <TableCell align="center">{row.departamento}</TableCell>
                                    <TableCell align="center">{row.localidad}</TableCell>
                                    <TableCell align="center">{row.empresa}</TableCell>
                                    <TableCell align="center">{row.obra}</TableCell>
                                    <TableCell align="center" data-order={row.jubilacion_iso}>{row.jubilacion}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Información General">
                                            <Button
                                                color="primary"
                                                size="large"
                                                onClick={() => toggleModalInformacion(row.id)}
                                                endIcon={<VisibilityOutlinedIcon />}
                                            >
                                            </Button>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        );
    }
});


