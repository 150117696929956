import React, { useEffect, useState } from 'react'
import { Button, FormControl, InputLabel, Grid, TextField, Stack } from '@mui/material';
import { Snackbar } from '@mui/material'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Page } from '../ui/Page';
import MuiAlert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import api from '../../service/api';
import { useHistory } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Utils from '../../commons/Utils.js'

const useStyles = makeStyles({
    error: {
        backgroundColor: '#EFAFC0',
        '&:hover': {
            backgroundColor: '#DBA1B0 !important',
        },
    },
});

export const AfiliadoEdicion = ({ location }) => {
    const { id } = useParams();
    const classes = useStyles();

    const [afiliado, setAfiliado] = useState({
        loading: true,
        data: {
            aux_persona_id: '',
            aux_afiliado_id: id,
            aux_apellido: '',
            aux_nombre: '',
            aux_prefijo: '',
            aux_dni: '',
            aux_verificador: '',
            aux_nacionalidad: '',
            aux_id_lugarDeNacimiento: '',
            aux_id_departamentoDeNacimiento: '',
            aux_id_provinciaDeNacimiento: '',
            aux_fechaDeNacimiento: '',
            aux_grupoSanguineo: '',
            aux_domicilioActual: '',
            aux_id_localidadActual: '',
            aux_id_provinciaActual: '',
            aux_id_departamentoActual: '',
            aux_telefonoActual: '',
            aux_celularActual: '',
            aux_caracteristica_c: '',
            aux_celularActual1: '',
            aux_caracteristica_c1: '',
            aux_caracteristica_t: '',
            aux_email: '',
            aux_estCivil: '',
            aux_chekeado: '',
            aux_genero: '',
        },
        error: null
    });
    const {
        aux_persona_id,
        aux_afiliado_id,
        aux_apellido,
        aux_nombre,
        aux_prefijo,
        aux_dni,
        aux_verificador,
        aux_nacionalidad,
        aux_id_lugarDeNacimiento,
        aux_id_departamentoDeNacimiento,
        aux_id_provinciaDeNacimiento,
        aux_fechaDeNacimiento,
        aux_grupoSanguineo,
        aux_domicilioActual,
        aux_id_localidadActual,
        aux_id_provinciaActual,
        aux_id_departamentoActual,
        aux_telefonoActual,
        aux_celularActual,
        aux_email,
        aux_estCivil,
        aux_chekeado,
        aux_genero,
        aux_caracteristica_c,
        aux_caracteristica_t,
        aux_caracteristica_c1,
        aux_celularActual1
    } = afiliado.data ?? {
        aux_persona_id: '',
        aux_afiliado_id: 0,
        aux_apellido: '',
        aux_nombre: '',
        aux_prefijo: '',
        aux_dni: '',
        aux_verificador: '',
        aux_nacionalidad: '',
        aux_id_lugarDeNacimiento: '',
        aux_id_departamentoDeNacimiento: '',
        aux_id_provinciaDeNacimiento: '',
        aux_fechaDeNacimiento: '',
        aux_grupoSanguineo: '',
        aux_domicilioActual: '',
        aux_id_localidadActual: '',
        aux_id_provinciaActual: '',
        aux_id_departamentoActual: '',
        aux_telefonoActual: '',
        aux_celularActual: '',
        aux_email: '',
        aux_estCivil: '',
        aux_chekeado: '',
        aux_genero: '',
        aux_caracteristica_c: '',
        aux_caracteristica_t: '',
        aux_caracteristica_c1: '',
        aux_celularActual1: ''
    };

    useEffect(() => {
        api.cargarParaEditarAfiliadoPersonaPorId({ id_afiliado: id })
            .then(respuesta => {
                let pre_aux;
                let dni_aux;
                let ver_aux;
                if (respuesta.data.afiliado.cuil.length > 8) {
                    if (!respuesta.data.afiliado.cuil.includes('-')) {
                        pre_aux = respuesta.data.afiliado.cuil.slice(0, 2);
                        dni_aux = respuesta.data.afiliado.cuil.slice(2, (respuesta.data.afiliado.cuil.length - 1));
                        ver_aux = respuesta.data.afiliado.cuil.slice(-1);
                    } else {
                        pre_aux = respuesta.data.afiliado.cuil.split('-')[0];
                        dni_aux = respuesta.data.afiliado.dni;
                        ver_aux = respuesta.data.afiliado.cuil.split('-')[2];
                    }
                } else {
                    dni_aux = respuesta.data.afiliado.dni;
                }
                setListadoProvincias({
                    loading: false,
                    data: respuesta.data.provincias,
                    error: null
                });
                setListadoDepartamentos({
                    loading: false,
                    data: respuesta.data.departamentos_nac,
                    error: null
                });
                setListadoDepartamentosActual({
                    loading: false,
                    data: respuesta.data.departamentos,
                    error: null
                });
                setListadoLocalidades({
                    loading: false,
                    data: respuesta.data.localidades_nac,
                    error: null
                });
                setListadoLocalidadesActual({
                    loading: false,
                    data: respuesta.data.localidades,
                    error: null
                });
                setListadoEstadoCivil({
                    loading: false,
                    data: respuesta.data.estados_civiles,
                    error: null
                });
                setListadoGruposSanguineos({
                    loading: false,
                    data: respuesta.data.grupos_sanguineos,
                    error: null
                });
                setAfiliado({
                    loading: false,
                    data: {
                        aux_persona_id: respuesta.data.afiliado.id_persona,
                        aux_afiliado_id: respuesta.data.afiliado.id_afiliado,
                        aux_apellido: respuesta.data.afiliado.apellido,
                        aux_nombre: respuesta.data.afiliado.nombre,
                        aux_prefijo: pre_aux,
                        aux_dni: dni_aux,
                        aux_verificador: ver_aux,
                        aux_nacionalidad: respuesta.data.afiliado.nacionalidad,
                        aux_id_lugarDeNacimiento: respuesta.data.afiliado.id_localidad_nac ?? '',
                        aux_id_departamentoDeNacimiento: respuesta.data.afiliado.id_departamento_nac ?? '',
                        aux_id_provinciaDeNacimiento: respuesta.data.afiliado.id_provincia_nac ?? '',
                        aux_fechaDeNacimiento: respuesta.data.afiliado.fecha_nacimiento,
                        aux_grupoSanguineo: respuesta.data.afiliado.id_grupo_sanguineo ?? '',
                        aux_domicilioActual: respuesta.data.afiliado.domicilio,
                        aux_id_localidadActual: respuesta.data.afiliado.id_localidad ?? '',
                        aux_id_provinciaActual: respuesta.data.afiliado.id_provincia ?? '',
                        aux_id_departamentoActual: respuesta.data.afiliado.id_departamento ?? '',
                        aux_telefonoActual: respuesta.data.afiliado.telefono,
                        aux_celularActual: respuesta.data.afiliado.celular,
                        aux_celularActual1: respuesta.data.afiliado.celular1,
                        aux_caracteristica_c: respuesta.data.afiliado.caracteristica_c,
                        aux_caracteristica_c1: respuesta.data.afiliado.caracteristica_c1,
                        aux_caracteristica_t: respuesta.data.afiliado.caracteristica_t,
                        aux_email: respuesta.data.afiliado.email,
                        aux_estCivil: respuesta.data.afiliado.id_estado_civil ?? '',
                        aux_genero: respuesta.data.afiliado.genero ?? '',
                        aux_chekeado: respuesta.data.afiliado.chekeado
                    },
                    error: null
                });
            })
            .catch((error) => {
                setAfiliado({
                    loading: false,
                    data: null,
                    error: true
                });
                setListadoProvincias({ loading: false, error: true, data: null });
                setListadoDepartamentos({ loading: false, error: true, data: null });
                setListadoDepartamentosActual({ loading: false, error: true, data: null });
                setListadoLocalidades({ loading: false, error: true, data: null });
                setListadoLocalidadesActual({ loading: false, error: true, data: null });
                setListadoEstadoCivil({ loading: false, error: true, data: null });
                setListadoGruposSanguineos({ loading: false, error: true, data: null });
            });
    }, [id]);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [open, setOpen] = React.useState({ open: false, status: 0 });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        if (open.status === 201)
            history.replace(`/search${location.search}`);
    };

    const history = useHistory();

    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Afiliado", ruta: `/search${location.search}` },
    ];

    const [submitting, setSubmitting] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState("");
    const [tipoMensaje, setTipoMensaje] = useState("");

    var validationSchema = Yup.object().shape({
    });

    const formik = useFormik({
        initialValues: {
            id_persona: aux_persona_id,
            id_afiliado: aux_afiliado_id,
            apellido: aux_apellido,
            nombre: aux_nombre,
            prefijo: aux_prefijo,
            dni: aux_dni,
            verificador: aux_verificador,
            nacionalidad: aux_nacionalidad,
            lugarDeNacimiento: aux_id_lugarDeNacimiento,
            departamentoDeNacimiento: aux_id_departamentoDeNacimiento,
            provinciaDeNacimiento: aux_id_provinciaDeNacimiento,
            fechaDeNacimiento: aux_fechaDeNacimiento,
            grupoSanguineo: aux_grupoSanguineo,
            domicilioActual: aux_domicilioActual,
            localidadActual: aux_id_localidadActual,
            provinciaActual: aux_id_provinciaActual,
            departamentoActual: aux_id_departamentoActual,
            telefonoActual: aux_telefonoActual,
            celularActual: aux_celularActual,
            celularActual1: aux_celularActual1,
            caracteristica_c: aux_caracteristica_c,
            caracteristica_c1: aux_caracteristica_c1,
            caracteristica_t: aux_caracteristica_t,
            email: aux_email,
            estCivil: aux_estCivil,
            chekeado: aux_chekeado,
            genero: aux_genero,
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitting(true);
            api.actualizarPersona(values)
                .then(respuesta => {
                    setMensajeAlert('¡El afiliado se almacenó exitosamente!');
                    setTipoMensaje('success');
                    setOpen({ open: true, status: respuesta.status });
                })
                .catch(function (error) {
                    setMensajeAlert('Error al guardar los datos del afiliado');
                    setTipoMensaje('error');
                    setSubmitting(false);
                    setOpen({ open: true, status: 422 });
                })
        },
        onReset: () => { },
    });

    const cancelar = () => {
        formik.handleReset();
        if (location.search)
            history.replace('/search'.concat(location.search));
        else
            history.replace('/inicio');
    };

    const [listadoEstadoCivil, setListadoEstadoCivil] = useState({ loading: true, data: [], error: null });
    const [listadoGruposSanguineos, setListadoGruposSanguineos] = useState({ loading: true, data: [], error: null });

    const [listadoProvincias, setListadoProvincias] = useState({ loading: true, data: [], error: null });

    const [listadoDepartamentos, setListadoDepartamentos] = useState({ loading: true, data: [], error: null });
    const [listadoLocalidades, setListadoLocalidades] = useState({ loading: true, data: [], error: null });

    const [listadoDepartamentosActual, setListadoDepartamentosActual] = useState({ loading: true, data: [], error: null });
    const [listadoLocalidadesActual, setListadoLocalidadesActual] = useState({ loading: true, data: [], error: null });

    const provinciaDeNacimientoChanged = (e) => {
        formik.handleChange(e);
        formik.setFieldValue('departamentoDeNacimiento', '');
        formik.setFieldValue('lugarDeNacimiento', '');
        setListadoDepartamentos({ loading: true });
        api.getDepartamentos(e.target.value)
            .then((resp) => {
                setListadoDepartamentos({
                    loading: false,
                    data: resp.data,
                    error: null
                });
                setListadoLocalidades({ loading: false, data: null, error: null });
            })
            .catch((error) => {
                setListadoDepartamentos({
                    loading: false,
                    data: null,
                    error: true
                });
                setListadoLocalidades({ loading: false, data: null, error: null });
            });
    };

    const provinciaActualChanged = (e) => {
        formik.handleChange(e);
        formik.setFieldValue('departamentoActual', '');
        formik.setFieldValue('localidadActual', '');
        setListadoDepartamentosActual({ loading: true });
        api.getDepartamentos(e.target.value)
            .then((resp) => {
                setListadoDepartamentosActual({
                    loading: false,
                    data: resp.data,
                    error: null
                });
                setListadoLocalidadesActual({ loading: false, data: null, error: null });
            })
            .catch((error) => {
                setListadoDepartamentosActual({
                    loading: false,
                    data: null,
                    error: true
                });
                setListadoLocalidadesActual({ loading: false, data: null, error: null });
            });
    };

    const departamentoDeNacimientoChanged = (e) => {
        formik.handleChange(e);
        formik.setFieldValue('lugarDeNacimiento', '');
        setListadoLocalidades({ loading: true });
        api.getLocalidades(e.target.value)
            .then((resp) => {
                setListadoLocalidades({
                    loading: false,
                    data: resp.data,
                    error: null
                });
            })
            .catch((error) => {
                setListadoLocalidades({
                    loading: false,
                    data: null,
                    error: true
                });
            });
    };

    const departamentoActualChanged = (e) => {
        formik.handleChange(e);
        formik.setFieldValue('localidadActual', '');
        setListadoLocalidadesActual({ loading: true });
        api.getLocalidades(e.target.value)
            .then((resp) => {
                setListadoLocalidadesActual({
                    loading: false,
                    data: resp.data,
                    error: null
                });
            })
            .catch((error) => {
                setListadoLocalidadesActual({
                    loading: false,
                    data: null,
                    error: true
                });
            });
    };

    const pageTitle = "Editar datos Afiliado";

    if (afiliado.loading) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Alert severity="info">
                    Cargando...
                </Alert>
            </Page>
        );
    } else if (!afiliado.loading && afiliado.error) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Alert severity="error">
                    No se ha encontrado ningún afiliado.
                </Alert>
                <br />
                <Grid item xs={12} md={12}>
                    {location.search ?
                        <Link style={{ textDecoration: 'none' }} to={`/search${location.search}`}>
                            <Button variant="contained" underline="none" endIcon={<CloseIcon />}>
                                Volver
                            </Button>
                        </Link>
                        : <Link style={{ textDecoration: 'none' }} to={'/inicio'}>
                            <Button variant="contained" underline="none" endIcon={<CloseIcon />}>
                                Volver
                            </Button>
                        </Link>
                    }
                </Grid>
            </Page>
        );
    } else if (!afiliado.loading && afiliado.data) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Snackbar open={open.open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}>
                    <Alert onClose={handleClose} severity={tipoMensaje} sx={{
                        width: '100%'
                    }}>
                        {mensajeAlert}
                    </Alert>
                </Snackbar>

                <form
                    id="formAfiliado"
                    name="formAfiliado"
                    onSubmit={formik.handleSubmit}
                    onReset={formik.handleReset}
                    autoComplete="off"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControl sx={{ width: '25%' }}>
                                <TextField
                                    autoComplete="off"
                                    id="prefijo"
                                    name="prefijo"
                                    value={formik.values.prefijo}
                                    type="number"
                                    inputProps={{ maxLength: 2, min: 0, max: 99 }}
                                    onChange={formik.handleChange}
                                    error={formik.touched.prefijo && Boolean(formik.errors.prefijo)}
                                    helperText={formik.touched.prefijo && formik.errors.prefijo}
                                />
                            </FormControl>

                            <FormControl sx={{ width: '55%' }}>
                                <TextField
                                    autoComplete="off"
                                    id="dni"
                                    label="CUIL"
                                    name="dni"
                                    value={formik.values.dni}
                                    type="number"
                                    inputProps={{ maxLength: 8, min: 0 }}
                                    onChange={formik.handleChange}
                                    error={formik.touched.dni && Boolean(formik.errors.dni)}
                                    helperText={formik.touched.dni && formik.errors.dni}
                                />
                            </FormControl>

                            <FormControl sx={{ width: '20%' }}>
                                <TextField
                                    autoComplete="off"
                                    id="verificador"
                                    name="verificador"
                                    value={formik.values.verificador}
                                    type="number"
                                    inputProps={{ maxLength: 1, min: 0, max: 9 }}
                                    onChange={formik.handleChange}
                                    error={formik.touched.verificador && Boolean(formik.errors.verificador)}
                                    helperText={formik.touched.verificador && formik.errors.verificador}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}></Grid>

                        <Grid item xs={4} >
                            <FormControl fullWidth>
                                <TextField
                                    variant="outlined"
                                    autoComplete="off"
                                    name="apellido"
                                    placeholder="Apellido"
                                    label="Apellido"
                                    type="text"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    value={formik.values.apellido}
                                    onChange={(e) => Utils.toUpperCaseHandler(e, formik)}
                                    error={formik.touched.apellido && Boolean(formik.errors.apellido)}
                                    helperText={formik.touched.apellido && formik.errors.apellido}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} >
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    name="nombre"
                                    placeholder="Nombre"
                                    label="Nombre"
                                    type="text"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    value={formik.values.nombre}
                                    onChange={(e) => Utils.toUpperCaseHandler(e, formik)}
                                    error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                                    helperText={formik.touched.nombre && formik.errors.nombre}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}></Grid>

                        <Grid item xs={10} md={8}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    id="nacionalidad"
                                    label="Nacionalidad"
                                    name="nacionalidad"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    value={formik.values.nacionalidad}
                                    onChange={(e) => Utils.toUpperCaseHandler(e, formik)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} md={4}></Grid>

                        <Grid item xs={4}>
                            <FormControl fullWidth variant='outlined'>
                                <InputLabel htmlFor="provinciaDeNacimiento">Provincia De Nacimiento</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="provinciaDeNacimiento"
                                    label="Provincia de Nacimiento"
                                    name="provinciaDeNacimiento"
                                    value={formik.values.provinciaDeNacimiento}
                                    onChange={provinciaDeNacimientoChanged}
                                >
                                    <MenuItem value="">
                                        <em>Dejar sin dato</em>
                                    </MenuItem>
                                    {listadoProvincias.loading ?
                                        <MenuItem>Cargando...</MenuItem>
                                        : listadoProvincias.error ?
                                            <MenuItem className={classes.error} disabled="true">No se pudo cargar los datos.</MenuItem>
                                            : listadoProvincias.data && listadoProvincias.data.map((prov) => <MenuItem value={prov.id} key={prov.id}>{prov.descripcion}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant='outlined'>
                                <InputLabel htmlFor="departamentoDeNacimiento">Departamento De Nacimiento</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="departamentoDeNacimiento"
                                    label="Departamento de Nacimiento"
                                    name="departamentoDeNacimiento"
                                    value={formik.values.departamentoDeNacimiento}
                                    onChange={departamentoDeNacimientoChanged}
                                >
                                    <MenuItem value="">
                                        <em>Dejar sin dato</em>
                                    </MenuItem>
                                    {listadoDepartamentos.loading ?
                                        <MenuItem>Cargando...</MenuItem>
                                        : !formik.values.provinciaDeNacimiento ?
                                            <MenuItem><em>Debe seleccionar una provincia primero</em></MenuItem>
                                            : listadoDepartamentos.error ?
                                                <MenuItem className={classes.error} disabled="true">No se pudo cargar listado.</MenuItem>
                                                : listadoDepartamentos.data && listadoDepartamentos.data.map((dto) => <MenuItem value={dto.id} key={dto.id}>{dto.descripcion}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="lugarDeNacimiento">Lugar De Nacimiento</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="lugarDeNacimiento"
                                    label="Lugar de Nacimiento"
                                    name="lugarDeNacimiento"
                                    value={formik.values.lugarDeNacimiento}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value="">
                                        <em>Dejar sin dato</em>
                                    </MenuItem>
                                    {listadoLocalidades.loading ?
                                        <MenuItem>Cargando...</MenuItem>
                                        : !formik.values.departamentoDeNacimiento ?
                                            <MenuItem><em>Debe seleccionar un departamento primero</em></MenuItem>
                                            : listadoLocalidades.error ?
                                                <MenuItem className={classes.error} disabled="true">No se pudo cargar listado.</MenuItem>
                                                : listadoLocalidades.data && listadoLocalidades.data.map((loca) => <MenuItem value={loca.id} key={loca.id}>{loca.descripcion}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    id="fechaDeNacimiento"
                                    label="Fecha de Nacimiento"
                                    name="fechaDeNacimiento"
                                    value={formik.values.fechaDeNacimiento}
                                    onChange={formik.handleChange}
                                    error={formik.touched.fechaDeNacimiento && Boolean(formik.errors.fechaDeNacimiento)}
                                    helperText={formik.touched.fechaDeNacimiento && formik.errors.fechaDeNacimiento}
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="grupoSanguineo">Grupo Sanguineo</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="grupoSanguineo"
                                    label="Grupo sanguineo"
                                    name="grupoSanguineo"
                                    value={formik.values.grupoSanguineo}
                                    onChange={formik.handleChange}
                                    type="date"
                                >
                                    <MenuItem value="">
                                        <em>Dejar sin dato</em>
                                    </MenuItem>
                                    {listadoGruposSanguineos.loading ?
                                        <MenuItem>Cargando...</MenuItem>
                                        : listadoGruposSanguineos.error ?
                                            <MenuItem className={classes.error} disabled="true">No se pudo cargar listado.</MenuItem>
                                            : listadoGruposSanguineos.data && listadoGruposSanguineos.data.map((gs) => <MenuItem value={gs.id} key={gs.id}>{gs.descripcion}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="genero">Genero</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="genero"
                                    label="Genero"
                                    name="genero"
                                    value={formik.values.genero}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value="">
                                        <em>Dejar sin dato</em>
                                    </MenuItem>
                                    <MenuItem value='2' key={2}>Femenino</MenuItem>
                                    <MenuItem value='1' key={1}>Masculino</MenuItem>
                                    <MenuItem value='3' key={1}>X</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}></Grid>

                        <Grid item xs={10}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    id="domicilioActual"
                                    label="Domicilio Actual"
                                    name="domicilioActual"
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                    value={formik.values.domicilioActual}
                                    onChange={(e) => Utils.toUpperCaseHandler(e, formik)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}></Grid>

                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="provinciaActual">Provincia</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="provinciaActual"
                                    label="Provincia"
                                    name="provinciaActual"
                                    value={formik.values.provinciaActual}
                                    onChange={provinciaActualChanged}
                                >
                                    <MenuItem value="">
                                        <em>Dejar sin dato</em>
                                    </MenuItem>
                                    {listadoProvincias.loading ?
                                        <MenuItem>Cargando...</MenuItem>
                                        : listadoProvincias.error ?
                                            <MenuItem className={classes.error} disabled="true">No se pudo cargar listado.</MenuItem>
                                            : listadoProvincias.data && listadoProvincias.data.map((provActual) => <MenuItem value={provActual.id} key={provActual.id}>{provActual.descripcion}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="departamentoActual">Departamento</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="departamentoActual"
                                    label="Departamento"
                                    name="departamentoActual"
                                    value={formik.values.departamentoActual}
                                    onChange={departamentoActualChanged}
                                >
                                    <MenuItem value="">
                                        <em>Dejar sin dato</em>
                                    </MenuItem>
                                    {listadoDepartamentosActual.loading ?
                                        <MenuItem>Cargando...</MenuItem>
                                        : !formik.values.provinciaActual ?
                                            <MenuItem><em>Debe seleccionar una provincia primero</em></MenuItem>
                                            : listadoDepartamentosActual.error ?
                                                <MenuItem className={classes.error} disabled="true">No se pudo cargar listado.</MenuItem>
                                                : listadoDepartamentosActual.data && listadoDepartamentosActual.data.map((dtoActual) => <MenuItem value={dtoActual.id} key={dtoActual.id}>{dtoActual.descripcion}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="localidadActual">Localidad</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="localidadActual"
                                    label="Localidad"
                                    name="localidadActual"
                                    value={formik.values.localidadActual}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value="">
                                        <em>Dejar sin dato</em>
                                    </MenuItem>
                                    {listadoLocalidadesActual.loading ?
                                        <MenuItem>Cargando...</MenuItem>
                                        : !formik.values.departamentoActual ?
                                            <MenuItem><em>Deber seleccionar un departamento primero</em></MenuItem>
                                            : listadoLocalidadesActual.error ?
                                                <MenuItem className={classes.error} disabled="true">No se pudo cargar listado.</MenuItem>
                                                : listadoLocalidadesActual.data && listadoLocalidadesActual.data.map((locaActual) => <MenuItem value={locaActual.id} key={locaActual.id}>{locaActual.descripcion}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={1}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    id="caracteristica_t"
                                    label="Característica"
                                    name="caracteristica_t"
                                    value={formik.values.caracteristica_t}
                                    onChange={formik.handleChange}
                                    error={formik.touched.caracteristica_t && Boolean(formik.errors.caracteristica_t)}
                                    helperText={formik.touched.caracteristica_t && formik.errors.caracteristica_t}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    id="telefonoActual"
                                    label="Teléfono"
                                    name="telefonoActual"
                                    value={formik.values.telefonoActual}
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2} md={1}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    id="caracteristica_c"
                                    label="Característica"
                                    name="caracteristica_c"
                                    value={formik.values.caracteristica_c}
                                    onChange={formik.handleChange}
                                    error={formik.touched.caracteristica_c && Boolean(formik.errors.caracteristica_c)}
                                    helperText={formik.touched.caracteristica_c && formik.errors.caracteristica_c}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    id="celularActual"
                                    label="Celular"
                                    name="celularActual"
                                    value={formik.values.celularActual}
                                    onChange={formik.handleChange}
                                    error={formik.touched.celularActual && Boolean(formik.errors.celularActual)}
                                    helperText={formik.touched.celularActual && formik.errors.celularActual}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    id="caracteristica_c1"
                                    label="Característica"
                                    name="caracteristica_c1"
                                    value={formik.values.caracteristica_c1}
                                    onChange={formik.handleChange}
                                    error={formik.touched.caracteristica_c1 && Boolean(formik.errors.caracteristica_c1)}
                                    helperText={formik.touched.caracteristica_c1 && formik.errors.caracteristica_c1}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    id="celularActual1"
                                    label="Celular"
                                    name="celularActual1"
                                    min="0"
                                    value={formik.values.celularActual1}
                                    onChange={formik.handleChange}
                                    error={formik.touched.celularActual1 && Boolean(formik.errors.celularActual1)}
                                    helperText={formik.touched.celularActual1 && formik.errors.celularActual1}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}></Grid>

                        <Grid item xs={7}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    id="email"
                                    label="Email"
                                    name="email"
                                    inputProps={{ style: { textTransform: "lowercase" } }}
                                    value={formik.values.email}
                                    onChange={(e) => Utils.toLowerCaseHandler(e, formik)}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="estCivil">Estado Civil</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="estCivil"
                                    label="Estado Civil"
                                    name="estCivil"
                                    value={formik.values.estCivil}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value="">
                                        <em>Dejar sin dato</em>
                                    </MenuItem>
                                    {listadoEstadoCivil.loading ?
                                        <MenuItem>Cargando...</MenuItem>
                                        : listadoEstadoCivil.error ?
                                            <MenuItem className={classes.error} disabled="true">No se pudo cargar listado.</MenuItem>
                                            : listadoEstadoCivil.data && listadoEstadoCivil.data.map((EstCivil) => <MenuItem value={EstCivil.id} key={EstCivil.id}>{EstCivil.descripcion}</MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}></Grid>

                        <Grid item xs={6}>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    onClick={e => formik.submitForm(e)}
                                    disabled={submitting}
                                    endIcon={<SaveIcon />}
                                >
                                    {submitting ? "Guardando..." : "Guardar"}
                                </Button>
                                <Button
                                    variant="secondary"
                                    size="medium"
                                    color="secondary"
                                    endIcon={<CloseIcon />}
                                    onClick={cancelar}
                                    disabled={submitting}
                                > Cancelar
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </Page>
        )
    }
}
