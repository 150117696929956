import React from 'react'
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Dashboard from './Dashboard';
import PageBreadcrumbs from './PageBreadcrumbs';

export const Page = (props) => {
    return (
        <>
            <Dashboard>
                <PageBreadcrumbs data={props.breadcrumbs} />
                <Paper elevation={0} sx={{
                    m: {
                        xs: 1,
                        sm: 1,
                        md: 2
                    }, border: "1px solid #e0e0e0"
                }}>
                    {props.title ? (
                        <Box sx={{ borderBottom: "1px solid #e0e0e0" }} pt={1} pb={1} pl={2}>
                            <Typography variant="cuerpo">{props.title}</Typography>
                        </Box>
                    ) : (
                        ""
                    )}
                    <Box
                        sx={{
                            m: {
                                xs: 2,
                                sm: 2,
                                md: 3,
                            },
                        }}
                    >
                        {props.children}
                    </Box>
                </Paper>
            </Dashboard>
        </>
    )
}
