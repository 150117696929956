import { useEffect } from "react";
import { useState } from "react";
import api from "../service/api";

export const useJubilados = (buscar = '', refrescar, habilitarSinFiltro) => {
    const [datosList, setDatosListState] = useState({ loading: true, error: false, data: null });
    useEffect(() => {
        let ignore = false;
        if (buscar === '') {
            if (!ignore) {
                setDatosListState({
                    loading: false,
                    error: false,
                    data: null,
                });
            }
        } else {
            api.jubiladosBuscar(buscar)
                .then(resultado => {
                    if (!ignore) {
                        setDatosListState({
                            loading: false,
                            error: false,
                            data: resultado.data
                        });
                    }
                })
                .catch(function (error) {
                    if (!ignore) {
                        setDatosListState({
                            loading: false,
                            data: null,
                            error: true,
                        });
                        console.log("jubiladosBuscar => ", error);
                    }
                });
        }
        return () => { ignore = true; }
    }, [buscar, refrescar, habilitarSinFiltro]);

    return datosList;
}
