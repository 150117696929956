import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
    Select, Checkbox, Box,
    Button, Grid, FormControl, TextField, Stack, Snackbar, InputLabel,
    ListItemText, IconButton,
    InputAdornment
} from '@mui/material';
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import { useFetch } from '../../hooks/useFetch';
import MuiAlert from '@mui/material/Alert';
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AuthContext } from '../../auth/AuthContext';
import { ReporteInformesPdf } from '../../commons/ReporteInformesPdf';
import { Page } from '../ui/Page';
import api from "../../service/api";
import { TableAfiliados } from './TablaAfiliados';
import Modal from '../ui/Modal';
import { InformeAfiliadoDetalleModal } from './InformeAfiliadoDetalleModal';

export const InformeAfiliadosAll = (props) => {
    const useStyles = makeStyles({
        error: {
            backgroundColor: '#EFAFC0',
            '&:hover': {
                backgroundColor: '#DBA1B0 !important',
            },
        },
        selectAdornment: {
            marginRight: 30,
        }
    });
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState("");
    const [tipoMensaje, setTipoMensaje] = useState("");
    const [expandini, setExpandIni] = useState(false);

    const { user: { canBusquedaAmpliada } } = useContext(AuthContext);

    const toggleAcordionIni = () => {
        setExpandIni((prev) => !prev);
    };

    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Informe de Afiliados", ruta: "/informeAfiliados" },
    ];

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const [afiliados, setAfiliados] = useState({ loading: false });

    var validationSchema = Yup.object().shape({
        documento: Yup.string()
            .min(7, "Documento no válido")
            .max(8, "Documento no válido"),
        nombre: Yup.string()
            .min(2, "Ingrese al menos 2 letras")
            .max(50, "Ingrese menos de 50"),
        apellido: Yup.string()
            .min(2, "Ingrese al menos 2 letras")
            .max(50, "Ingrese menos de 50"),
    });

    const formik = useFormik({
        initialValues: {
            documento: "",
            nombre: "",
            apellido: "",
            provincia: "",
            localidad: [],
            departamento: "",
            empresa: "",
            obras: "",
            condicion: "T",
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitting(true);
            setAfiliados({ loading: true });
            api
                .buscarAfiliados({
                    documento: values.documento,
                    nombre: values.nombre,
                    apellido: values.apellido,
                    provincia: values.provincia,
                    localidad: values.localidad,
                    departamento: values.departamento,
                    empresa: values.empresa,
                    obras: values.obras,
                    condicion: values.condicion,
                })
                .then((res) => {
                    setSubmitting(false);
                    setAfiliados({
                        loading: false,
                        data: res.data.afiliados
                    });
                })
                .catch(function (error) {
                    setMensajeAlert('Error al guardar');
                    setTipoMensaje('error');
                    setSubmitting(false);
                    setAfiliados({ loading: false });
                    console.log('El error es =>', error)
                })
        },
        onReset: () => { },
    });

    const listadoProvincias = useFetch('provincias');
    const listadoDepartamentos = useFetch(`departamentos/${formik.values.provincia}`);
    //provinciaActual se pasa solamente para forzar la actualización cuando cambia la provincia.
    const listadoLocalidades = useFetch(`localidades/${formik.values.departamento}?p=${formik.values.provincia}`);

    const handleProvinciaChanged = (e) => {
        formik.values.departamento = "";
        formik.values.localidad = [];
        formik.handleChange(e);
    }

    const handleDepartamentoChanged = (e) => {
        formik.values.localidad = [];
        formik.handleChange(e);
    }

    const [empresasList, setEmpresasList] = useState([]);
    const [listadoObrasSociales, setObra] = useState([]);
    const buscarObras = async () => {
        api.obrasSociales.buscarObras()
            .then(function (resp) {
                setObra(resp.data);
            })
            .catch(function (error) {
                console.log('El error es =>', error)
            });
    }

    const buscarEmpresas = async () => {
        api.empresas.buscarEmpresas()
            .then(function (resp) {
                setEmpresasList(resp.data);
            })
            .catch(function (error) {
                console.log('El error es =>', error)
            });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const cerrar = () => {
        formik.handleReset();
    };

    const confirmar = () => {
        formik.submitForm();
    }

    const [openModalInformacion, setOpenModalInformacion] = useState(false);
    const [idAfiliadoMostrarInformacion, setIdAfiliadoMostrarInformacion] = useState(0);

    const toggleModalInformacion = useCallback((selectedIdAfiliado) => {
        setIdAfiliadoMostrarInformacion(selectedIdAfiliado);
        setOpenModalInformacion(!openModalInformacion);
    }, [openModalInformacion]);
    const handleCloseModalInformacion = () => {
        setOpenModalInformacion(false);
    };

    const renderLocalidadValue = (selected, localidades) => {
        if (localidades && localidades.data && selected) {
            let d = localidades.data;
            return selected
                .map(s => d.find(l => l.id === s))
                .filter(l => l)
                .map(l => l.descripcion)
                .join(', ');
        }
        return '';
    }

    const handleCleanLocalidades = (e) => {
        formik.setFieldValue("localidad", []);
    }

    useEffect(() => {
        if (expandini) {
            buscarObras();
            buscarEmpresas();
        }
    }, [expandini]);

    return (
        <Page title="Informe de Afiliados" breadcrumbs={breadcrumbs}>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}>
                <Alert onClose={handleClose} severity={tipoMensaje} sx={{
                    width: '100%'
                }}>
                    {mensajeAlert}
                </Alert>
            </Snackbar>
            <Grid>
                <form
                    id="formBusca"
                    name="formBuscar"
                    onSubmit={formik.handleSubmit}
                    onReset={formik.handleReset}
                    autoComplete="off"
                >
                    <Grid>
                        <Accordion disableGutters expanded={expandini} onChange={toggleAcordionIni}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel-filtros-ini"
                                id="panel-filtros-ini"
                                sx={{ height: "50px" }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                    }}
                                >
                                    <SearchIcon
                                        color={submitting ? "secondary" : "primary"}
                                        sx={{ mr: 1, display: { xs: "none", md: "inline" } }}
                                    />
                                    <Typography variant="cuerpo">Criterios de búsqueda</Typography>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{ p: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={2} >
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete="off"
                                                placeholder="Dni"
                                                label="Dni"
                                                name="documento"
                                                id="documento"
                                                type="number"
                                                variant="outlined"
                                                value={formik.values.documento}
                                                onChange={formik.handleChange}
                                                error={formik.touched.documento && Boolean(formik.errors.documento)}
                                                helperText={formik.touched.documento && formik.errors.documento}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={5} >
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete="off"
                                                placeholder="Nombres"
                                                label="Nombres"
                                                name="nombre"
                                                id="nombre"
                                                type="text"
                                                variant="outlined"
                                                inputProps={{ style: { textTransform: "uppercase" } }}
                                                value={formik.values.nombre}
                                                onChange={formik.handleChange}
                                                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                                                helperText={formik.touched.nombre && formik.errors.nombre}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={5} >
                                        <FormControl fullWidth>
                                            <TextField
                                                autoComplete="off"
                                                placeholder="Apellido"
                                                label="Apellido"
                                                name="apellido"
                                                id="apellido"
                                                type="text"
                                                variant="outlined"
                                                inputProps={{ style: { textTransform: "uppercase" } }}
                                                value={formik.values.apellido}
                                                onChange={formik.handleChange}
                                                error={formik.touched.apellido && Boolean(formik.errors.apellido)}
                                                helperText={formik.touched.apellido && formik.errors.apellido}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {canBusquedaAmpliada &&
                                    <Grid>
                                        <br></br>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4} >
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="Provincia">Provincia</InputLabel>
                                                    <Select
                                                        autoComplete="off"
                                                        id="provincia"
                                                        label="Provincia"
                                                        name="provincia"
                                                        value={formik.values.provincia}
                                                        onChange={handleProvinciaChanged}
                                                    >
                                                        <MenuItem value="">Seleccionar..</MenuItem>
                                                        {listadoProvincias.loading ?
                                                            <MenuItem>Cargando...</MenuItem>
                                                            : listadoProvincias.error ?
                                                                <MenuItem className={classes.error} disabled="true">No se pudo cargar los datos.</MenuItem>
                                                                : listadoProvincias.data && listadoProvincias.data.map((prov) => <MenuItem value={prov.id} key={prov.id}>{prov.descripcion}</MenuItem>)
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4} >
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="Departamento">Departamento</InputLabel>
                                                    <Select
                                                        autoComplete="off"
                                                        id="departamento"
                                                        label="Departamento"
                                                        name="departamento"
                                                        value={formik.values.departamento}
                                                        onChange={handleDepartamentoChanged}
                                                    >
                                                        <MenuItem value="">Seleccionar..</MenuItem>
                                                        {listadoDepartamentos.loading ?
                                                            <MenuItem>Cargando...</MenuItem>
                                                            : listadoDepartamentos.error ?
                                                                <MenuItem className={classes.error} disabled="true">No se pudo cargar los datos.</MenuItem>
                                                                : listadoDepartamentos.data.map((dtoActual) => <MenuItem value={dtoActual.id} key={dtoActual.id}>{dtoActual.descripcion}</MenuItem>)
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="Localidad">Localidad</InputLabel>
                                                    <Select
                                                        autoComplete="off"
                                                        id="localidad"
                                                        label="Localidad"
                                                        name="localidad"
                                                        value={formik.values.localidad}
                                                        onChange={formik.handleChange}
                                                        multiple
                                                        renderValue={(selected) => renderLocalidadValue(selected, listadoLocalidades)}
                                                        endAdornment={formik.values.localidad.length > 0 &&
                                                            <InputAdornment position="end"
                                                                class={classes.selectAdornment}>
                                                                <IconButton edge="end"
                                                                    title='limpiar selección'
                                                                >
                                                                    <CloseIcon onClick={handleCleanLocalidades} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    >
                                                        <MenuItem value="">Seleccionar.. (5 máximo)</MenuItem>
                                                        {listadoLocalidades.loading ?
                                                            <MenuItem>Cargando...</MenuItem>
                                                            : listadoLocalidades.error ?
                                                                <MenuItem className={classes.error} disabled="true">No se pudo cargar los datos.</MenuItem>
                                                                : listadoLocalidades.data
                                                                && listadoLocalidades.data.map((l) => (
                                                                    <MenuItem
                                                                        value={l.id}
                                                                        key={l.id}
                                                                        disabled={formik.values.localidad.indexOf(l.id) === -1 && formik.values.localidad.length >= 5}
                                                                    >
                                                                        <Checkbox checked={formik.values.localidad.indexOf(l.id) > -1} />
                                                                        <ListItemText primary={l.descripcion} />
                                                                    </MenuItem>)
                                                                )
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {canBusquedaAmpliada &&
                                    <Grid>
                                        <br></br>
                                        <Grid container spacing={2}>
                                            <Grid item xs={5} >
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="Empresa">Empresa</InputLabel>
                                                    <Select
                                                        autoComplete="off"
                                                        id="empresa"
                                                        label="Empresa"
                                                        name="empresa"
                                                        value={formik.values.empresa}
                                                        onChange={formik.handleChange}
                                                    >
                                                        <MenuItem value="">Seleccionar..</MenuItem>
                                                        {!empresasList.loading ? empresasList.map((empresv) => <MenuItem value={empresv.id} key={empresv.id}>{empresv.descripcion}</MenuItem>) : <MenuItem>Cargando</MenuItem>}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={5} >
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="Obra Social">Obra Social</InputLabel>
                                                    <Select
                                                        autoComplete="off"
                                                        id="obras"
                                                        label="Obra Social"
                                                        name="obras"
                                                        value={formik.values.obras}
                                                        onChange={formik.handleChange}
                                                    >
                                                        <MenuItem value="">Seleccionar..</MenuItem>
                                                        {!listadoObrasSociales.loading ?
                                                            listadoObrasSociales.map((os) =>
                                                                <MenuItem key={os.id} value={os.id}>{os.abreviatura}</MenuItem>) : <MenuItem>Cargando</MenuItem>
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="condicion">Condición</InputLabel>
                                                    <Select
                                                        autoComplete="off"
                                                        id="condicion"
                                                        label="Condición"
                                                        name="condicion"
                                                        value={formik.values.condicion}
                                                        onChange={formik.handleChange}
                                                    >
                                                        <MenuItem value="T">Todos</MenuItem>
                                                        <MenuItem value="A">Activos</MenuItem>
                                                        <MenuItem value="J">Jubilados</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                <br></br>
                                <br></br>
                                <Stack direction="row" spacing={2}>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="primary"
                                        disabled={submitting}
                                        onClick={confirmar}
                                        endIcon={<SearchIcon />}
                                    >
                                        {submitting ? "Buscando..." : "Buscar"}
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        size="medium"
                                        color="secondary"
                                        onClick={cerrar}
                                        endIcon={<CloseIcon />}
                                    >Limpiar
                                    </Button>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </form>
            </Grid>
            <br></br>
            <Grid container>
                <Grid item xs={6} align="left">
                    <ReporteInformesPdf
                        nombre="Afiliados"
                        headerTabla={[
                            'Dni',
                            'Apellido',
                            'Nombre',
                            'Provincia',
                            'Departamento',
                            'Localidad',
                            'Empresa',
                            'Obra Social',
                            'Jubilación',
                        ]}
                        mapeador={(i) => {
                            return [
                                i.dni,
                                i.apellido,
                                i.nombre,
                                i.provincia,
                                i.departamento,
                                i.localidad,
                                i.empresa,
                                i.obra,
                                i.jubilacion,
                            ];
                        }}
                        datos={afiliados.data}
                    >
                    </ReporteInformesPdf>
                </Grid>
                <Grid item xs={6} align="right">
                </Grid>
            </Grid>
            <Grid container>
                <Modal open={openModalInformacion} handleClose={handleCloseModalInformacion} size="xl">
                    <InformeAfiliadoDetalleModal afiliado_aux={idAfiliadoMostrarInformacion}></InformeAfiliadoDetalleModal>
                    <br></br>
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="secondary"
                            size="medium"
                            color="secondary"
                            onClick={handleCloseModalInformacion}
                            endIcon={<CloseIcon />}
                        >Cerrar
                        </Button>
                    </Stack>
                </Modal>
                <TableAfiliados
                    afiliados={afiliados}
                    toggleModalInformacion={toggleModalInformacion}
                ></TableAfiliados>
            </Grid>
        </Page >
    )
}