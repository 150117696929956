import React, { useState, useEffect, useRef } from 'react';
import {
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Grid, Alert, Stack,
    Button,
    TextField,
    Tooltip,
    IconButton,
    Typography,
} from '@mui/material';
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import { Page } from '../ui/Page';
import api from "../../service/api";
import DataTables from "datatables.net-dt";
import 'datatables.net-dt/css/dataTables.dataTables.css';
import { InformesAuditPdf } from '../../commons/InformesAuditPdf';
import { Info, Search, Close } from '@mui/icons-material';
import Modal from '../ui/Modal';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        width: '100% !important',
    },
    root: {
        flexGrow: 1,
    },
    cabeceraTabla: {
        backgroundColor: 'darkgrey',
        color: 'white'
    }
});

const get_tipo_display = (tipo) => {
    switch (tipo) {
        case 0: return "ALTA";
        case 1: return "BAJA";
        case 2: return "MODIFICACIÓN";
        case 3: return "REVERTIR BAJA";
        case 4: return "ELIMINO";
        case 5: return "BLOQUEO";
        case 6: return "DESBLOQUEO";
        case 7: return "CAMBIO CONTRASEÑA";
        default: return "SIN DESCRIPCION (".concat(tipo, ")");
    }
};

const TablaAudit = ({
    datos,
    handleOpenModalCambios
}) => {
    const tableRef = useRef();
    const classes = useStyles();

    useEffect(() => {
        const dt = new DataTables(tableRef.current, {
            searching: true,
            order: [[4, 'desc']],
        });
        return () => {
            dt.destroy();
        };
    }, [datos]);

    if (datos.loading) {
        return (
            <Grid item xs={12} >
                <Alert severity="info">
                    Cargando...
                </Alert>
            </Grid>
        );
    } else if (datos.error) {
        return (<Grid item xs={12} >
            <Alert severity="error">
                No se pudo cargar la información solicitada.
            </Alert>
        </Grid>
        );
    } else if (datos.data) {
        return (
            <Grid container>
                <Grid item xs={12} >
                    {
                        datos.data.fechaDesde && datos.data.fechaHasta &&
                        <p>Auditoria desde <b>{datos.data.fechaDesde}</b> hasta <b>{datos.data.fechaHasta}</b>.</p>
                    }
                </Grid>
                <Grid container>
                    <InformesAuditPdf
                        nombre="Auditoría de datos usuarios"
                        subfixNamePdf="usuarios"
                        datos={datos.data.informes_audit.map(row => {
                            return {
                                mensaje: row.mensaje,
                                nombre: row.nombre,
                                dni: row.dni,
                                nombre_usuario: row.nombre_usuario,
                                fecha_hora: row.fecha_hora,
                                tipo: get_tipo_display(row.tipo)
                            }
                        })}
                        headerTabla={[
                            'Mensaje',
                            'Usuario',
                            'DNI',
                            'Usuario',
                            'Fecha y hora',
                            'Tipo'
                        ]}
                    ></InformesAuditPdf>
                </Grid>
                <Grid container>
                    <Grid item xs={12} >
                        <TableContainer>
                            <Table ref={tableRef} className={`${classes.table} stripe compact`} size="small" aria-label="a dense table">
                                <TableHead className={classes.cabeceraTabla}>
                                    <TableRow>
                                        <TableCell align="left" className="dt-head-left">Mensaje</TableCell>
                                        <TableCell align="left" className="dt-head-left">Usuario</TableCell>
                                        <TableCell align="left" className="dt-head-left">DNI</TableCell>
                                        <TableCell align="left" className="dt-head-left">Usuario</TableCell>
                                        <TableCell align="right" className="dt-head-right">Fecha y hora</TableCell>
                                        <TableCell align="center" className="dt-head-center">Tipo</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {datos.data.informes_audit.map((row, idx) => (
                                        <TableRow key={row.id}>
                                            <TableCell align="left" className='dt-body-left'>{row.mensaje}</TableCell>
                                            <TableCell align="left" className='dt-body-left'>{row.nombre}</TableCell>
                                            <TableCell align="left" className='dt-body-left'>{row.dni}</TableCell>
                                            <TableCell align="left" className='dt-body-left'>{row.nombre_usuario}</TableCell>
                                            <TableCell align="right" className='dt-body-right' data-order={row.fecha_hora_iso}>{row.fecha_hora}</TableCell>
                                            <TableCell align="center" className='dt-body-center'>{get_tipo_display(row.tipo)}</TableCell>
                                            <TableCell align="center" className='dt-body-center'>
                                                <Tooltip title="Información">
                                                    <IconButton
                                                        color="primary"
                                                        size="small"
                                                        type="button"
                                                        variant="outlined"
                                                        onClick={() => handleOpenModalCambios(row)}
                                                    >
                                                        <Info />
                                                    </IconButton >
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid item xs={12} >
                <Alert severity="error">
                    Error inesperado.
                </Alert>
            </Grid>
        );
    }
}

export const AuditarUsuarios = (props) => {
    const [datos, setDatos] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const classes = useStyles();
    const pageTitle = "Auditoria datos usuarios";
    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: pageTitle, ruta: "/auditorias/auditUsuarios" },
    ];

    const { fechaDesdeIso, fechaHastaIso } = datos !== null && datos.data ?
        datos.data
        : { fechaDesdeIso: '', fechaHastaIso: '' };

    const getAuditoria = (values) => {
        setSubmitting(true);
        setDatos({ loading: true });
        api.audit.buscarAuditUsuarios(values)
            .then((resp) => {
                setDatos({ data: resp.data });
                setSubmitting(false);
            })
            .catch((error) => {
                setDatos({ error: true });
                setSubmitting(false);
                console.log('El error es => ', error);
            });
    };

    var validationSchema = Yup.object().shape({
    });
    const formik = useFormik({
        initialValues: {
            fechaDesde: fechaDesdeIso,
            fechaHasta: fechaHastaIso,
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: getAuditoria,
        onReset: () => { },
    });

    useEffect(() => {
        getAuditoria();
    }, []);

    const [openModalCambios, setOpenModalCambios] = useState(false);
    const [cambios, setCambios] = useState({});

    const handleOpenModalCambios = (row) => {
        setOpenModalCambios(true);
        setCambios({ loading: true });
        api.audit.getCambiosUsuarioByIdAudit({ id_audit_usuario: row.id })
            .then((resp) => {
                setCambios({
                    data: resp.data.cambios,
                    row: row
                });
            })
            .catch((error) => {
                setCambios({ error: true });
            });
    };

    const handleCloseModalCambios = () => {
        setOpenModalCambios(false);
        setCambios({});
    };

    const TablaCambios = ({ titulo, cambios }) => {
        return (
            <Grid container>
                <Grid item xs={12}>{titulo}</Grid>
                <Grid item xs={12}>
                    <TableContainer>
                        <Table className={`${classes.table} stripe compact`} size="small" aria-label="a dense table">
                            <TableHead className={classes.cabeceraTabla}>
                                <TableRow>
                                    <TableCell align="left" className="dt-head-left">Campo modificado</TableCell>
                                    <TableCell align="left" className="dt-head-left">Valor</TableCell>
                                    <TableCell align="left" className="dt-head-left">Valor anterior</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowsCambios(cambios)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        );
    }

    const rowsCambios = (cambios) => {
        return Object.getOwnPropertyNames(cambios).map((n) => {
            return (
                <TableRow key={n}>
                    <TableCell align="left" className='dt-body-left'>{n}</TableCell>
                    <TableCell align="left" className='dt-body-left'>{cambios[n].valor}</TableCell>
                    <TableCell align="left" className='dt-body-left'>{cambios[n].original}</TableCell>
                </TableRow>
            );
        });
    };

    const renderCambios = (cambios) => {
        return (
            cambios.data &&
            <>
                {
                    cambios.row &&
                    <Grid container>
                        <Grid item xs={12}>
                            <p><b>Nombre usuario: </b> {cambios.row.nombre}&nbsp;&nbsp;
                                <b>DNI:</b> {cambios.row.dni}
                                <br />
                                <b>N° Interno: </b>{cambios.row.id_usuario2}</p>
                        </Grid>
                    </Grid>
                }
                {cambios.data.hay_cambios ?
                    <>
                        {
                            cambios.data.cambios_persona &&
                            <TablaCambios titulo="Datos personales:" cambios={cambios.data.cambios_persona.cambios} />
                        }
                        {
                            cambios.data.cambios_usuario &&
                            <TablaCambios titulo="Datos usuario" cambios={cambios.data.cambios_usuario.cambios} />
                        }
                    </>
                    : (cambios.data.tipo !== 2) &&
                    <Alert severity="info">
                        {get_tipo_display(cambios.data.tipo)}
                    </Alert>
                }
            </>
        );
    };

    return (
        <Page title={pageTitle} breadcrumbs={breadcrumbs}>
            <Grid className={classes.root}>
                <form
                    id="formBuscar"
                    name="formBuscar"
                    onSubmit={formik.handleSubmit}
                    onReset={formik.handleReset}
                    autoComplete="off"
                >
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                autoComplete="off"
                                id="fechaDesde"
                                label="Fecha de Desde"
                                name="fechaDesde"
                                value={formik.values.fechaDesde}
                                onChange={formik.handleChange}
                                error={formik.touched.fechaDesde && Boolean(formik.errors.fechaDesde)}
                                helperText={formik.touched.fechaDesde && formik.errors.fechaDesde}
                                type="date"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                autoComplete="off"
                                id="fechaHasta"
                                label="Fecha de Hasta"
                                name="fechaHasta"
                                value={formik.values.fechaHasta}
                                onChange={formik.handleChange}
                                error={formik.touched.fechaHasta && Boolean(formik.errors.fechaHasta)}
                                helperText={formik.touched.fechaHasta && formik.errors.fechaHasta}
                                type="date"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                    <br />
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            disabled={submitting}
                            onClick={formik.handleSubmit}
                            endIcon={<Search />}
                        >
                            {submitting ? "Buscando..." : "Buscar"}
                        </Button>
                    </Stack>
                </form>
                <br />
                {
                    datos !== null &&
                    <TablaAudit datos={datos} handleOpenModalCambios={handleOpenModalCambios} />
                }
            </Grid>
            <Modal open={openModalCambios} handleClose={handleCloseModalCambios} size="lg">
                <Typography variant="titulo" paragraph>
                    Auditoría de cambios
                </Typography>
                <Typography variant="overline">
                    {
                        cambios.loading &&
                        <Alert severity="info">
                            Cargando...
                        </Alert>
                    }
                    {
                        cambios.error &&
                        <Alert severity="error">
                            No se pudo cargar la información solicitada.
                        </Alert>
                    }
                    {renderCambios(cambios)}
                </Typography>
                <br></br>
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="secondary"
                        size="medium"
                        color="secondary"
                        onClick={handleCloseModalCambios}
                        endIcon={<Close />}
                    >Cerrar
                    </Button>
                </Stack>
            </Modal>
        </Page>
    );
}