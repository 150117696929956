import { useEffect } from "react";
import { useState } from "react";
const baseUrl = String(process.env.REACT_APP_API_URL);

export const useFetch = (buscar = 'provincias') => {
    const [datos, setDatos] = useState({ data: null, loading: true, error: null });
    useEffect(() => {
        let ignore = false;
        const axios = require('axios').default;
        axios.get(`${baseUrl}/${buscar}`)
            .then(function (resp) {
                if (!ignore) {
                    setDatos({
                        loading: false,
                        error: null,
                        data: resp.data
                    });
                }
            })
            .catch(function (error) {
                if (!ignore) {
                    setDatos({
                        loading: false,
                        error: true,
                        data: null
                    });
                    console.log('El error es =>', error);
                }
            });
        return () => {
            ignore = true;
        };
    }, [buscar]);

    return datos;
}
