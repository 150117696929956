import React, { useState, useEffect, useContext } from 'react'
import {
    Button, FormControl, Grid, Container, InputLabel, MenuItem, Select,
    Snackbar, TextField, Checkbox, Divider, Stack, FormHelperText,
    FormControlLabel
} from '@mui/material'
import api from '../../service/api'
import { Page } from './../ui/Page';
import MuiAlert from '@mui/material/Alert';
import { useParams } from 'react-router';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import axios from "axios";

export const EmpleoEdicionCompleto = (props) => {
    const { id } = useParams();
    const { user: { canEditarEmpleoCompleto } } = useContext(AuthContext)
    const [afiliado, setAfiliado] = useState({
        loading: true,
        data: {
            id_afiliado: 0,
            nombre_afiliado: '',
            dni_afiliado: '',
        },
        error: null
    });

    const { nombre_afiliado, dni_afiliado } = afiliado.data ?? { nombre_afiliado: '', dni_afiliado: '' };
    const [loading, setLoading] = useState(true);
    const [checkedRes268, setCheckedRes268] = useState(true);
    const [checkedOS, setCheckedOS] = useState(false);
    const [checkedFAF, setCheckedFAF] = useState(false);
    const [habilitadoNroOS, setHabilitadoNroOS] = useState(false);
    const [listadoObrasSociales, setObra] = useState({ data: null, loading: true, error: null });
    const [empresasList, setEmpresasList] = useState({ data: null, loading: true, error: null });
    const [sistNacList, setSistNacList] = useState({ data: null, loading: true, error: null });
    const [open, setOpen] = useState(false);
    const [formEmpleoState, setFormEmpleoState] = useState({
        aux_afiliado_id: id,
        aux_empleadoEn: '',
        aux_cajaJubProvincial: false,
        aux_sistemaNacional: false,
        aux_sistNacCual: '',
        aux_obraSocial: '',
        aux_fechaDeIngreso: '',
        aux_funcionQueDesempeña: '',
        aux_ingresos: '',
        aux_legajo: '',
        aux_fechaAfiliacion: '',
        aux_fondoCompensador: false,
        aux_osfatlyf: false,
        aux_faf: false,
        aux_pre_nro: '',
        aux_numero: '',
        aux_post_nro: '',
        aux_extra_nro: '',
        aux_res2689: false
    });
    const [submitting, setSubmitting] = useState(false);
    const [mensajeAlert, setMensajeAlert] = useState("");
    const [tipoMensaje, setTipoMensaje] = useState("");
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSubmitting(false);
        setOpen(false);
    };

    const history = useHistory();

    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Datos empleo", ruta: "" },
    ];

    const {
        aux_afiliado_id,
        aux_empleadoEn,
        aux_cajaJubProvincial,
        aux_sistemaNacional,
        aux_sistNacCual,
        aux_obraSocial,
        aux_fechaDeIngreso,
        aux_legajo,
        aux_funcionQueDesempeña,
        aux_ingresos,
        aux_fechaAfiliacion,
        aux_fondoCompensador,
        aux_osfatlyf,
        aux_faf,
        aux_pre_nro,
        aux_numero,
        aux_post_nro,
        aux_extra_nro,
        aux_res2689
    } = formEmpleoState;

    useEffect(() => {
        setLoading(true);
        api.getAfiliadoVW({ 'id': id })
            .then(respAfiliado => {
                setAfiliado({
                    loading: false,
                    data: {
                        id_afiliado: respAfiliado.data.id_afiliado,
                        nombre_afiliado: respAfiliado.data.apellido + ', ' + respAfiliado.data.nombre,
                        dni_afiliado: respAfiliado.data.dni,
                    },
                });
                return api.buscarAfiliadoPorId({ id_afiliado: id });
            })
            .then(function (respAfiliado) {
                const aporte_caja_jub = parseInt(respAfiliado.data.aporte_caja_jub) === 1;
                const aporte_sist_nac = parseInt(respAfiliado.data.aporte_sist_nac) === 1;
                const res2689 = parseInt(respAfiliado.data.res2689) === 1;
                const plan_especial = respAfiliado.data.plan_especial === 1;
                const faf = respAfiliado.data.faf === 1;
                setFormEmpleoState({
                    aux_afiliado_id: respAfiliado.data.id,
                    aux_empleadoEn: respAfiliado.data.id_empresa ? respAfiliado.data.id_empresa : '',
                    aux_cajaJubProvincial: aporte_caja_jub,
                    aux_sistemaNacional: aporte_sist_nac,
                    aux_sistNacCual: respAfiliado.data.id_sistema_nacional ? parseInt(respAfiliado.data.id_sistema_nacional) : 3,
                    aux_obraSocial: respAfiliado.data.id_obra_social ? respAfiliado.data.id_obra_social : '',
                    aux_fechaDeIngreso: respAfiliado.data.fecha_ingreso ? respAfiliado.data.fecha_ingreso : '',
                    aux_funcionQueDesempeña: respAfiliado.data.funcion ? respAfiliado.data.funcion : '',
                    aux_legajo: respAfiliado.data.legajo ? respAfiliado.data.legajo : '',
                    aux_ingresos: respAfiliado.data.ingresos ? respAfiliado.data.ingresos : '',
                    aux_fechaAfiliacion: respAfiliado.data.fecha_afiliacion ? respAfiliado.data.fecha_afiliacion : '',
                    aux_fondoCompensador: respAfiliado.data.fondo_compensador !== null ? respAfiliado.data.fondo_compensador : '',
                    aux_osfatlyf: plan_especial,
                    aux_faf: faf,
                    aux_pre_nro: respAfiliado.data.pre_nro ? respAfiliado.data.pre_nro : '',
                    aux_numero: respAfiliado.data.numero ? respAfiliado.data.numero : '',
                    aux_post_nro: respAfiliado.data.post_nro ? respAfiliado.data.post_nro : '',
                    aux_extra_nro: respAfiliado.data.extra_nro ? respAfiliado.data.extra_nro : '',
                    aux_res2689: res2689
                });
                formik.setFieldValue('cajaJubProvincial', aporte_caja_jub);
                formik.setFieldValue('sistemaNacional', aporte_sist_nac);
                setCheckedRes268(res2689);
                setCheckedOS(plan_especial);
                setCheckedFAF(faf);
                setHabilitadoNroOS(isIdOsfatlyf(respAfiliado.data.id_obra_social));
                return axios.all([
                    api.getEmpresas({ id_afiliado: respAfiliado.data.id }),
                    api.getSistNac(),
                    api.getObrasSociales({ id_afiliado: respAfiliado.data.id }),
                ]);
            })
            .then(axios.spread((respEmpresas, respSistNac, respObrasSociales) => {
                setEmpresasList({
                    loading: false,
                    data: respEmpresas.data
                });
                setSistNacList({
                    loading: false,
                    data: respSistNac.data
                });
                setObra({
                    loading: false,
                    data: respObrasSociales.data
                });
                setLoading(false);
            }))
            .catch(error => {
                console.log("Error => ", error);
                setLoading(false);
                setAfiliado({ loading: false, error: true, });
                setEmpresasList({ loading: false, error: true, });
                setSistNacList({ loading: false, error: true, });
                setObra({ loading: false, error: true, });
                setFormEmpleoState({
                    aux_afiliado_id: id,
                    aux_empleadoEn: '',
                    aux_cajaJubProvincial: false,
                    aux_sistemaNacional: false,
                    aux_sistNacCual: 3,
                    aux_obraSocial: '',
                    aux_fechaDeIngreso: '',
                    aux_funcionQueDesempeña: '',
                    aux_legajo: '',
                    aux_ingresos: '',
                    aux_fechaAfiliacion: '',
                    aux_fondoCompensador: false,
                    aux_osfatlyf: false,
                    aux_faf: false,
                    aux_pre_nro: '',
                    aux_numero: '',
                    aux_post_nro: '',
                    aux_extra_nro: '',
                    aux_res2689: false
                });
            });
    }, [id]);

    var validationSchema = Yup.object().shape({
        empleadoEn: Yup.number().required("Requerido"),
    });

    const formik = useFormik({
        initialValues: {
            afiliado_id: aux_afiliado_id,
            empleadoEn: aux_empleadoEn,
            cajaJubProvincial: aux_cajaJubProvincial,
            sistemaNacional: aux_sistemaNacional,
            sistNacCual: aux_sistNacCual,
            obraSocial: aux_obraSocial,
            fechaDeIngreso: aux_fechaDeIngreso,
            funcionQueDesempeña: aux_funcionQueDesempeña,
            legajo: aux_legajo,
            ingresos: aux_ingresos,
            fechaAfiliacion: aux_fechaAfiliacion,
            fondoCompensador: aux_fondoCompensador,
            osfatlyf: aux_osfatlyf,
            faf: aux_faf,
            pre_nro: aux_pre_nro,
            numero: aux_numero,
            post_nro: aux_post_nro,
            extra_nro: aux_extra_nro,
            res268: aux_res2689
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setSubmitting(true);
            api.postAfiliadosEmpresas(values)
                .then(respuesta => {
                    setMensajeAlert('Se guardó con éxito');
                    setTipoMensaje('success');
                    setOpen(true);
                    history.replace('/search'.concat(props.location.search));
                })
                .catch(function (error) {
                    setMensajeAlert('Error al guardar');
                    setTipoMensaje('error');
                    setSubmitting(false);
                    setOpen(true);
                })
        },
        onReset: () => { },
    });

    const cancelar = () => {
        setSubmitting(false);
        formik.handleReset();
        history.replace('/search'.concat(props.location.search));
    };

    const isIdOsfatlyf = (id) => parseInt(id) === 1;

    const actuObraSocial = (e) => {
        formik.handleChange(e);
        const isOsfatlyf = isIdOsfatlyf(e.target.value);
        setHabilitadoNroOS(isOsfatlyf);
        if (isOsfatlyf) {
            //Restaurar valores iniciales/base de datos.
            formik.setFieldValue('post_nro', aux_post_nro);
            formik.setFieldValue('pre_nro', aux_pre_nro);
            formik.setFieldValue('numero', aux_numero);
            formik.setFieldValue('extra_nro', aux_extra_nro);
            setCheckedOS(aux_osfatlyf);
            formik.setFieldValue('osfatlyf', aux_osfatlyf);
            setCheckedFAF(aux_faf);
            formik.setFieldValue('faf', aux_faf);
        } else {
            formik.setFieldValue('post_nro', '');
            formik.setFieldValue('pre_nro', '');
            formik.setFieldValue('numero', '');
            formik.setFieldValue('extra_nro', '');
            setCheckedOS(false);
            formik.setFieldValue('osfatlyf', false);
            setCheckedFAF(false);
            formik.setFieldValue('faf', false);
        }
    };

    const checkPlan = (e) => {
        if (isIdOsfatlyf(formik.values.obraSocial)) {
            setCheckedOS(!formik.values.osfatlyf);
            formik.setFieldValue('osfatlyf', !formik.values.osfatlyf);
        } else {
            setCheckedOS(false);
            formik.setFieldValue('osfatlyf', false);
        }
    };

    const checkFaf = (e) => {
        if (isIdOsfatlyf(formik.values.obraSocial)) {
            setCheckedFAF(!formik.values.faf);
            formik.setFieldValue('faf', !formik.values.faf);
        } else {
            setCheckedFAF(false);
            formik.setFieldValue('faf', false);
        }
    };

    const confirmar = () => {
        formik.submitForm();
    };

    const actuJubilacion = (e) => {
        if (e.target.name === 'res268') {
            if (formik.values.sistemaNacional && formik.values.sistNacCual === 1) {
                setCheckedRes268(e.target.checked);
                formik.setFieldValue('res268', e.target.checked);
            } else {
                setCheckedRes268(false);
                formik.setFieldValue('res268', false);
            }
        } else if (e.target.name === 'cajaJubProvincial') {
            if (e.target.value) {
                formik.setFieldValue('sistemaNacional', false);
                setCheckedRes268(false);
                formik.setFieldValue('res268', false);
            }
            formik.handleChange(e);
        } else if (e.target.name === 'sistemaNacional' || e.target.name === 'sistNacCual') {
            const sistemaNacionalValue = e.target.name === 'sistemaNacional' ?
                e.target.value
                : formik.values.sistemaNacional;
            const sistNacCualValue = e.target.name === 'sistNacCual' ?
                e.target.value
                : formik.values.sistNacCual;
            const isAnses = sistemaNacionalValue && sistNacCualValue === 1;
            setCheckedRes268(isAnses);
            formik.setFieldValue('res268', isAnses);
            if (sistemaNacionalValue) {
                formik.setFieldValue('cajaJubProvincial', false);
            }
            formik.handleChange(e);
        } else {
            formik.handleChange(e);
        }
    };

    const [tiempoTrabajo, setTiempoTrabajo] = useState();

    const calcular = () => {
        const fechaIng = formik.values.fechaDeIngreso.split('-');

        api.calcularAntiguedad(fechaIng).then(resultado => {
            const dias = resultado.data;
            if (parseInt(dias) >= 365) {
                const anios = Math.floor(dias / 365)
                const anios_ = anios > 0 ? anios + " años " : ''
                const dias_ = dias - (anios * 365)
                const meses = (dias_ >= 30) ? Math.floor(dias_ / 30) + " meses " : ''
                const dias_aux = (dias_ - (Math.floor(dias_ / 30) * 30) > 0) ? dias_ - (Math.floor(dias_ / 30) * 30) + " días " : ''

                setTiempoTrabajo(anios_ + meses + dias_aux);
            } else {
                setTiempoTrabajo(dias + " días");
            }
        });
    };

    const pageTitle = "Editar datos empleo Afiliado";

    if (!canEditarEmpleoCompleto) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Alert severity="error">
                    No tiene permisos.
                </Alert>
                <br />
                <Grid item xs={12} md={12}>
                    <Link style={{ textDecoration: 'none' }} to={'/inicio'}>
                        <Button variant="contained" underline="none" endIcon={<CloseIcon />}>
                            Volver
                        </Button>
                    </Link>
                </Grid>
            </Page>
        );
    }
    else if (loading) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Alert severity="info">
                    Cargando...
                </Alert>
            </Page>
        );
    } else if (!loading && afiliado.error) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Alert severity="error">
                    Se produjo un error al cargar los datos.
                </Alert>
                <br />
                <Grid item xs={12} md={12}>
                    <Button
                        variant="secondary"
                        size="medium"
                        color="secondary"
                        onClick={cancelar}
                        endIcon={<ArrowBackIcon />}>
                        Volver
                    </Button>
                </Grid>
            </Page>
        );
    } else if (!afiliado.loading && !afiliado.data) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Alert severity="error">
                    No se ha encontrado el afiliado.
                </Alert>
                <br />
                <Grid item xs={12} md={12}>
                    <Button
                        variant="secondary"
                        size="medium"
                        color="secondary"
                        onClick={cancelar}
                        endIcon={<ArrowBackIcon />}>
                        Volver
                    </Button>
                </Grid>
            </Page>
        );
    } else {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Container>
                    <p><b>Nombre:</b> {nombre_afiliado} &nbsp;&nbsp;&nbsp;&nbsp;<b>Documento:</b> {dni_afiliado}</p>
                </Container>
                <Divider />
                <br />
                <form
                    id="formAfiliadoEmpleo"
                    name="formAfiliadoEmpleo"
                    onSubmit={formik.handleSubmit}
                    onReset={formik.handleReset}
                    autoComplete="off"
                >
                    <Grid container spacing={2}>
                        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}>
                            <Alert onClose={handleClose} severity={tipoMensaje} sx={{
                                width: '100%'
                            }}>
                                {mensajeAlert}
                            </Alert>
                        </Snackbar>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    focused
                                    id="standard-adornment-fechaAfiliacion"
                                    label="Fecha de Afiliación"
                                    name="fechaAfiliacion"
                                    value={formik.values.fechaAfiliacion}
                                    onChange={formik.handleChange}
                                    type="date"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={5} >
                            <FormControl fullWidth>
                                <InputLabel htmlFor="empleadoEn">Empleado en:</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="empleadoEn"
                                    label="Empleado en:"
                                    name="empleadoEn"
                                    value={formik.values.empleadoEn}
                                    onChange={formik.handleChange}
                                    error={formik.touched.empleadoEn && Boolean(formik.errors.empleadoEn)}
                                    endAdornment={formik.touched.empleadoEn && formik.errors.empleadoEn}
                                >
                                    {empresasList.loading ?
                                        <MenuItem>Cargando</MenuItem>
                                        : empresasList.error ?
                                            <MenuItem>Error al cargar</MenuItem>
                                            : empresasList.data.map((empresa) =>
                                                <MenuItem value={empresa.id} key={empresa.id}>
                                                    {empresa.descripcion}
                                                </MenuItem>)
                                    }
                                </Select>
                                {formik.errors.empleadoEn && formik.touched.empleadoEn ? (<FormHelperText error>Requerido</FormHelperText>) : null}
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    focused
                                    id="standard-adornment-fechaDeIngreso"
                                    label="Fecha de Ingreso"
                                    name="fechaDeIngreso"
                                    value={formik.values.fechaDeIngreso}
                                    onChange={formik.handleChange}
                                    onBlur={calcular}
                                    type="date"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}><p>{tiempoTrabajo}</p></Grid>
                    </Grid>
                    <br />

                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="funcionQueDesempeña">Función que desempeña</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="funcionQueDesempeña"
                                    label="Función que desempeña"
                                    name="funcionQueDesempeña"
                                    value={formik.values.funcionQueDesempeña}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value=''>Sin definir</MenuItem>
                                    <MenuItem value='ADMINISTRATIVO'>ADMINISTRATIVO</MenuItem>
                                    <MenuItem value='OPERATIVO'>OPERATIVO</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    id="legajo"
                                    label="Nº Legajo:"
                                    name="legajo"
                                    value={formik.values.legajo}
                                    type="text"
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <TextField
                                    autoComplete="off"
                                    id="standard-adornment-ingresos"
                                    label="Ingresos:"
                                    name="ingresos"
                                    value={formik.values.ingresos}
                                    type="number"
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br />

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-obraSocial">Obra Social</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="standard-adornment-obraSocial"
                                    label="Obra Social"
                                    name="obraSocial"
                                    value={formik.values.obraSocial}
                                    onChange={actuObraSocial}
                                >
                                    {listadoObrasSociales.loading ?
                                        <MenuItem>Cargando</MenuItem>
                                        : listadoObrasSociales.error ?
                                            <MenuItem>Error al cargar</MenuItem>
                                            : listadoObrasSociales.data.map((os) =>
                                                <MenuItem key={os.id} value={os.id}>
                                                    {os.abreviatura}
                                                </MenuItem>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl sx={{ width: '25%' }}>
                                <TextField
                                    autoComplete="off"
                                    disabled={!habilitadoNroOS}
                                    id="pre_nro"
                                    name="pre_nro"
                                    value={formik.values.pre_nro}
                                    type="text"
                                    onChange={formik.handleChange}
                                />
                            </FormControl>

                            <FormControl sx={{ width: '40%' }}>
                                <TextField
                                    autoComplete="off"
                                    disabled={!habilitadoNroOS}
                                    id="numero"
                                    label="Número Obra Social"
                                    name="numero"
                                    value={formik.values.numero}
                                    type="text"
                                    onChange={formik.handleChange}
                                />
                            </FormControl>

                            <FormControl sx={{ width: '15%' }}>
                                <TextField
                                    autoComplete="off"
                                    disabled={!habilitadoNroOS}
                                    id="post_nro"
                                    name="post_nro"
                                    value={formik.values.post_nro}
                                    type="text"
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                            <FormControl sx={{ width: '20%' }}>
                                <TextField
                                    autoComplete="off"
                                    disabled={!habilitadoNroOS}
                                    id="extra_nro"
                                    name="extra_nro"
                                    value={formik.values.extra_nro}
                                    type="text"
                                    onChange={formik.handleChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br />

                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <FormControlLabel
                                control={<Checkbox />}
                                disabled={!habilitadoNroOS}
                                value={formik.values.osfatlyf}
                                checked={checkedOS}
                                onChange={checkPlan}
                                label="Aporte Solidario OSFATLYF (Plan Especial)"
                                labelPlacement="start"
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormControlLabel
                                control={<Checkbox />}
                                disabled={!habilitadoNroOS}
                                value={formik.values.faf}
                                checked={checkedFAF}
                                onChange={checkFaf}
                                label="Fondo de Auxilio Financiero (FAF)"
                                labelPlacement="start"
                            />
                        </Grid>
                    </Grid>
                    <br />

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                    <br />

                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="cajaJubProvincial">Caja de Jubilación Provincial</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="cajaJubProvincial"
                                    label="Aporta a la Caja de Jubilaciones Provincial"
                                    name="cajaJubProvincial"
                                    value={formik.values.cajaJubProvincial}
                                    onChange={actuJubilacion}
                                    error={formik.touched.cajaJubProvincial && Boolean(formik.errors.cajaJubProvincial)}
                                >
                                    <MenuItem value={true}>Sí</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={1}></Grid>

                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-sistemaNacional">Aporta al Sistema Nacional</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="standard-adornment-sistemaNacional"
                                    label="Aporta el Sistema Naciona"
                                    name="sistemaNacional"
                                    value={formik.values.sistemaNacional}
                                    onChange={actuJubilacion}
                                >
                                    <MenuItem value={true}>Sí</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </ Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-sistemaNacional">Cuál</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="standard-adornment-sistNacCual"
                                    label="Cuál"
                                    name="sistNacCual"
                                    value={formik.values.sistNacCual}
                                    onChange={actuJubilacion}
                                >
                                    {sistNacList.loading ?
                                        <MenuItem>Cargando</MenuItem>
                                        : sistNacList.error ?
                                            <MenuItem>Error al cargar</MenuItem>
                                            : sistNacList.data.map((sistNac) => <MenuItem value={sistNac.id} key={sistNac.id}>{sistNac.descripcion}</MenuItem>)
                                    }
                                </ Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            Res. 268/9
                            <Checkbox name='res268'
                                value={formik.values.res268}
                                onChange={actuJubilacion}
                                checked={checkedRes268}
                            />
                        </Grid>

                        <Grid item xs={1}></Grid>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-fondoCompensador">Fondo Compensador</InputLabel>
                                <Select
                                    autoComplete="off"
                                    id="standard-adornment-fondoCompensador"
                                    label="Fondo Compensador"
                                    name="fondoCompensador"
                                    value={formik.values.fondoCompensador}
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value=''><em>Dejar sin dato</em></MenuItem>
                                    <MenuItem value='1'>Sí</MenuItem>
                                    <MenuItem value='0'>No</MenuItem>
                                </ Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}></Grid>
                    </Grid>
                    <br />

                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <Stack direction="row" spacing={2}>
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="primary"
                                    onClick={() => confirmar()}
                                    disabled={submitting}
                                    endIcon={<SaveIcon />}
                                >
                                    {submitting ? "Guardando..." : "Guardar"}
                                </Button>
                                <Button
                                    variant="secondary"
                                    size="medium"
                                    color="secondary"
                                    onClick={cancelar}
                                    disabled={submitting}
                                    endIcon={<CloseIcon />}>
                                    Cancelar
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </form>
            </Page >
        )
    }
}