import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import MuiListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ListItemButton from '@mui/material/ListItemButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SearchIcon from '@mui/icons-material/Search';
import ApartmentIcon from '@mui/icons-material/Apartment';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import PersonIcon from '@mui/icons-material/Person';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import GroupIcon from '@mui/icons-material/Group';
import PolicyIcon from '@mui/icons-material/Policy';
import { AuthContext } from '../../auth/AuthContext';
import { Login } from "@mui/icons-material";

const RootList = styled(List)(({ theme }) => ({
    width: "100%",
    maxWidth: 256,
    backgroundColor: "#006AFF",
    color: "#FFFFFF",
    paddingTop: 0,
}));

const ListItem = styled(MuiListItem)(({ theme }) => ({
    "&:hover": {
        backgroundColor: "#004AB3",
        color: "white",
    },
}));

const SubLisItem = styled(MuiListItem)(({ theme }) => ({
    "&:hover": {
        backgroundColor: "#004AB3",
        color: "white",
    },
    height: "50px",
}));

const HeaderListItem = styled(ListItem)(({ theme }) => ({
    height: 49,
    paddingTop: 0,
    paddingBottom: 0,
}));

const NestedListItem = styled(ListItem)(({ theme }) => ({
    backgroundColor: "#2783d5",
    height: "50px",
}));

const MenuIzquierdo = (props) => {
    const { user: {
        isNivel3,
        afiliadosBusqueda,
        nuevoAfiliado,
        jubiladosBusqueda,
        nuevoJubilado,
        canABMEmpresas,
        canVerEmpresas,
        canABMObrasSociales,
        canABMUsuarios,
        canAuditorias,
        canPadron,
        canInformeAfiliados,
        canBusquedaUnitaria,
    } } = useContext(AuthContext);

    const [open, setOpen] = React.useState(true);
    const handleClick = () => {
        setOpen(!open);
    };

    const [openJubilado, setOpenJubilado] = React.useState(true);
    const handleClickJubilado = () => {
        setOpenJubilado(!openJubilado);
    };

    const [openAuditorias, setOpenAuditorias] = React.useState(true);
    const handleClickAuditorias = () => {
        setOpenAuditorias(!openAuditorias);
    };

    return (
        <RootList component="nav" aria-labelledby="nested-list-subheader">
            <HeaderListItem
                button
                key="encabezado"
                component={Link}
                to="/"
                onClick={props.closeResponsiveDrawer}
            >
                <ListItemIcon>
                    <HomeOutlinedIcon sx={{ color: "white" }} />
                </ListItemIcon>
                <ListItemText
                    primary="Inicio"
                    primaryTypographyProps={{ variant: "body2" }}
                />
            </HeaderListItem>
            <List>
                {afiliadosBusqueda && nuevoAfiliado ?
                    <>
                        <SubLisItem>
                            <ListItemButton onClick={handleClick}>
                                <ListItemIcon>
                                    <PersonIcon sx={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary='Afiliado'
                                    primaryTypographyProps={{ variant: "body2" }}
                                />
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        </SubLisItem>
                        <Collapse in={!open} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <NestedListItem>
                                    <ListItemButton component={Link}
                                        to="/afiliado" sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <AddBoxIcon sx={{ color: "white" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary='Nuevo afiliado'
                                            primaryTypographyProps={{ variant: "body2" }}
                                        />
                                    </ListItemButton>
                                </NestedListItem>
                                <NestedListItem>
                                    <ListItemButton component={Link}
                                        to="/search" sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <SearchIcon sx={{ color: "white" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary='Buscar Afiliado'
                                            primaryTypographyProps={{ variant: "body2" }}
                                        />
                                    </ListItemButton>
                                </NestedListItem>
                            </List>
                        </Collapse>
                    </>
                    : afiliadosBusqueda &&
                    <ListItemButton component={Link}
                        to="/search" sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <SearchIcon sx={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText
                            primary='Buscar Afiliado'
                            primaryTypographyProps={{ variant: "body2" }}
                        />
                    </ListItemButton>
                }
                {jubiladosBusqueda && nuevoJubilado ?
                    <>
                        <SubLisItem>
                            <ListItemButton onClick={handleClickJubilado}>
                                <ListItemIcon>
                                    <PersonIcon sx={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary='Jubilado'
                                    primaryTypographyProps={{ variant: "body2" }}
                                />
                                {openJubilado ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        </SubLisItem>
                        <Collapse in={!openJubilado} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <NestedListItem>
                                    <ListItemButton component={Link}
                                        to="/jubilados" sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <AddBoxIcon sx={{ color: "white" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary='Jubilado Nuevo'
                                            primaryTypographyProps={{ variant: "body2" }}
                                        />
                                    </ListItemButton>
                                </NestedListItem>
                                <NestedListItem>
                                    <ListItemButton component={Link}
                                        to="/searchJubilados" sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <SearchIcon sx={{ color: "white" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary='Buscar Jubilado'
                                            primaryTypographyProps={{ variant: "body2" }}
                                        />
                                    </ListItemButton>
                                </NestedListItem>
                            </List>
                        </Collapse>
                    </>
                    : jubiladosBusqueda &&
                    <ListItemButton component={Link}
                        to="/searchJubilados" sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <SearchIcon sx={{ color: "white" }} />
                        </ListItemIcon>
                        <ListItemText
                            primary='Buscar Jubilado'
                            primaryTypographyProps={{ variant: "body2" }}
                        />
                    </ListItemButton>
                }
                {(canABMEmpresas || canVerEmpresas) &&
                    <SubLisItem>
                        <ListItemButton component={Link}
                            to="/empresa">
                            <ListItemIcon>
                                <ApartmentIcon sx={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText
                                primary='Empresas'
                                primaryTypographyProps={{ variant: "body2" }}
                            />
                        </ListItemButton>
                    </SubLisItem>
                }
                {canABMObrasSociales &&
                    <SubLisItem>
                        <ListItemButton component={Link}
                            to="/obrasocial">
                            <ListItemIcon>
                                <MedicalServicesIcon sx={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText
                                primary='Obras Sociales'
                                primaryTypographyProps={{ variant: "body2" }}
                            />
                        </ListItemButton>
                    </SubLisItem>
                }
                {canPadron &&
                    <SubLisItem>
                        <ListItemButton component={Link}
                            to="/padron">
                            <ListItemIcon>
                                <HowToVoteIcon sx={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText
                                primary='Padrón Electoral'
                                primaryTypographyProps={{ variant: "body2" }}
                            />
                        </ListItemButton>
                    </SubLisItem>
                }
                {canABMUsuarios &&
                    <SubLisItem>
                        <ListItemButton component={Link}
                            to="/usuario">
                            <ListItemIcon>
                                <GroupIcon sx={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText
                                primary='Usuarios'
                                primaryTypographyProps={{ variant: "body2" }}
                            />
                        </ListItemButton>
                    </SubLisItem>
                }
                {canInformeAfiliados &&
                    <SubLisItem>
                        <ListItemButton component={Link}
                            to="/informeAfiliados">
                            <ListItemIcon>
                                <GroupIcon sx={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText
                                primary='Informe Afiliados'
                                primaryTypographyProps={{ variant: "body2" }}
                            />
                        </ListItemButton>
                    </SubLisItem>
                }
                {canBusquedaUnitaria &&
                    <SubLisItem>
                        <ListItemButton component={Link}
                            to="/busqueda">
                            <ListItemIcon>
                                <GroupIcon sx={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText
                                primary='Búsqueda Unitaria'
                                primaryTypographyProps={{ variant: "body2" }}
                                secondary={!isNivel3 ? '(nivel 3)' : ''}
                                secondaryTypographyProps={{ fontSize: "smaller", color: "whitesmoke" }}
                            />
                        </ListItemButton>
                    </SubLisItem>
                }
                {canAuditorias &&
                    <>
                        <SubLisItem>
                            <ListItemButton onClick={handleClickAuditorias}>
                                <ListItemIcon>
                                    <PolicyIcon sx={{ color: "white" }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary='Auditorias'
                                    primaryTypographyProps={{ variant: "body2" }}
                                />
                                {openAuditorias ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        </SubLisItem>
                        <Collapse in={!openAuditorias} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <NestedListItem>
                                    <ListItemButton component={Link}
                                        to="/auditorias/ingresos" sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <Login sx={{ color: "white" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary='Ingresos'
                                            primaryTypographyProps={{ variant: "body2" }}
                                        />
                                    </ListItemButton>
                                </NestedListItem>
                                <NestedListItem>
                                    <ListItemButton component={Link}
                                        to="/auditorias/auditAfiliados" sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <PersonIcon sx={{ color: "white" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary='Datos Afiliados'
                                            primaryTypographyProps={{ variant: "body2" }}
                                        />
                                    </ListItemButton>
                                </NestedListItem>
                                <NestedListItem>
                                    <ListItemButton component={Link}
                                        to="/auditorias/auditEmpresas" sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <ApartmentIcon sx={{ color: "white" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary='Datos Empresas'
                                            primaryTypographyProps={{ variant: "body2" }}
                                        />
                                    </ListItemButton>
                                </NestedListItem>
                                <NestedListItem>
                                    <ListItemButton component={Link}
                                        to="/auditorias/auditObrasSociales" sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <MedicalServicesIcon sx={{ color: "white" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary='Datos Obras Sociales'
                                            primaryTypographyProps={{ variant: "body2" }}
                                        />
                                    </ListItemButton>
                                </NestedListItem>
                                <NestedListItem>
                                    <ListItemButton component={Link}
                                        to="/auditorias/auditUsuarios" sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <GroupIcon sx={{ color: "white" }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary='Datos Usuarios'
                                            primaryTypographyProps={{ variant: "body2" }}
                                        />
                                    </ListItemButton>
                                </NestedListItem>
                            </List>
                        </Collapse>
                    </>
                }
            </List>
        </RootList>
    );
};

export default MenuIzquierdo;