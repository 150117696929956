import { Alert, AlertTitle, Box, Grid } from '@mui/material';
import React, { useContext } from 'react'
import { AuthContext } from '../../auth/AuthContext';
import { Page } from '../ui/Page'
import logo from '../../assets/logo.png'

export const Inicio = (props) => {
    const { user: { name, ultimoIngreso } } = useContext(AuthContext)
    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
    ];
    return (
        <Page title="" breadcrumbs={breadcrumbs}>
            <Grid container alignItems={'center'}>
                <Grid item xs={5}></Grid>
                <Grid item xs={2}>
                    <Box mb={1}>
                        <img src={logo} mb={1} alt="Logo" width="180px" />
                    </Box>
                </Grid>
                <Grid item xs={5}></Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={6}>
                    <Alert severity="success">
                        <AlertTitle>Último ingreso</AlertTitle>
                        El usuario <strong>{name}</strong> ingresó el <strong>{ultimoIngreso}</strong>
                    </Alert>
                </Grid>
            </Grid>
        </Page>
    )
}
