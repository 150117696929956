import React, { useContext, useState, useRef, useEffect } from 'react'
import { AuthContext } from '../auth/AuthContext'
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { useForm } from '../hooks/useForm';
import {
    Table, TableBody,
    TableCell, TableContainer, TableHead,
    TableRow, Button, Grid, Tooltip, TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Alert } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { Page } from '../components/ui/Page';
import { useAfiliados } from '../hooks/useAfiliados';
import DataTables from "datatables.net-dt";
import 'datatables.net-dt/css/dataTables.dataTables.css';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    root: {
        flexGrow: 1,
    },
    cabeceraTabla: {
        backgroundColor: 'darkgrey',
        color: 'white'
    },
    jubilado: {
        backgroundColor: '#EFAFC0 !important',
        '&:hover': {
            backgroundColor: '#DBA1B0 !important',
        },
    },
    deshabilitado: {
        backgroundColor: '#FFCF8F !important',
        '&:hover': {
            backgroundColor: '#F1C589 !important',
        },
    }
});

const TablaAfiliados = React.memo(({
    afiliadosFiltered,
    q,
    a,
    n
}) => {
    const classes = useStyles();

    const tableRef = useRef();

    useEffect(() => {
        const dt = new DataTables(tableRef.current, {
            order: [[1, 'asc']],
            columnDefs: [{
                targets: [0, 2, 3, 4, 5, 6],
                className: 'dt-body-center dt-head-center'
            }, {
                targets: '_all',
                className: 'dt-head-center'
            }]
        });
        return () => {
            dt.destroy();
        };
    }, [afiliadosFiltered]);

    const getClassName = (afiliado) => {
        if (afiliado.fecha_jubilacion !== '00/00/0000' && afiliado.fecha_jubilacion !== null)
            return classes.jubilado;
        if (afiliado.fecha_baja_afiliacion !== '00/00/0000' && afiliado.fecha_baja_afiliacion !== null)
            return classes.deshabilitado;
        return '';
    }

    return (
        <Grid className="classes.root">
            {
                ((q !== '' || a !== '' || n !== '') && afiliadosFiltered.loading) &&
                <Alert severity="info">
                    Buscando...
                </Alert>
            }
            {
                ((q !== '' || a !== '' || n !== '')
                    && !afiliadosFiltered.loading
                    && afiliadosFiltered.error) &&
                <Alert severity="error">
                    No se ha encontrado ningún afiliado con los datos especificados.
                </Alert>
            }
            {
                ((q === '' && a === '' && n === '')) &&
                <Alert severity="info">
                    Para buscar un Afiliado debe de introducir un texto.
                </Alert>
            }
            {((q !== '' || a !== '' || n !== '') && !afiliadosFiltered.error) &&
                <Grid container>
                    <TableContainer>
                        <Table ref={tableRef} className={`${classes.table} stripe condensed`} size="small" aria-label="a dense table">
                            <TableHead className={classes.cabeceraTabla}>
                                <TableRow>
                                    <TableCell align="center">Documento</TableCell>
                                    <TableCell align="center">Nombre</TableCell>
                                    <TableCell align="center">Obra Social</TableCell>
                                    <TableCell align="center">Afiliación</TableCell>
                                    <TableCell align="center">Jubilación</TableCell>
                                    <TableCell align="center">Baja Afiliación</TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {afiliadosFiltered.data.map((row) => (
                                    <TableRow hover key={row.id_afiliado} className={getClassName(row)}>
                                        <TableCell align="center">{row.dni}</TableCell>
                                        <TableCell component="th" scope="row">{row.apellido}, {row.nombre}</TableCell>
                                        <TableCell align="center">{row.obra_social}</TableCell>
                                        <TableCell align="center" data-order={row.fecha_afiliacion_iso}>{row.fecha_afiliacion}</TableCell>
                                        <TableCell align="center" data-order={row.fecha_jubilacion_iso}>{row.fecha_jubilacion}</TableCell>
                                        <TableCell align="center" data-order={row.fecha_baja_afiliacion_iso}>{row.fecha_baja_afiliacion}</TableCell>
                                        <TableCell align="center">
                                            <Link to={`./informacion/${row.id_afiliado}?q=${q}&a=${a}&n=${n}&u=u`}>
                                                <Tooltip title="Información">
                                                    <IconButton
                                                        color="primary"
                                                        size="small"
                                                        type="submit"
                                                        variant="outlined"
                                                    >
                                                        <InfoIcon />
                                                    </IconButton >
                                                </Tooltip>
                                            </Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            }
        </Grid>
    );
});

export const SearchUnitaria = ({ history }) => {
    const { user: {
        canBusquedaUnitaria
    } } = useContext(AuthContext)

    const { search } = useLocation();
    const { q = '', a = '', n = '' } = queryString.parse(search);

    const [formValues, handleInputChange] = useForm({
        searchText: q,
        searchTextA: a,
        searchTextN: n
    });
    const [refrescar, setRefrescar] = useState(0);
    const { searchText, searchTextA, searchTextN } = formValues;
    const afiliadosFiltered = useAfiliados(q, a, n, refrescar);
    const handleSearch = (e) => {
        e.preventDefault();
        setRefrescar(refrescar + 1);
        history.push(`?q=${searchText}&a=${searchTextA}&n=${searchTextN}`)
    }

    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Afiliado Listado", ruta: "/busqueda" },
    ];
    if (!canBusquedaUnitaria) {
        return (
            <Page title="Listado de Afiliados" breadcrumbs={breadcrumbs}>
                <Alert severity="error">
                    No tiene permisos.
                </Alert>
                <br />
                <Grid item xs={12} md={12}>
                    <Link style={{ textDecoration: 'none' }} to={'/inicio'}>
                        <Button variant="contained" underline="none" endIcon={<CloseIcon />}>
                            Volver
                        </Button>
                    </Link>
                </Grid>
            </Page>
        );
    } else {
        return (
            <Page title="Listado de Afiliados" breadcrumbs={breadcrumbs}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <form onSubmit={handleSearch}>
                            <TextField
                                autoComplete="off"
                                fullWidth
                                name="searchText"
                                label="Documento"
                                placeholder="Ingresar texto a buscar"
                                size="small"
                                type="text"
                                value={searchText}
                                onChange={handleInputChange}
                            />
                        </form>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <form onSubmit={handleSearch}>
                            <TextField
                                autoComplete="off"
                                fullWidth
                                name="searchTextA"
                                label="Apellido"
                                placeholder="Ingresar texto a buscar"
                                size="small"
                                type="text"
                                inputProps={{ style: { textTransform: "uppercase" } }}
                                value={searchTextA}
                                onChange={handleInputChange}
                            />
                        </form>
                    </Grid>

                    <Grid item xs={6}>
                        <form onSubmit={handleSearch}>
                            <TextField
                                autoComplete="off"
                                fullWidth
                                name="searchTextN"
                                label="Nombre"
                                placeholder="Ingresar texto a buscar"
                                size="small"
                                type="text"
                                inputProps={{ style: { textTransform: "uppercase" } }}
                                value={searchTextN}
                                onChange={handleInputChange}
                            />
                        </form>
                    </Grid>

                    <Grid item xs={2} justifyContent="flex-end">
                        <Button
                            color="primary"
                            size="medium"
                            fullWidth
                            type="submit"
                            variant="contained"
                            onClick={handleSearch}
                        >
                            Buscar
                        </Button>
                    </Grid>
                </Grid>
                <br />

                <TablaAfiliados afiliadosFiltered={afiliadosFiltered} q={q} a={a} n={n} />
            </Page >
        );
    }
}
