import React, { useState, useEffect } from 'react';
import {
    Paper, Tab, Tabs, Box, Typography,
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Grid, IconButton, Alert
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Page } from '../ui/Page';
import api from "../../service/api";
import { DocumentoListadoPdf } from '../ui/ReportesListadosPdf';
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from "axios";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    root: {
        flexGrow: 1,
    },
    cabeceraTabla: {
        backgroundColor: 'darkgrey',
        color: 'white'
    }
});

export const Padron = (props) => {
    const [votantes, setVotantes] = useState({ loading: true });
    const [postulantes, setPostulantes] = useState({ loading: true });
    const [value, setValue] = useState(0);
    const [refrescar, setRefrescar] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const classes = useStyles();
    const breadcrumbs = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Padrón electoral", ruta: "" },
    ];

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <div>{children}</div>
                    </Box>
                )}
            </div>
        );
    }

    const headers = [
        'Apellido',
        'Nombre',
        'Dni',
        'Fecha afiliación',
        'Localidad',
        'Provincia',
    ]

    const headerspostulantes = [
        'Apellido',
        'Nombre',
        'Dni',
        'Fecha afiliación',
        'Localidad',
        'Provincia',
    ]

    const recargar = (e) => {
        setVotantes({ loading: true });
        setPostulantes({ loading: true });
        setRefrescar(refrescar + 1);
    }

    useEffect(() => {
        let procesar = true;
        axios.all([
            api.buscarVotantes(),
            api.buscarPostulantes()
        ]).then(axios.spread((respVotantes, respPostulantes) => {
            if (procesar) {
                setVotantes({ data: respVotantes.data.votantes });
                setPostulantes({ data: respPostulantes.data.postulantes });
            }
        }))
            .catch((error) => {
                if (procesar) {
                    setVotantes({ error: true });
                    setPostulantes({ error: true });
                    console.log('El error es => ', error);
                }
            });
        return () => {
            procesar = false;
        };
    }, [refrescar]);

    const pageTitle = "Padrón electoral";

    if (votantes.loading || postulantes.loading) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Grid className="classes.root">
                    <Alert severity="info">
                        Cargando...
                    </Alert>
                </Grid>
            </Page>
        );
    } else if (votantes.data && postulantes.data) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Paper className={classes.root}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab label="Votantes" {...a11yProps(0)} />
                        <Tab label="Postulantes" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Grid container>
                            <Grid item xs={6} align="left">
                                <DocumentoListadoPdf
                                    nombre="Votantes"
                                    headerTabla={headers}
                                    datos={votantes.data}
                                >
                                </DocumentoListadoPdf>
                                <IconButton
                                    color="primary"
                                    size="small"
                                    type="submit"
                                    variant="outlined"
                                    onClick={recargar}
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} align="right">Votantes encontrados: {votantes.data.length}</Grid>
                            <Typography variant="caption">Tienen más de 6 meses de afiliado</Typography>
                            <TableContainer>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead className={classes.cabeceraTabla}>
                                        <TableRow>
                                            <TableCell align="center">Nombre</TableCell>
                                            <TableCell align="center">Dni</TableCell>
                                            <TableCell align="center">Fecha afiliación</TableCell>
                                            <TableCell align="center">Localidad</TableCell>
                                            <TableCell align="center">Provincia</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {votantes.data.map((row, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell align="left">{row.apellido + ', ' + row.nombre}</TableCell>
                                                <TableCell align="center">{row.dni}</TableCell>
                                                <TableCell align="center">{row.fechaa}</TableCell>
                                                <TableCell align="left">{row.localidad}</TableCell>
                                                <TableCell align="left">{row.provincia}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Grid container>
                            <Grid item xs={6} align="left">
                                <DocumentoListadoPdf
                                    nombre="Postulantes"
                                    headerTabla={headerspostulantes}
                                    datos={postulantes.data}
                                >
                                </DocumentoListadoPdf>
                                <IconButton
                                    color="primary"
                                    size="small"
                                    type="submit"
                                    variant="outlined"
                                    onClick={recargar}
                                >
                                    <RefreshIcon />
                                </IconButton></Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} align="right">Postulantes encontrados: {postulantes.data.length}</Grid>
                            <Typography variant="caption">Tienen más de 2 años de afiliado</Typography>
                            <TableContainer>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead className={classes.cabeceraTabla}>
                                        <TableRow>
                                            <TableCell align="center">Nombre</TableCell>
                                            <TableCell align="center">Dni</TableCell>
                                            <TableCell align="center">Fecha afiliación</TableCell>
                                            <TableCell align="center">Localidad</TableCell>
                                            <TableCell align="center">Provincia</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {postulantes.data.map((row, idx) => (
                                            <TableRow key={idx}>
                                                <TableCell align="left">{row.apellido + ', ' + row.nombre}</TableCell>
                                                <TableCell align="center">{row.dni}</TableCell>
                                                <TableCell align="center">{row.fechaa}</TableCell>
                                                <TableCell align="left">{row.localidad}</TableCell>
                                                <TableCell align="left">{row.provincia}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </TabPanel>
                </Paper>
            </Page>
        )
    } else if (votantes.error || postulantes.error) {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Grid className="classes.root">
                    <Alert severity="error">
                        No se pudo cargar la información solicitada.
                    </Alert>
                </Grid>
            </Page>
        );
    } else {
        return (
            <Page title={pageTitle} breadcrumbs={breadcrumbs}>
                <Grid className="classes.root">
                    <Alert severity="error">
                        No se pudo cargar la información solicitada.
                    </Alert>
                </Grid>
            </Page>
        );
    }
}